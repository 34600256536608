export default {
  error_occurred: 'क्षमा करे, एक त्रुटि प्राप्त हुई। कृपया एक क्षण में पुन: प्रयास करें।',
  new_content_available: "एक नया पृष्ठ अद्यतन है, कृपया पृष्ठ को पुनः लोड करने के लिए 'ताज़ा करें' पर क्लिक करें।",
  refresh_button_title: 'ताज़ा करना',
  force_reload_message: 'माफ कीजिए, एक गलती हुई है। हमारी टीम पहले से ही इस पर काम कर रही है। कृपया एक पल में वेबसाइट को पुनः लोड करने का प्रयास करें।',
  sign_up: 'खाता बनाएं',
  service: 'सेवा',
  employee: 'कर्मचारी',
  select_employee: 'एक व्यक्ति का चयन करें',
  employees: 'कर्मचारी',
  services: 'सेवाएं',
  duration: 'अवधि',
  from: 'से',
  select_date: 'एक तारीख चुनें',
  no_account_question: 'अब तक कोई खाता नहीं है?',
  name: 'संतोष',
  surname: 'उपनाम',
  name_and_surname: 'पूरा नाम',
  phone: 'फ़ोन',
  password: 'पासवर्ड',
  field_is_required: 'यह फ़ील्ड आवश्यक है.',
  value_is_too_long: 'मान बहुत लंबा है.',
  value_is_too_short: 'मान बहुत छोटा है.',
  signup_was_successful: 'पंजीकरण सफल रहा। पैनल में साइन इन करना।',
  signup_has_failed: 'पंजीकरण विफल रहा। कृपया एक क्षण में पुन: प्रयास करें।',
  email_is_taken: 'दिए गए ईमेल पते वाला खाता पहले से मौजूद है। यदि आप अपना पासवर्ड भूल गए हैं, तो रिमाइंडर विकल्प का उपयोग करें।',
  email_or_password_incorrect: 'ईमेल पता या पासवर्ड गलत है। यदि आप अपना पासवर्ड भूल गए हैं, तो रिमाइंडर विकल्प का उपयोग करें।',
  account_not_found: 'खता नहीं मिला।',
  page_not_found: 'उफ़! आप जिस पेज की तलाश कर रहे थे वह नहीं मिला :(',
  go_back_to_main_page: 'होम पेज पर वापस जाएं और पुनः प्रयास करें।',
  bookings: 'बुकिंग के',
  account: 'मेरा खाता',
  date: 'दिनांक',
  cancel: 'रद्द करना',
  date_from: 'तारीख से',
  date_to: 'की तारीख',
  nothing_found_here: 'कुछ न मिला।',
  old_password: 'पुराना पासवर्ड',
  remove: 'मिटाना',
  phone_is_invalid: 'फोन नंबर गलत है।',
  book: 'किताब',
  sign_up_2: 'खाता बनाएं',
  log_in: 'साइन इन करें',
  your_email_address: 'आपका ईमेल पता',
  select: 'चुनना',
  filters: 'फिल्टर',
  expand: 'बढ़ाना',
  collapse: 'गिर जाना',
  bookings_drawer_title: 'केवल वे बुकिंग दिखाने के लिए फ़िल्टर चुनें जिन्हें आप ढूंढ रहे हैं।',
  clear_filters: 'फ़िल्टर रीसेट करें',
  show_more: 'और दिखाओ',
  dont_you_remember_password: 'पासवर्ड भूल गए हैं?',
  password_information: 'पासवर्ड में कम से कम 8 अक्षर होने चाहिए, इसमें एक बड़ा अक्षर, एक संख्या और एक विशेष वर्ण होना चाहिए।',
  forgotten_password_button: 'अपना पासवर्ड बदलने के लिए एक लिंक भेजें',
  do_you_already_have_an_account: 'क्या आपका पूर्व निर्मित खाता है?',
  reset_password_title: 'नया पासवर्ड सेट करें',
  reset_password_subtitle: 'नीचे अपना नया पासवर्ड डालिए।',
  reset_password_button: 'नया पासवर्ड सेव करें',
  profile: 'प्रोफ़ाइल',
  logout: 'साइन आउट',
  cancel_booking: 'अपनी बुकिंग रद्द करें',
  status_approved: 'स्वीकृत',
  status_payment: 'भुगतान की प्रतीक्षा',
  status_done: 'पूरा हुआ',
  status_cancelled: 'रद्द',
  cancel_booking_dialog_title: 'क्या आप अपनी बुकिंग रद्द करना चाहते हैं?',
  cancel_booking_dialog_information: 'यह कार्य दोबारा से नहीं हो सकता!',
  cancel_booking_dialog_button: 'हां, मेरी बुकिंग रद्द करें',
  my_profile: 'मेरी प्रोफाइल',
  my_data: 'मेरे विवरण',
  upload_new_photo: 'एक नई फ़ोटो जोड़ें',
  birthday: 'जन्मदिन',
  save_changes: 'परिवर्तनों को सुरक्षित करें',
  phone_number: 'फ़ोन नंबर',
  directional: 'एरिया कोड',
  save_new_password: 'नया पासवर्ड सेव करें',
  new_password: 'नया पासवर्ड',
  password_change: 'पासवर्ड बदलें',
  view: 'राय',
  services_2: 'सेवाएं',
  accept_terms_and_conditions_1: 'नीचे दिए गए विकल्प पर क्लिक करके, मैं इससे सहमत हूं',
  accept_terms_and_conditions_2: 'नियम और शर्तें',
  accept_terms_and_conditions_3: 'और मैं पुष्टि करता हूं कि मैंने इसे पढ़ लिया है',
  accept_terms_and_conditions_4: 'गोपनीयता वाले कथन।',
  to: 'प्रति',
  pln: '@{value} PLN',
  usd: '$@{value}',
  eur: '@{value} EUR',
  gbp: '£@{value}',
  jpy: '@{value} JPY',
  aud: '@{value} AUD',
  cad: '@{value} CAD',
  chf: '@{value} CHF',
  sek: '@{value} SEK',
  nzd: '@{value} NZD',
  brl: '@{value} BRL',
  bgn: '@{value} BGN',
  czk: '@{value} CZK',
  dkk: '@{value} DKK',
  hkd: '@{value} HKD',
  huf: '@{value} HUF',
  ils: '@{value} ILS',
  myr: '@{value} MYR',
  mxn: '@{value} MXN',
  twd: '@{value} TWD',
  nok: '@{value} NOK',
  php: '@{value} PHP',
  ron: '@{value} RON',
  rub: '@{value} RUB',
  sgd: '@{value} SGD',
  thb: '@{value} THB',
  uah: '@{value} UAH',
  inr: '@{value} INR',
  clp: '@{value} CLP',
  login: 'साइन इन करें',
  email_is_incorrect: 'ईमेल पता गलत है।',
  logged_out: 'आपको साइन आउट कर दिया गया है',
  booking_dialog_date_is_already_taken: 'चयनित समय अनुपलब्ध है। क्या आप एक अलग जगह चुनने का मन करेंगे?',
  data_search: 'डेटा के लिए खोज रहे हैं। कृपया प्रतीक्षा करें...',
  no_employees: 'कोई कर्मचारी नहीं',
  no_services: 'कोई सेवा नहीं',
  no_categories: 'कोई श्रेणी नहीं',
  no_bookings: 'बुकिंग नहीं मिली।',
  no_subscriptions: 'कोई सदस्यता नहीं मिली।',
  female: 'मादा',
  male: 'पुरुष',
  other: 'अन्य',
  no_numeric_in_password: 'पासवर्ड में कोई अंकीय वर्ण नहीं है।',
  no_lowercase_in_password: 'पासवर्ड में कोई लोअरकेस अक्षर नहीं है।',
  no_uppercase_in_password: 'पासवर्ड में कोई बड़ा अक्षर नहीं है।',
  no_special_character: 'कोई विशेष पात्र नहीं।',
  min_n_characters: 'वर्णों की न्यूनतम संख्या @{min} है।',
  max_n_characters: 'वर्णों की अधिकतम संख्या @{max} है।',
  email: 'ईमेल',
  reset_password_fail_text: 'पासवर्ड नहीं बदला गया है। लिंक शायद समाप्त हो गया है।',
  reset_password_success_title: 'आपका पासवर्ड बदल दिया गया है।',
  reset_password_success_text: 'आप अपने नए पासवर्ड से साइन इन कर सकते हैं।',
  account_activation: 'खाता सक्रियण',
  account_activation_success: 'खाता सक्रिय कर दिया गया है।',
  account_activation_fail: 'आपका खाता सक्रिय करते समय एक समस्या हुई थी। ऐसा लगता है कि खाता पहले से सक्रिय है या लिंक अब मान्य नहीं है।',
  account_activation_processing: 'आपके खाते को सक्रिय किया जा रहा है। कृपया प्रतीक्षा करें...',
  account_activation_resend_title: 'खाता सक्रिय नहीं किया गया है',
  account_activation_resend_text: 'हमारे द्वारा आपको भेजे गए ईमेल में सक्रियण लिंक पर क्लिक करके अपना खाता सक्रिय करें।',
  account_activation_resend_button: 'पुनः भेजें',
  confirm_send_activate_link_dialog_subtitle: 'हमने पहले दिए गए ईमेल पते पर सक्रियण लिंक के साथ एक ईमेल भेजा है।',
  file_is_too_large: 'चयनित फ़ाइल बहुत बड़ी है.',
  appointment_title: 'बुकिंग के लिए एक व्यक्ति, तिथि और समय का चयन करें',
  others: 'अन्य',
  countryLabel: 'देश',
  requiredMessages: 'एक फ़ोन नंबर आवश्यक है',
  date_of_visit: 'दिनांक',
  hour_of_visit: 'समय',
  thank_you_for_booking: 'आपकी बुकिंग',
  no_free_hours: 'चयनित दिन पर कोई उपलब्ध समय स्लॉट नहीं है।',
  any_person: 'यादृच्छिक विकल्प',
  password_changed: 'आपका पासवर्ड बदल दिया गया है',
  incorrect_old_password: 'पुराना पासवर्ड गलत है',
  error404: 'त्रुटि 404',
  minutes: 'मिनट',
  hours: 'एच',
  days: 'डी',
  ok: 'OK',
  web_page_temporary_disabled: 'माफ़ करना! यह पृष्ठ अस्थायी रूप से अक्षम किया गया था।',
  profile_update_success: 'आपका विवरण सहेज लिया गया है।',
  form_is_invalid: 'प्रपत्र में त्रुटियां हैं। कृपया हाइलाइट किए गए फ़ील्ड जांचें और पुन: प्रयास करें.',
  search: 'खोज',
  pay_online_button_title: 'ऑनलाइन भुगतान करें',
  account_not_activated_info: 'ईमेल पते की पुष्टि नहीं की गई है। भेजे गए संदेश में लिंक पर क्लिक करके अपने ई-मेल पते की पुष्टि करें।',
  paid: 'भुगतान किया गया',
  payments: 'भुगतान',
  payment_service: 'सेवा',
  payment_date: 'दिनांक',
  payment_price: 'कीमत',
  payment_success_title: 'भुगतान सफल रहा, धन्यवाद!',
  payment_fail_title: 'भुगतान विफल हो गया है, कृपया पुन: प्रयास करें।',
  pay_again: 'फिर से भुगतान करें',
  newsletter_subscribe_success: 'आपको धन्यवाद! आपके ईमेल पते की पुष्टि हो गई है।',
  newsletter_subscribe_fail: 'आपका ईमेल पता सक्रिय करते समय एक समस्या हुई थी। ऐसा लगता है कि ईमेल पता पहले से ही सक्रिय है या लिंक अब मान्य नहीं है।',
  newsletter_send_again: 'पुनः भेजें',
  newsletter_send_again_success: 'सक्रियण लिंक फिर से भेज दिया गया है।',
  send_again_fail: 'एक नया सक्रियण लिंक जनरेट करने में समस्या थी, हो सकता है कि आपका ईमेल पता पहले से ही सक्रिय हो या आपने अभी-अभी लिंक जनरेट किया हो।',
  newsletter_email_is_used: 'ईमेल पता पहले से ही सूची में है, अगर पते की पुष्टि नहीं हुई है, तो हम सक्रियण लिंक को फिर से भेज सकते हैं।',
  newsletter_unsubscribe_success: 'आपको धन्यवाद! आपका ईमेल पता हटा दिया गया है।',
  newsletter_unsubscribe_fail: 'ईमेल पता हटाने में एक समस्या हुई। ऐसा लगता है कि ईमेल पता पहले ही हटा दिया गया है या लिंक की समय सीमा समाप्त हो गई है।',
  booking_payments: 'बुकिंग के',
  simple_store_product_transactions: 'उत्पादों',
  payment_type_select_box: 'भुगतान का प्रकार चुनें',
  payment_description: 'विवरण',
  add_to_cart: 'कार्ट में जोड़ें',
  remove_from_cart: 'कार्ट से निकालें',
  save: 'बचाना',
  bookings_cart_summary: 'बुकिंग सारांश',
  today: 'आज',
  fill_booking_details_title: 'विवरण दर्ज करें',
  create_an_account_question: 'एक खाता बनाएं और ई-मेल पते पर पासवर्ड भेजें',
  value_is_incorrect: 'यह मान मान्य नहीं है।',
  first_available_date: 'उपलब्ध समय स्लॉट चालू हैं',
  service_location_1: 'पता',
  service_location_2: 'फ़ोन',
  service_location_3: 'Google Meet',
  service_location_4: 'अन्य',
  service_location_5: 'Zoom',
  service_location_6: 'Microsoft Teams',
  service_location_7: 'Skype',
  service_location_8: 'WhatsApp',
  service_location: 'स्थान',
  booking_not_found: 'बुकिंग नहीं मिली।',
  booking_canceled: 'बुकिंग कैंसिल कर दी गई।',
  booking_canceled_fail: 'यह बुकिंग रद्द नहीं की जा सकती।',
  day: 'दिन',
  week: 'सप्ताह',
  month: 'महीना',
  year: 'साल',
  booking_plural_1: 'बुकिंग',
  booking_plural_2: 'बुकिंग के',
  booking_plural_3: 'बुकिंग के',
  booking_plural_4: 'बुकिंग के',
  booking_plural_5: 'बुकिंग के',
  day_plural_1: 'दिन',
  day_plural_2: 'दिन',
  day_plural_3: 'दिन',
  day_plural_4: 'दिन',
  day_plural_5: 'दिन',
  week_plural_1: 'सप्ताह',
  week_plural_2: 'हफ्तों',
  week_plural_3: 'हफ्तों',
  week_plural_4: 'हफ्तों',
  week_plural_5: 'हफ्तों',
  month_plural_1: 'महीना',
  month_plural_2: 'महीने',
  month_plural_3: 'महीने',
  month_plural_4: 'महीने',
  month_plural_5: 'महीने',
  year_plural_1: 'साल',
  year_plural_2: 'वर्षों',
  year_plural_3: 'वर्षों',
  year_plural_4: 'वर्षों',
  year_plural_5: 'वर्षों',
  tax: 'VAT ID',
  tax_prefix: 'उपसर्ग',
  get_company_data: 'खोज',
  get_company_data_failed: 'डेटा डाउनलोड नहीं किया जा सका।',
  company_name: 'कंपनी का नाम',
  street_and_number: 'गली एवं संख्या',
  postcode: 'डाक कोड',
  city: 'शहर',
  country: 'देश',
  subscribe: 'सदस्यता लेने के',
  recurrent_fee_info: 'बिल हर',
  one_time_fee_info: 'बस एकबार दिए जाना वाला शुल्क।',
  new_account_title: 'एक नया खाता बनाएं',
  payment_data_title: 'भुगतान विवरण',
  billing_data_checkbox_question: 'बिलिंग विवरण',
  confirmation_modal_header: 'कार्रवाई की पुष्टि की आवश्यकता है',
  confirmation_modal_warning: 'क्या तुम वाकई जारी रखना चाहते हो?',
  no_way_to_undo_action: 'यह कार्य दोबारा से नहीं हो सकता!',
  delete_payment_method: 'कार्ड निकालें',
  add_payment_method: 'कार्ड जोड़ें',
  fill_card_details: 'भुगतान विवरण पूरा करें।',
  subscriptions: 'सदस्यता',
  subscription: 'अंशदान',
  invoice_number: 'इनवॉइस',
  number: 'संख्या',
  status: 'दर्जा',
  details: 'विवरण',
  created_at: 'बनाया था',
  subscription_status_initiated: 'अवैतनिक',
  subscription_status_active: 'सक्रिय',
  subscription_status_inactive: 'निष्क्रिय',
  subscription_status_canceled: 'रद्द',
  ends_at: 'खतम होता है',
  ends_at_default: 'बिलिंग अवधि के अंत तक मान्य',
  select_subscription_to_pay: 'भुगतान करने के लिए एक सदस्यता का चयन करें।',
  pay_with_subscription: 'सदस्यता के साथ भुगतान करें',
  bookings_paid_by_subscription_success_message: 'भुगतान किया गया',
  bookings_not_paid_by_subscription_error_message: 'चयनित सदस्यता के साथ बुकिंग का भुगतान नहीं किया जा सका।',
  stripe: 'Stripe',
  provider_tag: 'तरीका',
  card: 'कार्ड',
  cash: 'नकद',
  transfer: 'स्थानांतरण करना',
  promo_code_label: 'डिस्काउंट कोड',
  promo_code_invalid_message: 'दिया गया छूट कोड अमान्य है.',
  payment_method_setup_error: 'आपके कार्ड में कोई समस्या थी. आपके बैंक ने बाद के लेन-देन के लिए कार्ड को अधिकृत करने से मना कर दिया है। कृपया अपने बैंक से संपर्क करें।',
  active_subscription_missing: 'इस सेवा के लिए बुकिंग के लिए एक सक्रिय सदस्यता की आवश्यकता होती है। दुर्भाग्य से, आपके पास उपयोग करने के लिए कोई सदस्यता नहीं है।',
  area_code: 'एरिया कोड',
  usage: 'प्रयोग',
  create_account_in_stripe_failed: 'भुगतान ऑपरेटर ने आपके सब्सक्रिप्शन डेटा को अस्वीकार कर दिया है। कृपया फॉर्म की शुद्धता (कर पहचान संख्या, टेलीफोन नंबर, ई-मेल पता) की जांच करें।',
  additional_auth_required: 'आपके बैंक को अतिरिक्त प्राधिकरण की आवश्यकता है',
  general_payment_error: 'आपकी भुगतान विधि में कोई समस्या थी. शायद आपका कार्ड सदस्यताओं का समर्थन नहीं करता है। कृपया अपने बैंक से संपर्क करें या भुगतान का कोई दूसरा तरीका चुनें.',
  subscription_set_successfully: 'सदस्यता सही ढंग से सेट की गई।',
  booking_created: 'नई बुकिंग',
  booking_updated: 'बुकिंग में बदलाव',
  booking_deleted: 'बुकिंग हटाना',
  booking_reminder: 'बुकिंग रिमाइंडर',
  email_notifications: 'ईमेल सूचनाएं',
  sms_notifications: 'एसएमएस सूचनाएं',
  save_user_preferences: 'प्राथमिकताएं सहेजें',
  free_spots: 'स्थान शेष',
  no: 'नहीं',
  yes: 'हाँ',
  p24_inactive: 'भुगतान ऑपरेटर Przelewy24 का उपयोग नहीं किया जा सकता है। कृपया अपने भुगतान ऑपरेटर से संपर्क करें।',
  stripe_customer_invalid: 'अमान्य Stripe ग्राहक आईडी। भुगतान विधि को ग्राहक से नहीं जोड़ा जा सकता है।',
  stripe_resource_invalid: 'चयनित उत्पाद उपलब्ध नहीं है। कृपया भुगतान ऑपरेटर के साथ उत्पाद की उपलब्धता की जांच करें।',
  account_blocked_message: 'आपका खाता निष्क्रिय है। कृपया व्यवस्थापक से संपर्क करें।',
  time_zone: 'समय क्षेत्र',
  no_spots_left: 'आप उपलब्ध स्थानों की अधिकतम संख्या तक पहुंच गए हैं।',
  employee_auth_required: 'कृपया अपने खाते में लॉग इन करें या किसी भिन्न ईमेल पते का उपयोग करें। कर्मचारी खातों में लॉग इन करने की आवश्यकता है।',
  subpage_auth_required: 'टैब देखने के लिए कृपया अपने खाते में लॉग इन करें।',
  gross: 'सकल राशि।',
  subscription_login_info: 'क्या आपके पास पहले से एक खाता मौजूद है? अपना डेटा भरने के लिए कृपया नीचे साइन इन करें।',
  change_date_time: 'पुनर्निर्धारित',
  promo_code_checkbox: 'मेरे पास डिस्काउंट कोड है',
  booking_promo_code_label: 'छूट कोड दर्ज करें',
  select_hour: 'समय चुनें',
  id: 'Id',
  booking_rescheduled: 'आपकी बुकिंग बदल दी गई है।',
  booking_rescheduled_fail: 'इस बुकिंग को बदला नहीं जा सकता है।',
  max_user_bookings_error: 'आप बुकिंग की अधिकतम संख्या पार कर चुके हैं। कृपया कोई अन्य सेवा चुनें या हमसे संपर्क करें।',
  in_total: 'कुल',
  company: 'कंपनी',
  individual: 'निजी व्यक्ति',
  bulk_payment: 'थोक भुगतान',
  make_bulk_payment: 'थोक भुगतान',
  simple_store_product_confirmation_title: 'खरीद प्रपत्र को पूरा करें',
  select_service_type: 'एक सेवा विकल्प चुनें',
  add_to_calendar_button_label: 'कैलेंडर में जोड़े',
  login_to_use_service_with_subscription: 'इस सेवा के लिए अपॉइंटमेंट बुक करने के लिए कृपया अपने खाते में लॉग इन करें।',
  booking_dialog_date_is_already_taken_multiple_bookings: 'चुनी गई तारीख उपलब्ध नहीं है। कृपया कोई अन्य खाली तारीख चुनें। कई तारीखों की बुकिंग के मामले में, सुनिश्चित करें कि तारीखें एक दूसरे को बाधित न करें।',
  select_location_calendar_warning: 'उपलब्ध समय लोड करने के लिए स्थान चुनें।',
  selectable_location_online_title: 'ऑनलाइन',
  change: 'बदलें',
  show_less_hours_button: 'कम घंटे दिखाएं',
  show_more_hours_button: 'अधिक घंटे दिखाएं',
  add_another_button_title: 'एक और जोड़ें',
  close: 'बंद करें',
  selected_time_slots: 'चयनित समय स्लॉट',
  select_location: 'स्थान चुनें',
  captcha_invalid_error_notification: 'कैप्चा सत्यापन असफल रहा। यदि समस्या बनी रहती है तो पृष्ठ को पुनः लोड करने का प्रयास करें।',
  verify_code_incorrect_error: 'दर्ज किया गया कोड गलत है।',
  verify_code_too_many_requests: 'इस समय हम एक नया कोड जनरेट नहीं कर सकते। कृपया बाद में पुनः प्रयास करें या उस कोड का उपयोग करें जो हमने भेजा है।',
  send_again_button_title: 'फिर से भेजें',
  verify_title: 'सत्यापित करें',
  verify_code_title: 'सत्यापन कोड दर्ज करें',
  verify_code_description: 'हमने आपके ईमेल पर 6-अंकीय सत्यापन कोड भेजा है। कृपया इसे नीचे दर्ज करें:',
  verification_invalid_error_notification: 'अमान्य सत्यापन। बाद में पुनः प्रयास करें।',
  too_many_requests: 'ओह! आप थोड़ा तेज़ी से काम कर रहे हैं। कृपया धीमे हों और थोड़ी देर बाद पुनः प्रयास करें।',
  services_number_title: 'चयन करने के लिए सेवाओं की संख्या',
  booking_can_not_be_paid: 'भुगतान उपलब्ध नहीं है। पेज को रीफ़्रेश करें या बुकिंग देखने के लिए ईमेल में लिंक का फिर से उपयोग करें।',
  drag_and_drop_files_here_or_click_to_upload: 'फ़ाइलों को यहां खींचें और छोड़ें या अपलोड करने के लिए क्लिक करें',
  files_uploaded: 'अपलोड की गई फ़ाइलें',
  max_file_size_mb: 'अधिकतम फ़ाइल आकार: 10 MB',
  unknown_type: 'अज्ञात प्रकार',
  upload_file_error: 'फ़ाइल अपलोड करते समय एक त्रुटि हुई। स्वीकृत प्रारूप: PDF, XLSX, XLS, CSV, DOC, DOCX, JPG, JPEG, PNG, TXT। फ़ाइल का आकार 10 MB से अधिक नहीं होना चाहिए।',
  logged_in_info_notification: 'सफलतापूर्वक खाते में लॉग इन किया गया: @{email}',
  logged_out_info_notification: 'सफलतापूर्वक खाते से लॉग आउट किया गया: @{email}'
}
