export enum BookingDynamicFormTypes {
  TEXT_FIELD,
  TEXT_AREA,
  CHECKBOX,
  RADIO,
  EMAIL,
  PHONE,
  ADDRESS,
  FILES
}
