export default {
  error_occurred: 'Sorry, an error occurred. Please try again in a moment.',
  new_content_available: 'There is a new page update, please click "Refresh" to reload the page.',
  refresh_button_title: 'Refresh',
  force_reload_message: 'Sorry, an error has occurred. Our team is already working on it. Please try to reload the website in a moment.',
  sign_up: 'Create an account',
  service: 'Service',
  employee: 'Employee',
  select_employee: 'Select a person',
  employees: 'Employees',
  services: 'Services',
  duration: 'Duration',
  from: 'From',
  select_date: 'Select a date',
  no_account_question: 'Don\'t have an account yet?',
  name: 'First name',
  surname: 'Last name',
  name_and_surname: 'Full name',
  phone: 'Phone',
  password: 'Password',
  field_is_required: 'This field is required.',
  value_is_too_long: 'The value is too long.',
  value_is_too_short: 'The value is too short.',
  signup_was_successful: 'The registration was successful. Signing in to the panel.',
  signup_has_failed: 'The registration failed. Please try again in a moment.',
  email_is_taken: 'The account with the given email address already exists. If you forgot your password, use the reminder option.',
  email_or_password_incorrect: 'The e-mail address or password is incorrect. If you forgot your password, use the reminder option.',
  account_not_found: 'Account not found.',
  page_not_found: 'Oops! The page you were looking for could not be found :(',
  go_back_to_main_page: 'Go back to the home page and try again.',
  bookings: 'Bookings',
  account: 'My account',
  date: 'Date',
  cancel: 'Cancel',
  date_from: 'Date from',
  date_to: 'Date to',
  nothing_found_here: 'Nothing was found.',
  old_password: 'Old password',
  remove: 'Delete',
  phone_is_invalid: 'The phone number is incorrect.',
  book: 'Book',
  sign_up_2: 'Create an account',
  log_in: 'Sign in',
  your_email_address: 'Your e-mail address',
  select: 'Select',
  filters: 'Filters',
  expand: 'Expand',
  collapse: 'Collapse',
  bookings_drawer_title: 'Select filters to show only the bookings you are looking for.',
  clear_filters: 'Reset filters',
  show_more: 'Show more',
  dont_you_remember_password: 'Forgot your password?',
  password_information: 'The password should consist of at least 8 characters, contain an uppercase letter, a number, and a special character.',
  forgotten_password_button: 'Send a link to change your password',
  do_you_already_have_an_account: 'Do you already have an account?',
  reset_password_title: 'Set new password',
  reset_password_subtitle: 'Enter your new password below.',
  reset_password_button: 'Save the new password',
  profile: 'Profile',
  logout: 'Sign out',
  cancel_booking: 'Cancel your booking',
  status_approved: 'Approved',
  status_payment: 'Awaiting payment',
  status_done: 'Completed',
  status_cancelled: 'Canceled',
  cancel_booking_dialog_title: 'Do you want to cancel your booking?',
  cancel_booking_dialog_information: 'This action cannot be undone!',
  cancel_booking_dialog_button: 'Yes, cancel my booking',
  my_profile: 'My profile',
  my_data: 'My details',
  upload_new_photo: 'Add a new photo',
  birthday: 'Birthday',
  save_changes: 'Save changes',
  phone_number: 'Phone number',
  directional: 'Area code',
  save_new_password: 'Save the new password',
  new_password: 'New password',
  password_change: 'Change password',
  view: 'View',
  services_2: 'Services',
  accept_terms_and_conditions_1: 'By clicking the option below, I agree to the',
  accept_terms_and_conditions_2: 'Terms and Conditions',
  accept_terms_and_conditions_3: 'and I confirm that I have read the',
  accept_terms_and_conditions_4: 'Privacy Statement.',
  to: 'To',
  pln: 'PLN @{value}',
  usd: '$@{value}',
  eur: 'EUR @{value}',
  gbp: '£@{value}',
  jpy: 'JPY @{value}',
  aud: 'AUD @{value}',
  cad: 'CAD @{value}',
  chf: 'CHF @{value}',
  sek: 'SEK @{value}',
  nzd: 'NZD @{value}',
  brl: 'BRL @{value}',
  bgn: 'BGN @{value}',
  czk: 'CZK @{value}',
  dkk: 'DKK @{value}',
  hkd: 'HKD @{value}',
  huf: 'HUF @{value}',
  ils: 'ILS @{value}',
  myr: 'MYR @{value}',
  mxn: 'MXN @{value}',
  twd: 'TWD @{value}',
  nok: 'NOK @{value}',
  php: 'PHP @{value}',
  ron: 'RON @{value}',
  rub: 'RUB @{value}',
  sgd: 'SGD @{value}',
  thb: 'THB @{value}',
  uah: 'UAH @{value}',
  inr: 'INR @{value}',
  clp: 'CLP @{value}',
  login: 'Sign in',
  email_is_incorrect: 'The email address is incorrect.',
  logged_out: 'You have been signed out',
  booking_dialog_date_is_already_taken: 'The selected time is unavailable. Would you mind picking a different spot?',
  data_search: 'Searching for data. Please wait...',
  no_employees: 'No employees',
  no_services: 'No services',
  no_categories: 'No categories',
  no_bookings: 'Bookings not found.',
  no_subscriptions: 'No subscriptions were found.',
  female: 'Female',
  male: 'Male',
  other: 'Other',
  no_numeric_in_password: 'No numeric character in the password.',
  no_lowercase_in_password: 'No lowercase letter in the password.',
  no_uppercase_in_password: 'No uppercase letter in the password.',
  no_special_character: 'No special character.',
  min_n_characters: 'The minimum number of characters is @{min}.',
  max_n_characters: 'The maximum number of characters is @{max}.',
  email: 'E-mail',
  reset_password_fail_text: 'The password has not been changed. The link has probably expired.',
  reset_password_success_title: 'Your password has been changed.',
  reset_password_success_text: 'You can sign in with your new password.',
  account_activation: 'Account activation',
  account_activation_success: 'The account has been activated.',
  account_activation_fail: 'There was a problem when activating your account. It looks like the account is already active or the link is no longer valid.',
  account_activation_processing: 'Activating your account. Please wait...',
  account_activation_resend_title: 'The account has not been activated',
  account_activation_resend_text: 'Activate your account by clicking the activation link in the email that we sent you.',
  account_activation_resend_button: 'Send again',
  confirm_send_activate_link_dialog_subtitle: 'We have sent an email with the activation link to the email address provided earlier.',
  file_is_too_large: 'The selected file is too large.',
  appointment_title: 'Select a person, date, and time of booking',
  others: 'Other',
  countryLabel: 'Country',
  requiredMessages: 'A phone number is required',
  date_of_visit: 'Date',
  hour_of_visit: 'Time',
  thank_you_for_booking: 'Your booking',
  no_free_hours: 'No available time slots.',
  any_person: 'Random choice',
  password_changed: 'Your password has been changed',
  incorrect_old_password: 'The old password is incorrect',
  error404: 'Error 404',
  minutes: 'min',
  hours: 'h',
  days: 'd',
  ok: 'OK',
  web_page_temporary_disabled: 'Sorry! This page was temporarily disabled.',
  profile_update_success: 'Your details have been saved.',
  form_is_invalid: 'The form contains errors. Please check the highlighted fields and try again.',
  search: 'Search',
  pay_online_button_title: 'Pay Online',
  account_not_activated_info: 'The e-mail address has not been confirmed. Confirm your e-mail address by clicking the link in the sent message.',
  paid: 'Paid',
  payments: 'Payments',
  payment_service: 'Service',
  payment_date: 'Date',
  payment_price: 'Price',
  payment_success_title: 'The payment was successful, thank you!',
  payment_fail_title: 'The payment has failed, please try again.',
  pay_again: 'Pay again',
  newsletter_subscribe_success: 'Thank you! Your email address has been confirmed.',
  newsletter_subscribe_fail: 'There was a problem when activating your email address. It looks like the email address is already active or the link is no longer valid.',
  newsletter_send_again: 'Send again',
  newsletter_send_again_success: 'The activation link has been sent again.',
  send_again_fail: 'There was a problem generating a new activation link, maybe your email address is already active or you have just generated the link.',
  newsletter_email_is_used: 'The email address is already on the list, if the address has not been confirmed, we can resend the activation link.',
  newsletter_unsubscribe_success: 'Thank you! Your email address has been removed.',
  newsletter_unsubscribe_fail: 'There was a problem deleting the email address. It looks like the email address has already been deleted or the link has expired.',
  booking_payments: 'Bookings',
  simple_store_product_transactions: 'Products',
  payment_type_select_box: 'Select the type of payment',
  payment_description: 'Description',
  add_to_cart: 'Add to cart',
  remove_from_cart: 'Remove from the cart',
  save: 'Save',
  bookings_cart_summary: 'Booking summary',
  today: 'Today',
  fill_booking_details_title: 'Enter details',
  create_an_account_question: 'Create an account and send the password to the e-mail address',
  value_is_incorrect: 'This value is not valid.',
  first_available_date: 'Available time slots on',
  service_location_1: 'Address',
  service_location_2: 'Phone',
  service_location_3: 'Google Meet',
  service_location_4: 'Other',
  service_location_5: 'Zoom',
  service_location_6: 'Microsoft Teams',
  service_location_7: 'Skype',
  service_location_8: 'WhatsApp',
  service_location: 'Location',
  booking_not_found: 'Booking not found.',
  booking_canceled: 'The booking was canceled.',
  booking_canceled_fail: 'This booking cannot be canceled.',
  day: 'Day',
  week: 'Week',
  month: 'Month',
  year: 'Year',
  booking_plural_1: 'Booking',
  booking_plural_2: 'Bookings',
  booking_plural_3: 'Bookings',
  booking_plural_4: 'Bookings',
  booking_plural_5: 'Bookings',
  day_plural_1: 'Day',
  day_plural_2: 'Days',
  day_plural_3: 'Days',
  day_plural_4: 'Days',
  day_plural_5: 'Days',
  week_plural_1: 'Week',
  week_plural_2: 'Weeks',
  week_plural_3: 'Weeks',
  week_plural_4: 'Weeks',
  week_plural_5: 'Weeks',
  month_plural_1: 'Month',
  month_plural_2: 'Months',
  month_plural_3: 'Months',
  month_plural_4: 'Months',
  month_plural_5: 'Months',
  year_plural_1: 'Year',
  year_plural_2: 'Years',
  year_plural_3: 'Years',
  year_plural_4: 'Years',
  year_plural_5: 'Years',
  tax: 'VAT ID',
  tax_prefix: 'Prefix',
  get_company_data: 'Search',
  get_company_data_failed: 'The data could not be downloaded.',
  company_name: 'Company name',
  street_and_number: 'Street and number',
  postcode: 'Postal code',
  city: 'City',
  country: 'Country',
  subscribe: 'Subscribe',
  recurrent_fee_info: 'Billed every',
  one_time_fee_info: 'One-time fee.',
  new_account_title: 'Create a new account',
  payment_data_title: 'Payment details',
  billing_data_checkbox_question: 'Billing details',
  confirmation_modal_header: 'The action requires confirmation',
  confirmation_modal_warning: 'Are you sure you want to continue?',
  no_way_to_undo_action: 'This action cannot be undone!',
  delete_payment_method: 'Remove card',
  add_payment_method: 'Add card',
  fill_card_details: 'Complete the payment details.',
  subscriptions: 'Subscriptions',
  subscription: 'Subscription',
  invoice_number: 'Invoice',
  number: 'Number',
  status: 'Status',
  details: 'Details',
  created_at: 'Created',
  subscription_status_initiated: 'Unpaid',
  subscription_status_active: 'Active',
  subscription_status_inactive: 'Inactive',
  subscription_status_canceled: 'Canceled',
  ends_at: 'Ends at',
  ends_at_default: 'Valid until the end of the billing period',
  select_subscription_to_pay: 'Select a subscription to pay.',
  pay_with_subscription: 'Pay with subscription',
  bookings_paid_by_subscription_success_message: 'Paid',
  bookings_not_paid_by_subscription_error_message: 'The booking could not be paid with the selected subscription.',
  stripe: 'Stripe',
  provider_tag: 'Method',
  card: 'Card',
  cash: 'Cash',
  transfer: 'Transfer',
  promo_code_label: 'Discount code',
  promo_code_invalid_message: 'The provided discount code is invalid.',
  payment_method_setup_error: 'There was a problem with your card. Your bank has declined to authorize the card for later transactions. Please contact your bank.',
  active_subscription_missing: 'Booking for this service requires an active subscription. Unfortunately, you don\'t have any subscription to use.',
  area_code: 'Area code',
  usage: 'Usage',
  create_account_in_stripe_failed: 'The payment operator has rejected your subscription data. Please check the correctness of the form (tax identification number, telephone number, e-mail address).',
  additional_auth_required: 'Your bank requires additional authorization',
  general_payment_error: 'There was a problem with your payment. Perhaps your card does not support subscriptions, has purchase limits, or does not have enough funds to pay for the subscription. Please contact your bank or choose another payment method.',
  subscription_set_successfully: 'Subscription set up correctly.',
  booking_created: 'New booking',
  booking_updated: 'Change of booking',
  booking_deleted: 'Deletion of a booking',
  booking_reminder: 'Booking reminder',
  email_notifications: 'Email notifications',
  sms_notifications: 'SMS notifications',
  save_user_preferences: 'Save preferences',
  free_spots: 'Spots left',
  no: 'No',
  yes: 'Yes',
  p24_inactive: 'The payment operator Przelewy24 cannot be used. Please get in touch with your payment operator.',
  stripe_customer_invalid: 'Invalid Stripe Customer Id. The payment method cannot be attached to the customer.',
  stripe_resource_invalid: 'The selected product is not available. Please check the product availability with the payment operator.',
  account_blocked_message: 'Your account is inactive. Please contact the administrator.',
  time_zone: 'Time zone',
  no_spots_left: 'You have reached the maximum number of available spots.',
  employee_auth_required: 'Please log in to your account or use a different email address. Employee accounts require logging in.',
  subpage_auth_required: 'Please log in to your account to view the tab.',
  gross: 'Gross amount.',
  subscription_login_info: 'Already have an account? Please sign in below to fill in your data.',
  change_date_time: 'Reschedule',
  promo_code_checkbox: 'I have a discount code',
  booking_promo_code_label: 'Enter the discount code',
  select_hour: 'Choose time',
  id: 'Id',
  booking_rescheduled: 'Your booking has been changed.',
  booking_rescheduled_fail: 'This booking cannot be changed.',
  max_user_bookings_error: 'You have exceeded the maximum number of bookings. Please choose another service or contact us.',
  in_total: 'Total',
  company: 'Company',
  individual: 'Private person',
  bulk_payment: 'Bulk payment',
  make_bulk_payment: 'Bulk payment',
  simple_store_product_confirmation_title: 'Complete the purchase form',
  select_service_type: 'Select a service option',
  add_to_calendar_button_label: 'Add to Calendar',
  login_to_use_service_with_subscription: 'Please log in to your account to book an appointment for this service.',
  booking_dialog_date_is_already_taken_multiple_bookings: 'The selected date is unavailable. Please select another free date. In case of booking multiple dates, make sure the dates do not conflict with each other.',
  select_location_calendar_warning: 'Select a location to load available times.',
  selectable_location_online_title: 'Online',
  change: 'Change',
  show_less_hours_button: 'Show less hours',
  show_more_hours_button: 'Show more hours',
  add_another_button_title: 'Add another',
  close: 'Close',
  selected_time_slots: 'Selected time slots',
  select_location: 'Select location',
  captcha_invalid_error_notification: 'Captcha verification failed. Try reloading the page if the problem persists.',
  verify_code_incorrect_error: 'The code entered is incorrect.',
  verify_code_too_many_requests: 'We are unable to generate a new code at the moment. Please try again later or use the code we have already sent.',
  send_again_button_title: 'Send again',
  verify_title: 'Verify',
  verify_code_title: 'Enter verification code',
  verify_code_description: 'We have sent a 6-digit verification code to your email. Please enter it below:',
  verification_invalid_error_notification: 'Invalid verification. Try again later.',
  too_many_requests: 'Oops! You are acting a bit too fast. Slow down and try again shortly.',
  services_number_title: 'Number of services to choose from',
  booking_can_not_be_paid: 'Payment is unavailable. Refresh the page or use the link in the email again to view the booking.',
  drag_and_drop_files_here_or_click_to_upload: 'Drag and drop files here or click to upload',
  files_uploaded: 'Files uploaded',
  max_file_size_mb: 'Maximum file size: 10 MB',
  unknown_type: 'Unknown type',
  upload_file_error: 'An error occurred while uploading the file. Accepted formats: PDF, XLSX, XLS, CSV, DOC, DOCX, JPG, JPEG, PNG, TXT. File size must not exceed 10 MB.',
  logged_in_info_notification: 'Successfully logged into the account: @{email}',
  logged_out_info_notification: 'Successfully logged out of the account: @{email}'
}
