import {
  mdiAccountCircle,
  mdiAccountPlus,
  mdiArrowDownBold,
  mdiCalendarBlank,
  mdiChevronLeft,
  mdiChevronRight,
  mdiChevronDoubleLeft,
  mdiChevronDoubleRight,
  mdiCircle,
  mdiCreditCard,
  mdiEmail,
  mdiEye,
  mdiEyeOff, mdiFacebook,
  mdiFormatAlignJustify,
  mdiInformationOutline,
  mdiInstagram,
  mdiLock,
  mdiLogoutVariant,
  mdiMapMarker,
  mdiMenuDown,
  mdiMenuUp,
  mdiFilterVariant,
  mdiPhone,
  mdiTwitter,
  mdiYoutube,
  mdiCheck,
  mdiFileImage,
  mdiCart,
  mdiClock,
  mdiClose,
  mdiCreditCardOutline,
  mdiAlertCircle,
  mdiAccountReactivate,
  mdiShoppingOutline,
  mdiShopping,
  mdiEarth,
  mdiCartPlus,
  mdiCartRemove,
  mdiPlay,
  mdiArrowLeft,
  mdiCalendarPlus,
  mdiPlusCircleOutline,
  mdiMinusCircleOutline,
  mdiVideoAccount,
  mdiLinkedin,
  mdiDelete,
  mdiCloudUploadOutline
} from '@mdi/js'

const materialDesignIcons = {
  upload: mdiCloudUploadOutline,
  'plus-circle': mdiPlusCircleOutline,
  'minus-circle': mdiMinusCircleOutline,
  'arrow-left': mdiArrowLeft,
  play: mdiPlay,
  cart_plus: mdiCartPlus,
  cart_remove: mdiCartRemove,
  earth: mdiEarth,
  'shopping-outline': mdiShoppingOutline,
  shopping: mdiShopping,
  account: mdiAccountCircle,
  addUser: mdiAccountPlus,
  menu_up: mdiMenuUp,
  menu_down: mdiMenuDown,
  logout: mdiLogoutVariant,
  payments: mdiCreditCard,
  bookings: mdiFormatAlignJustify,
  subscriptions: mdiAccountReactivate,
  eye_on: mdiEye,
  eye_off: mdiEyeOff,
  password: mdiLock,
  mail: mdiEmail,
  information: mdiInformationOutline,
  marker: mdiMapMarker,
  phone: mdiPhone,
  chevron_left: mdiChevronLeft,
  chevron_right: mdiChevronRight,
  chevron_double_left: mdiChevronDoubleLeft,
  chevron_double_right: mdiChevronDoubleRight,
  circle: mdiCircle,
  calendar: mdiCalendarBlank,
  'calendar-plus': mdiCalendarPlus,
  arrow_down: mdiArrowDownBold,
  fb: mdiFacebook,
  yt: mdiYoutube,
  instagram: mdiInstagram,
  twitter: mdiTwitter,
  linkedin: mdiLinkedin,
  filter: mdiFilterVariant,
  check: mdiCheck,
  file: mdiFileImage,
  cart: mdiCart,
  clock: mdiClock,
  close: mdiClose,
  card: mdiCreditCardOutline,
  alert: mdiAlertCircle,
  video: mdiVideoAccount,
  'trash-default': mdiDelete
}

export default {
  ...materialDesignIcons
}
