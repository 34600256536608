export default {
  error_occurred: 'Ár leithscéal, tharla earráid. Bain triail eile as i gceann nóiméad le do thoil.',
  new_content_available: "Tá nuashonrú leathanach nua ann, cliceáil 'Athnuaigh' chun an leathanach a athlódáil.",
  refresh_button_title: 'Athnuaigh',
  force_reload_message: 'Ár leithscéal, tharla earráid. Tá ár bhfoireann ag obair air cheana féin. Déan iarracht an suíomh Gréasáin a athlódáil i gceann nóiméad le do thoil.',
  sign_up: 'Cruthaigh cuntas',
  service: 'Seirbhís',
  employee: 'Fostaí',
  select_employee: 'Roghnaigh duine',
  employees: 'Fostaithe',
  services: 'Seirbhísí',
  duration: 'Fad',
  from: 'Ó',
  select_date: 'Roghnaigh dáta',
  no_account_question: 'Níl cuntas agat fós?',
  name: 'Ainm',
  surname: 'Sloinne',
  name_and_surname: 'Ainm iomlán',
  phone: 'Fón',
  password: 'Pasfhocal',
  field_is_required: 'Tá an réimse seo ag teastáil.',
  value_is_too_long: 'Tá an luach ró-fhada.',
  value_is_too_short: 'Tá an luach ró-ghearr.',
  signup_was_successful: 'D’éirigh leis an gclárú. Ag síniú isteach ar an bpainéal.',
  signup_has_failed: 'Theip ar an gclárú. Bain triail eile as i gceann nóiméad le do thoil.',
  email_is_taken: 'Tá an cuntas leis an seoladh ríomhphoist tugtha ann cheana. Má rinne tú dearmad ar do phasfhocal, bain úsáid as an rogha meabhrúcháin.',
  email_or_password_incorrect: 'Tá an seoladh ríomhphoist nó an pasfhocal mícheart. Má rinne tú dearmad ar do phasfhocal, bain úsáid as an rogha meabhrúcháin.',
  account_not_found: 'Cuntas gan aimsiú.',
  page_not_found: 'Úps! Níorbh fhéidir an leathanach a bhí á lorg agat a fháil :(',
  go_back_to_main_page: 'Téigh ar ais go dtí an leathanach baile agus bain triail eile as.',
  bookings: 'Áirithintí',
  account: 'Mo chuntas',
  date: 'Dáta',
  cancel: 'Cealaigh',
  date_from: 'Dáta ó',
  date_to: 'Dáta le',
  nothing_found_here: 'Ní bhfuarthas aon rud.',
  old_password: 'Sean Pasfhocal',
  remove: 'Scrios',
  phone_is_invalid: 'Tá an uimhir theileafóin mícheart.',
  book: 'Leabhar',
  sign_up_2: 'Cruthaigh cuntas',
  log_in: 'Sínigh isteach',
  your_email_address: 'Do sheoladh ríomhphoist',
  select: 'Roghnaigh',
  filters: 'Scagairí',
  expand: 'Leathnaigh',
  collapse: 'Laghdaigh',
  bookings_drawer_title: 'Roghnaigh scagairí chun na háirithintí atá á lorg agat amháin a thaispeáint.',
  clear_filters: 'Athshocraigh na scagairí',
  show_more: 'Taispeáin níos mó',
  dont_you_remember_password: 'Ar rinne tú dearmad ar do Phásfhocail?',
  password_information: 'Ba chóir go mbeadh 8 gcarachtar ar a laghad sa phasfhocal, go mbeadh litir mhór, uimhir agus carachtar speisialta ann.',
  forgotten_password_button: 'Seol nasc chun do phasfhocal a athrú',
  do_you_already_have_an_account: 'An bhfuil cuntas agat cheana féin?',
  reset_password_title: 'Socraigh pasfhocal nua',
  reset_password_subtitle: 'Cuir isteach do phasfhocal nua thíos.',
  reset_password_button: 'Sábháil an pasfhocal nua',
  profile: 'Próifíl',
  logout: 'Logáil Amach',
  cancel_booking: "Cealaigh d'áirithint",
  status_approved: 'Ceadaithe',
  status_payment: 'Ag feitheamh le híocaíocht',
  status_done: 'Críochnaithe',
  status_cancelled: 'Ar ceal',
  cancel_booking_dialog_title: "Ar mhaith leat d'áirithint a chur ar ceal?",
  cancel_booking_dialog_information: 'Ní féidir an gníomh seo a chealú!',
  cancel_booking_dialog_button: 'Sea, cuir mo áirithint ar ceal',
  my_profile: 'Mo phróifíl',
  my_data: 'Mo chuid sonraí',
  upload_new_photo: 'Cuir grianghraf nua leis',
  birthday: 'Breithlá',
  save_changes: 'Sabháil na hathruithe',
  phone_number: 'Uimhir teileafón',
  directional: 'Cód Ceantair',
  save_new_password: 'Sábháil an pasfhocal nua',
  new_password: 'Focal Faire Nua',
  password_change: 'Athraigh do phasfhocal',
  view: 'Amharc',
  services_2: 'Seirbhísí',
  accept_terms_and_conditions_1: 'Trí chliceáil ar an rogha thíos, aontaím leis an',
  accept_terms_and_conditions_2: 'Téarmaí agus Coinníollacha',
  accept_terms_and_conditions_3: 'agus deimhním gur léigh mé an',
  accept_terms_and_conditions_4: 'Ráiteas Príobháideachta.',
  to: 'Chun',
  pln: '@{value} PLN',
  usd: '$@{value}',
  eur: '@{value} EUR',
  gbp: '£@{value}',
  jpy: '@{value} JPY',
  aud: '@{value} AUD',
  cad: '@{value} CAD',
  chf: '@{value} CHF',
  sek: '@{value} SEK',
  nzd: '@{value} NZD',
  brl: '@{value} BRL',
  bgn: '@{value} BGN',
  czk: '@{value} CZK',
  dkk: '@{value} DKK',
  hkd: '@{value} HKD',
  huf: '@{value} HUF',
  ils: '@{value} ILS',
  myr: '@{value} MYR',
  mxn: '@{value} MXN',
  twd: '@{value} TWD',
  nok: '@{value} NOK',
  php: '@{value} PHP',
  ron: '@{value} RON',
  rub: '@{value} RUB',
  sgd: '@{value} SGD',
  thb: '@{value} THB',
  uah: '@{value} UAH',
  inr: '@{value} INR',
  clp: '@{value} CLP',
  login: 'Sínigh isteach',
  email_is_incorrect: 'Tá an seoladh ríomhphoist mícheart.',
  logged_out: 'Síníodh amach thú',
  booking_dialog_date_is_already_taken: 'Níl an t-am roghnaithe ar fáil. Ar mhiste leat áit eile a phiocadh?',
  data_search: 'Ag cuardach sonraí. Fán le do thoil...',
  no_employees: 'Uimh fostaithe',
  no_services: 'Gan seirbhísí',
  no_categories: 'Uimh catagóirí',
  no_bookings: 'Ní bhfuarthas áirithintí.',
  no_subscriptions: 'Níor aimsíodh aon síntiús.',
  female: 'Mná',
  male: 'Fireann',
  other: 'Eile',
  no_numeric_in_password: 'Níl aon charachtar uimhriúil sa phasfhocal.',
  no_lowercase_in_password: 'Níl aon litir litreacha beaga sa phasfhocal.',
  no_uppercase_in_password: 'Níl aon litir chás uachtair sa phasfhocal.',
  no_special_character: 'Uimh carachtar speisialta.',
  min_n_characters: 'Is é @{min} an t-íoslíon carachtar.',
  max_n_characters: 'Is é @{max} uaslíon na gcarachtar.',
  email: 'R-phost',
  reset_password_fail_text: 'Níor athraíodh an pasfhocal. Is dócha go bhfuil an nasc imithe in éag.',
  reset_password_success_title: 'Athraíodh do phasfhocal.',
  reset_password_success_text: 'Is féidir leat síniú isteach le do phasfhocal nua.',
  account_activation: 'Gníomhachtú cuntais',
  account_activation_success: 'Tá an cuntas gníomhachtaithe.',
  account_activation_fail: 'Bhí fadhb ann agus do chuntas á ghníomhachtú. Tá an chuma ar an scéal go bhfuil an cuntas gníomhach cheana féin nó nach bhfuil an nasc bailí a thuilleadh.',
  account_activation_processing: 'Do chuntas á ghníomhachtú. Fán le do thoil...',
  account_activation_resend_title: 'Níl an cuntas gníomhachtaithe',
  account_activation_resend_text: 'Gníomhachtaigh do chuntas trí chliceáil ar an nasc gníomhachtaithe sa ríomhphost a sheolamar chugat.',
  account_activation_resend_button: 'Seol arís',
  confirm_send_activate_link_dialog_subtitle: 'Tá ríomhphost seolta againn leis an nasc gníomhachtaithe chuig an seoladh ríomhphoist a soláthraíodh níos luaithe.',
  file_is_too_large: 'Tá an comhad roghnaithe rómhór.',
  appointment_title: 'Roghnaigh duine, dáta, agus am na háirithinte',
  others: 'Eile',
  countryLabel: 'Tír',
  requiredMessages: 'Tá uimhir theileafóin ag teastáil',
  date_of_visit: 'Dáta',
  hour_of_visit: 'Am',
  thank_you_for_booking: "D'áirithint",
  no_free_hours: 'Níl aon sliotán ama ar fáil ar an lá roghnaithe.',
  any_person: 'Rogha randamach',
  password_changed: 'Athraíodh do phasfhocal',
  incorrect_old_password: 'Tá an seanfhocal faire mícheart',
  error404: 'Earráid 404',
  minutes: 'nóim',
  hours: 'h',
  days: 'd',
  ok: 'Ceart go leor',
  web_page_temporary_disabled: 'Tá brón orm! Díchumasaíodh an leathanach seo go sealadach.',
  profile_update_success: 'Sábháladh do shonraí.',
  form_is_invalid: 'Tá earráidí san fhoirm. Seiceáil na réimsí aibhsithe agus bain triail eile as.',
  search: 'Cuardach',
  pay_online_button_title: 'Íoc Ar Líne',
  account_not_activated_info: 'Níl an seoladh r-phoist deimhnithe. Deimhnigh do sheoladh r-phoist trí chliceáil ar an nasc sa teachtaireacht seolta.',
  paid: 'Íoctha',
  payments: 'Íocaíochtaí',
  payment_service: 'Seirbhís',
  payment_date: 'Dáta',
  payment_price: 'Praghas',
  payment_success_title: 'D’éirigh leis an íocaíocht, go raibh maith agat!',
  payment_fail_title: 'Theip ar an íocaíocht, bain triail eile as.',
  pay_again: 'Íoc arís',
  newsletter_subscribe_success: 'Go raibh maith agat! Deimhníodh do sheoladh ríomhphoist.',
  newsletter_subscribe_fail: 'Bhí fadhb ann agus do sheoladh ríomhphoist á ghníomhachtú. Tá an chuma ar an scéal go bhfuil an seoladh ríomhphoist gníomhach cheana féin nó nach bhfuil an nasc bailí a thuilleadh.',
  newsletter_send_again: 'Seol arís',
  newsletter_send_again_success: 'Tá an nasc gníomhachtaithe seolta arís.',
  send_again_fail: "Bhí fadhb ann le nasc gníomhachtaithe nua a ghiniúint, b'fhéidir go bhfuil do sheoladh ríomhphoist gníomhach cheana féin nó go bhfuil an nasc ginte agat.",
  newsletter_email_is_used: 'Tá an seoladh ríomhphoist ar an liosta cheana féin, mura bhfuil an seoladh deimhnithe, is féidir linn an nasc gníomhachtaithe a athsheoladh.',
  newsletter_unsubscribe_success: 'Go raibh maith agat! Baineadh do sheoladh ríomhphoist.',
  newsletter_unsubscribe_fail: 'Tharla fadhb agus an seoladh ríomhphoist á scriosadh. Tá an chuma ar an scéal go bhfuil an seoladh ríomhphoist scriosta cheana féin nó go bhfuil an nasc imithe in éag.',
  booking_payments: 'Áirithintí',
  simple_store_product_transactions: 'Táirgí',
  payment_type_select_box: 'Roghnaigh an cineál íocaíochta',
  payment_description: 'Cur síos',
  add_to_cart: 'Cuir leis an tralaí',
  remove_from_cart: 'Bain as an tralaí',
  save: 'Sábháil',
  bookings_cart_summary: 'Achoimre áirithinte',
  today: 'Inniu',
  fill_booking_details_title: 'Cuir isteach sonraí',
  create_an_account_question: 'Cruthaigh cuntas agus seol an pasfhocal chuig an seoladh r-phoist',
  value_is_incorrect: 'Níl an luach seo bailí.',
  first_available_date: 'Sliotáin ama ar fáil ar',
  service_location_1: 'Seoladh',
  service_location_2: 'Fón',
  service_location_3: 'Google Meet',
  service_location_4: 'Eile',
  service_location_5: 'Zoom',
  service_location_6: 'Microsoft Teams',
  service_location_7: 'Skype',
  service_location_8: 'WhatsApp',
  service_location: 'Suíomh',
  booking_not_found: 'Níor aimsíodh áirithint.',
  booking_canceled: 'Cuireadh an áirithint ar ceal.',
  booking_canceled_fail: 'Ní féidir an áirithint seo a chur ar ceal.',
  day: 'Lá',
  week: 'Seachtain',
  month: 'Mí',
  year: 'Bliain',
  booking_plural_1: 'Áirithint',
  booking_plural_2: 'Áirithintí',
  booking_plural_3: 'Áirithintí',
  booking_plural_4: 'Áirithintí',
  booking_plural_5: 'Áirithintí',
  day_plural_1: 'Lá',
  day_plural_2: 'Laethanta',
  day_plural_3: 'Laethanta',
  day_plural_4: 'Laethanta',
  day_plural_5: 'Laethanta',
  week_plural_1: 'Seachtain',
  week_plural_2: 'Seachtaine',
  week_plural_3: 'Seachtaine',
  week_plural_4: 'Seachtaine',
  week_plural_5: 'Seachtaine',
  month_plural_1: 'Mí',
  month_plural_2: 'Míonna',
  month_plural_3: 'Míonna',
  month_plural_4: 'Míonna',
  month_plural_5: 'Míonna',
  year_plural_1: 'Bliain',
  year_plural_2: 'Blianta',
  year_plural_3: 'Blianta',
  year_plural_4: 'Blianta',
  year_plural_5: 'Blianta',
  tax: 'Aitheantas CBL',
  tax_prefix: 'Réimír',
  get_company_data: 'Cuardach',
  get_company_data_failed: 'Níorbh fhéidir na sonraí a íoslódáil.',
  company_name: 'Ainm na Cuideachta',
  street_and_number: 'Sráid agus uimhir',
  postcode: 'Cód Poist',
  city: 'Cathair',
  country: 'Tír',
  subscribe: 'Liostáil',
  recurrent_fee_info: 'Billeáilte gach',
  one_time_fee_info: 'Táille aonuaire.',
  new_account_title: 'Cruthaigh cuntas nua',
  payment_data_title: 'Sonraí íocaíochta',
  billing_data_checkbox_question: 'Sonraí billeála',
  confirmation_modal_header: 'Teastaíonn deimhniú ón ngníomh',
  confirmation_modal_warning: 'An bhfuil tú cinnte gur mhaith leat leanúint ar aghaidh?',
  no_way_to_undo_action: 'Ní féidir an gníomh seo a chealú!',
  delete_payment_method: 'Bain cárta',
  add_payment_method: 'Cuir cárta leis',
  fill_card_details: 'Comhlánaigh na sonraí íocaíochta.',
  subscriptions: 'Síntiúis',
  subscription: 'Sintiús',
  invoice_number: 'Sonrasc',
  number: 'Uimhir',
  status: 'Stádas',
  details: 'Sonraí',
  created_at: 'Cruthaithe',
  subscription_status_initiated: 'Gan íoc',
  subscription_status_active: 'Gníomhach',
  subscription_status_inactive: 'Neamhghníomhach',
  subscription_status_canceled: 'Ar ceal',
  ends_at: 'Críochnaíonn ag',
  ends_at_default: 'Bailí go dtí deireadh na tréimhse billeála',
  select_subscription_to_pay: 'Roghnaigh síntiús le híoc.',
  pay_with_subscription: 'Íoc le síntiús',
  bookings_paid_by_subscription_success_message: 'Íoctha',
  bookings_not_paid_by_subscription_error_message: 'Níorbh fhéidir an áirithint a íoc leis an síntiús roghnaithe.',
  stripe: 'Stráice',
  provider_tag: 'Modh',
  card: 'Cárta',
  cash: 'Airgead',
  transfer: 'Aistriú',
  promo_code_label: 'Cóid lascaine',
  promo_code_invalid_message: 'Tá an cód lascaine a cuireadh ar fáil neamhbhailí.',
  payment_method_setup_error: 'Bhí fadhb le do chárta. Dhiúltaigh do bhanc an cárta a údarú le haghaidh idirbhearta níos déanaí. Déan teagmháil le do bhanc.',
  active_subscription_missing: 'Teastaíonn síntiús gníomhach chun áirithint a dhéanamh don tseirbhís seo. Ar an drochuair, níl aon síntiús agat le húsáid.',
  area_code: 'Cód Ceantair',
  usage: 'Úsáid',
  create_account_in_stripe_failed: 'Dhiúltaigh an t-oibreoir íocaíochta do shonraí síntiúis. Seiceáil le do thoil cruinneas na foirme (uimhir aitheantais cánach, uimhir theileafóin, seoladh r-phoist).',
  additional_auth_required: 'Tá údarú breise ag teastáil ó do bhanc',
  general_payment_error: "Bhí fadhb le d'íocaíocht. B’fhéidir nach dtacaíonn do chárta le síntiúis, go bhfuil teorainneacha ceannacháin aige, nó nach bhfuil go leor airgid aige chun íoc as an síntiús. Déan teagmháil le do bhanc nó roghnaigh modh íocaíochta eile.",
  subscription_set_successfully: 'Suibscríobh socraithe i gceart.',
  booking_created: 'Áirithint nua',
  booking_updated: 'Athrú ar áirithint',
  booking_deleted: 'Áirithint a scriosadh',
  booking_reminder: 'Meabhrúchán áirithinte',
  email_notifications: 'Fógraí ríomhphoist',
  sms_notifications: 'Fógraí SMS',
  save_user_preferences: 'Sábháil sainroghanna',
  free_spots: 'Spotaí fágtha',
  no: 'Níl',
  yes: 'Tá',
  p24_inactive: "Ní féidir an t-oibreoir íocaíochta Przelewy24 a úsáid. Téigh i dteagmháil le d'oibreoir íocaíochta le do thoil.",
  stripe_customer_invalid: 'Aitheantas Custaiméara Stripe Neamhbhailí. Ní féidir an modh íocaíochta a cheangal leis an gcustaiméir.',
  stripe_resource_invalid: 'Níl an táirge roghnaithe ar fáil. Seiceáil le do thoil infhaighteacht an táirge leis an oibreoir íocaíochta.',
  account_blocked_message: 'Tá do chuntas neamhghníomhach. Déan teagmháil leis an riarthóir le do thoil.',
  time_zone: 'Crios ama',
  no_spots_left: 'Tá uaslíon na spotaí atá ar fáil sroichte agat.',
  employee_auth_required: 'Logáil isteach i do chuntas nó úsáid seoladh ríomhphoist eile. Teastaíonn logáil isteach chun cuntais fostaithe.',
  subpage_auth_required: 'Logáil isteach i do chuntas chun an cluaisín a fheiceáil.',
  gross: 'Méid comhlán.',
  subscription_login_info: 'An bhfuil cuntas agat cheana féin? Sínigh isteach thíos le do thoil chun do shonraí a líonadh.',
  change_date_time: 'Athsceidealú',
  promo_code_checkbox: 'Tá cód lascaine agam',
  booking_promo_code_label: 'Cuir isteach an cód lascaine',
  select_hour: 'Roghnaigh am',
  id: 'Id',
  booking_rescheduled: "Athraíodh d'áirithint.",
  booking_rescheduled_fail: 'Ní féidir an áirithint seo a athrú.',
  max_user_bookings_error: 'Tá uaslíon na n-áirithintí sáraithe agat. Roghnaigh seirbhís eile le do thoil nó déan teagmháil linn.',
  in_total: 'Iomlán',
  company: 'Cuideachta',
  individual: 'Duine príobháideach',
  bulk_payment: 'Móríocaíocht',
  make_bulk_payment: 'Móríocaíocht',
  simple_store_product_confirmation_title: 'Comhlánaigh an fhoirm cheannaigh',
  select_service_type: 'Roghnaigh rogha seirbhíse',
  add_to_calendar_button_label: 'Cuir le Féilire',
  login_to_use_service_with_subscription: 'Logáil isteach i do chuntas le do thoil chun coinne a chur in áirithe don tseirbhís seo.',
  booking_dialog_date_is_already_taken_multiple_bookings: 'Níl an dáta roghnaithe ar fáil. Roghnaigh dáta saor eile le do thoil. Má tá tú ag áirithint iliomad dátaí, déan cinnte nach mbíonn na dátaí ag cur isteach ar a chéile.',
  select_location_calendar_warning: 'Roghnaigh suíomh chun na hamanna atá ar fáil a lódáil.',
  selectable_location_online_title: 'Ar Líne',
  change: 'Athraigh',
  show_less_hours_button: 'Taispeáin níos lú uaireanta',
  show_more_hours_button: 'Taispeáin níos mó uaireanta',
  add_another_button_title: 'Cuir ceann eile leis',
  close: 'Dún',
  selected_time_slots: 'Sliotáin ama roghnaithe',
  select_location: 'Roghnaigh suíomh',
  captcha_invalid_error_notification: 'Níor éirigh le fíorú captcha. Déan iarracht an leathanach a athlódáil má leanann an fhadhb ar aghaidh.',
  verify_code_incorrect_error: 'Tá an cód a cuireadh isteach mícheart.',
  verify_code_too_many_requests: 'Ní féidir linn cód nua a ghiniúint faoi láthair. Déan iarracht arís níos déanaí nó bain úsáid as an gcód a sheolamar chugat.',
  send_again_button_title: 'Seol arís',
  verify_title: 'Deimhnigh',
  verify_code_title: 'Cuir isteach cód fíoraithe',
  verify_code_description: 'Chuireamar cód fíoraithe 6 dhigit chuig do r-phost. Cuir isteach é thíos le do thoil:',
  verification_invalid_error_notification: 'Fíorú neamhbhailí. Bain triail eile as níos déanaí.',
  too_many_requests: 'Ó, tá tú ag gníomhú beagáinín ró-thapa. Moilligh agus bain triail eile as ar ball.',
  services_number_title: 'Líon seirbhísí le roghnú astu',
  booking_can_not_be_paid: 'Níl an íocaíocht ar fáil. Athnuaigh an leathanach nó bain úsáid as an nasc sa ríomhphost arís chun an áirithint a fheiceáil.',
  drag_and_drop_files_here_or_click_to_upload: 'Tarraing agus scaoil comhaid anseo nó cliceáil chun iad a uaslódáil',
  files_uploaded: 'Comhaid uaslódáilte',
  max_file_size_mb: 'Uasmhéid comhaid: 10 MB',
  unknown_type: 'Cineál anaithnid',
  upload_file_error: 'Tharla earráid agus an comhad á uaslódáil. Formáidí inghlactha: PDF, XLSX, XLS, CSV, DOC, DOCX, JPG, JPEG, PNG, TXT. Ní féidir le méid an chomhaid dul thar 10 MB.',
  logged_in_info_notification: 'Logáilte isteach go rathúil i gcuntas: @{email}',
  logged_out_info_notification: 'Logáilte amach go rathúil as cuntas: @{email}'
}
