export default {
  error_occurred: 'Atsiprašome, įvyko klaida. Po akimirkos bandykite dar kartą.',
  new_content_available: 'Yra naujas puslapio atnaujinimas. Norėdami iš naujo įkelti puslapį, spustelėkite „Atnaujinti“.',
  refresh_button_title: 'Atnaujinti',
  force_reload_message: 'Atsiprašome, įvyko klaida. Mūsų komanda prie to jau dirba. Pabandykite akimirksniu iš naujo įkelti svetainę.',
  sign_up: 'Sukurti paskyrą',
  service: 'Aptarnavimas',
  employee: 'Darbuotojas',
  select_employee: 'Pasirinkite asmenį',
  employees: 'Darbuotojai',
  services: 'Paslaugos',
  duration: 'Trukmė',
  from: 'Iš',
  select_date: 'Pasirinkite datą',
  no_account_question: 'Dar neturite paskyros?',
  name: 'Pirmas vardas',
  surname: 'Pavardė',
  name_and_surname: 'Pilnas vardas',
  phone: 'Telefonas',
  password: 'Slaptažodis',
  field_is_required: 'Šį lauką būtina užpildyti.',
  value_is_too_long: 'Vertė per ilga.',
  value_is_too_short: 'Vertė per trumpa.',
  signup_was_successful: 'Registracija buvo sėkminga. Prisijungimas prie skydelio.',
  signup_has_failed: 'Registracija nepavyko. Po akimirkos bandykite dar kartą.',
  email_is_taken: 'Paskyra su nurodytu el. pašto adresu jau yra. Jei pamiršote slaptažodį, naudokite priminimo parinktį.',
  email_or_password_incorrect: 'El. pašto adresas arba slaptažodis neteisingas. Jei pamiršote slaptažodį, naudokite priminimo parinktį.',
  account_not_found: 'Paskyra nerasta.',
  page_not_found: 'Oi! Puslapio, kurio ieškojote, nepavyko rasti :(',
  go_back_to_main_page: 'Grįžkite į pagrindinį puslapį ir bandykite dar kartą.',
  bookings: 'Užsakymai',
  account: 'Mano sąskaita',
  date: 'Data',
  cancel: 'Atšaukti',
  date_from: 'Data nuo',
  date_to: 'Data iki',
  nothing_found_here: 'Nieko nerasta.',
  old_password: 'Senas slaptažodis',
  remove: 'Ištrinti',
  phone_is_invalid: 'Telefono numeris neteisingas.',
  book: 'Knyga',
  sign_up_2: 'Sukurti paskyrą',
  log_in: 'Prisijungti',
  your_email_address: 'Jūsų elektroninio pašto adresas',
  select: 'Pasirinkite',
  filters: 'Filtrai',
  expand: 'Išskleisti',
  collapse: 'Sutraukti',
  bookings_drawer_title: 'Pasirinkite filtrus, kad būtų rodomi tik užsakymai, kurių ieškote.',
  clear_filters: 'Iš naujo nustatyti filtrus',
  show_more: 'Rodyti daugiau',
  dont_you_remember_password: 'Pamiršai slaptažodį?',
  password_information: 'Slaptažodis turi būti sudarytas iš mažiausiai 8 simbolių, sudarytas iš didžiosios raidės, skaičiaus ir specialaus simbolio.',
  forgotten_password_button: 'Siųskite nuorodą, kad pakeistumėte slaptažodį',
  do_you_already_have_an_account: 'Ar jau turite paskyrą?',
  reset_password_title: 'Nustatykite naują slaptažodį',
  reset_password_subtitle: 'Toliau įveskite naują slaptažodį.',
  reset_password_button: 'Išsaugokite naują slaptažodį',
  profile: 'Profilis',
  logout: 'Atsijungti',
  cancel_booking: 'Atšaukite užsakymą',
  status_approved: 'Patvirtinta',
  status_payment: 'Laukia apmokėjimo',
  status_done: 'Baigta',
  status_cancelled: 'Atšauktas',
  cancel_booking_dialog_title: 'Ar norite atšaukti užsakymą?',
  cancel_booking_dialog_information: 'Šio veiksmo anuliuoti negalima!',
  cancel_booking_dialog_button: 'Taip, atšaukti mano užsakymą',
  my_profile: 'Mano profilis',
  my_data: 'Mano duomenys',
  upload_new_photo: 'Pridėti naują nuotrauką',
  birthday: 'Gimtadienis',
  save_changes: 'Išsaugoti pakeitimus',
  phone_number: 'Telefono numeris',
  directional: 'Vietovės kodas',
  save_new_password: 'Išsaugokite naują slaptažodį',
  new_password: 'Naujas Slaptažodis',
  password_change: 'Pakeisti slaptažodį',
  view: 'Žiūrėti',
  services_2: 'Paslaugos',
  accept_terms_and_conditions_1: 'Spustelėdamas toliau pateiktą parinktį sutinku su',
  accept_terms_and_conditions_2: 'Taisyklės ir sąlygos',
  accept_terms_and_conditions_3: 'ir patvirtinu, kad perskaičiau',
  accept_terms_and_conditions_4: 'Privatumo pareiškimas.',
  to: 'Į',
  pln: '@{value} PLN',
  usd: '$@{value}',
  eur: '@{value} EUR',
  gbp: '£@{value}',
  jpy: '@{value} JPY',
  aud: '@{value} AUD',
  cad: '@{value} CAD',
  chf: '@{value} CHF',
  sek: '@{value} SEK',
  nzd: '@{value} NZD',
  brl: '@{value} BRL',
  bgn: '@{value} BGN',
  czk: '@{value} CZK',
  dkk: '@{value} DKK',
  hkd: '@{value} HKD',
  huf: '@{value} HUF',
  ils: '@{value} ILS',
  myr: '@{value} MYR',
  mxn: '@{value} MXN',
  twd: '@{value} TWD',
  nok: '@{value} NOK',
  php: '@{value} PHP',
  ron: '@{value} RON',
  rub: '@{value} RUB',
  sgd: '@{value} SGD',
  thb: '@{value} THB',
  uah: '@{value} UAH',
  inr: '@{value} INR',
  clp: '@{value} CLP',
  login: 'Prisijungti',
  email_is_incorrect: 'El. pašto adresas neteisingas.',
  logged_out: 'Jūs buvote atjungti',
  booking_dialog_date_is_already_taken: 'Pasirinktas laikas nepasiekiamas. Ar norėtumėte pasirinkti kitą vietą?',
  data_search: 'Ieškoma duomenų. Prašau palauk...',
  no_employees: 'Darbuotojų nėra',
  no_services: 'Jokių paslaugų',
  no_categories: 'Nėra kategorijų',
  no_bookings: 'Užsakymai nerasta.',
  no_subscriptions: 'Prenumeratos nerasta.',
  female: 'Moteris',
  male: 'Patinas',
  other: 'Kita',
  no_numeric_in_password: 'Slaptažodyje nėra skaitinio simbolio.',
  no_lowercase_in_password: 'Slaptažodyje nėra mažųjų raidžių.',
  no_uppercase_in_password: 'Slaptažodyje nėra didžiųjų raidžių.',
  no_special_character: 'Jokio ypatingo charakterio.',
  min_n_characters: 'Mažiausias simbolių skaičius yra @{min}.',
  max_n_characters: 'Didžiausias simbolių skaičius yra @{max}.',
  email: 'Paštas',
  reset_password_fail_text: 'Slaptažodis nebuvo pakeistas. Tikriausiai nuorodos galiojimo laikas baigėsi.',
  reset_password_success_title: 'Tavo slaptazodis buvo pakeistas.',
  reset_password_success_text: 'Galite prisijungti naudodami naują slaptažodį.',
  account_activation: 'Paskyros aktyvinimas',
  account_activation_success: 'Paskyra suaktyvinta.',
  account_activation_fail: 'Aktyvinant paskyrą kilo problema. Panašu, kad paskyra jau aktyvi arba nuoroda nebegalioja.',
  account_activation_processing: 'Aktyvinama paskyra. Prašau palauk...',
  account_activation_resend_title: 'Paskyra nesuaktyvinta',
  account_activation_resend_text: 'Suaktyvinkite paskyrą spustelėdami aktyvinimo nuorodą mūsų atsiųstame el. laiške.',
  account_activation_resend_button: 'Siųsti dar kartą',
  confirm_send_activate_link_dialog_subtitle: 'Išsiuntėme el. laišką su aktyvinimo nuoroda anksčiau pateiktu el. pašto adresu.',
  file_is_too_large: 'Pasirinktas failas per didelis.',
  appointment_title: 'Pasirinkite asmenį, užsakymo datą ir laiką',
  others: 'Kita',
  countryLabel: 'Šalis',
  requiredMessages: 'Reikalingas telefono numeris',
  date_of_visit: 'Data',
  hour_of_visit: 'Laikas',
  thank_you_for_booking: 'Jūsų užsakymas',
  no_free_hours: 'Pasirinktą dieną nėra laisvų laiko tarpsnių.',
  any_person: 'Atsitiktinis pasirinkimas',
  password_changed: 'tavo slaptazodis buvo pakeistas',
  incorrect_old_password: 'Senas slaptažodis neteisingas',
  error404: '404 klaida',
  minutes: 'min',
  hours: 'h',
  days: 'd',
  ok: 'Gerai',
  web_page_temporary_disabled: 'Atsiprašau! Šis puslapis buvo laikinai išjungtas.',
  profile_update_success: 'Jūsų duomenys buvo išsaugoti.',
  form_is_invalid: 'Formoje yra klaidų. Patikrinkite paryškintus laukus ir bandykite dar kartą.',
  search: 'Paieška',
  pay_online_button_title: 'Mokėti internetu',
  account_not_activated_info: 'El. pašto adresas nepatvirtintas. Patvirtinkite savo el. pašto adresą spustelėdami atsiųstame pranešime esančią nuorodą.',
  paid: 'Mokama',
  payments: 'Mokėjimai',
  payment_service: 'Aptarnavimas',
  payment_date: 'Data',
  payment_price: 'Kaina',
  payment_success_title: 'Mokėjimas pavyko, ačiū!',
  payment_fail_title: 'Mokėjimas nepavyko, bandykite dar kartą.',
  pay_again: 'Mokėkite dar kartą',
  newsletter_subscribe_success: 'Ačiū! Jūsų el. pašto adresas patvirtintas.',
  newsletter_subscribe_fail: 'Aktyvinant el. pašto adresą kilo problema. Panašu, kad el. pašto adresas jau aktyvus arba nuoroda nebegalioja.',
  newsletter_send_again: 'Siųsti dar kartą',
  newsletter_send_again_success: 'Aktyvinimo nuoroda vėl išsiųsta.',
  send_again_fail: 'Generuojant naują aktyvinimo nuorodą kilo problema, galbūt jūsų el. pašto adresas jau aktyvus arba ką tik sugeneravote nuorodą.',
  newsletter_email_is_used: 'Pašto adresas jau yra sąraše, jei adresas nepatvirtintas, galime iš naujo išsiųsti aktyvinimo nuorodą.',
  newsletter_unsubscribe_success: 'Ačiū! Jūsų el. pašto adresas pašalintas.',
  newsletter_unsubscribe_fail: 'Ištrinant el. pašto adresą kilo problema. Panašu, kad el. pašto adresas jau buvo ištrintas arba nuoroda nebegalioja.',
  booking_payments: 'Užsakymai',
  simple_store_product_transactions: 'Produktai',
  payment_type_select_box: 'Pasirinkite mokėjimo tipą',
  payment_description: 'apibūdinimas',
  add_to_cart: 'Į krepšelį',
  remove_from_cart: 'Išimkite iš krepšelio',
  save: 'Sutaupyti',
  bookings_cart_summary: 'Užsakymo santrauka',
  today: 'Šiandien',
  fill_booking_details_title: 'Įveskite išsamią informaciją',
  create_an_account_question: 'Susikurkite paskyrą ir atsiųskite slaptažodį el. pašto adresu',
  value_is_incorrect: 'Ši vertė negalioja.',
  first_available_date: 'Galimi laiko tarpai įjungti',
  service_location_1: 'Adresas',
  service_location_2: 'Telefonas',
  service_location_3: 'Google Meet',
  service_location_4: 'Kita',
  service_location_5: 'Zoom',
  service_location_6: 'Microsoft Teams',
  service_location_7: 'Skype',
  service_location_8: 'WhatsApp',
  service_location: 'Vieta',
  booking_not_found: 'Užsakymas nerastas.',
  booking_canceled: 'Užsakymas buvo atšauktas.',
  booking_canceled_fail: 'Šis užsakymas negali būti atšauktas.',
  day: 'Diena',
  week: 'Savaitė',
  month: 'Mėnuo',
  year: 'Metai',
  booking_plural_1: 'Rezervavimas',
  booking_plural_2: 'Užsakymai',
  booking_plural_3: 'Užsakymai',
  booking_plural_4: 'Užsakymai',
  booking_plural_5: 'Užsakymai',
  day_plural_1: 'Diena',
  day_plural_2: 'Dienos',
  day_plural_3: 'Dienos',
  day_plural_4: 'Dienos',
  day_plural_5: 'Dienos',
  week_plural_1: 'Savaitė',
  week_plural_2: 'Savaitės',
  week_plural_3: 'Savaitės',
  week_plural_4: 'Savaitės',
  week_plural_5: 'Savaitės',
  month_plural_1: 'Mėnuo',
  month_plural_2: 'Mėnesių',
  month_plural_3: 'Mėnesių',
  month_plural_4: 'Mėnesių',
  month_plural_5: 'Mėnesių',
  year_plural_1: 'Metai',
  year_plural_2: 'Metai',
  year_plural_3: 'Metai',
  year_plural_4: 'Metai',
  year_plural_5: 'Metai',
  tax: 'PVM mokėtojo kodas',
  tax_prefix: 'Priešdėlis',
  get_company_data: 'Paieška',
  get_company_data_failed: 'Nepavyko atsisiųsti duomenų.',
  company_name: 'Įmonės pavadinimas',
  street_and_number: 'Gatvė ir numeris',
  postcode: 'Pašto kodas',
  city: 'Miestas',
  country: 'Šalis',
  subscribe: 'Prenumeruoti',
  recurrent_fee_info: 'Atsiskaitoma kas',
  one_time_fee_info: 'Vienkartinis mokestis.',
  new_account_title: 'Sukurti naują paskyrą',
  payment_data_title: 'Mokėjimo detalės',
  billing_data_checkbox_question: 'Atsiskaitymo informacija',
  confirmation_modal_header: 'Veiksmui reikia patvirtinimo',
  confirmation_modal_warning: 'Ar tikrai norite tęsti?',
  no_way_to_undo_action: 'Šio veiksmo anuliuoti negalima!',
  delete_payment_method: 'Pašalinti kortelę',
  add_payment_method: 'Pridėti kortelę',
  fill_card_details: 'Užpildykite mokėjimo informaciją.',
  subscriptions: 'Prenumeratos',
  subscription: 'Prenumerata',
  invoice_number: 'Sąskaita faktūra',
  number: 'Skaičius',
  status: 'Būsena',
  details: 'Detalės',
  created_at: 'Sukurta',
  subscription_status_initiated: 'Neapmokėta',
  subscription_status_active: 'Aktyvus',
  subscription_status_inactive: 'Neaktyvus',
  subscription_status_canceled: 'Atšauktas',
  ends_at: 'Baigiasi val',
  ends_at_default: 'Galioja iki atsiskaitymo laikotarpio pabaigos',
  select_subscription_to_pay: 'Pasirinkite prenumeratą, kurią norite sumokėti.',
  pay_with_subscription: 'Mokėti su abonementu',
  bookings_paid_by_subscription_success_message: 'Mokama',
  bookings_not_paid_by_subscription_error_message: 'Užsakymo nepavyko apmokėti pasirinkta prenumerata.',
  stripe: 'Juostelė',
  provider_tag: 'Metodas',
  card: 'Kort',
  cash: 'Grynieji pinigai',
  transfer: 'Perdavimas',
  promo_code_label: 'Nuolaidos kodas',
  promo_code_invalid_message: 'Pateiktas nuolaidos kodas neteisingas.',
  payment_method_setup_error: 'Iškilo problema dėl kortelės. Jūsų bankas atsisakė leisti kortelę atlikti vėlesnėms operacijoms. Susisiekite su savo banku.',
  active_subscription_missing: 'Norint užsisakyti šią paslaugą, reikalinga aktyvi prenumerata. Deja, jūs neturite jokios prenumeratos, kuria galėtumėte naudotis.',
  area_code: 'Vietovės kodas',
  usage: 'Naudojimas',
  create_account_in_stripe_failed: 'Mokėjimo operatorius atmetė jūsų prenumeratos duomenis. Prašome patikrinti formos teisingumą (mokesčių mokėtojo kodas, telefono numeris, el. pašto adresas).',
  additional_auth_required: 'Jūsų bankui reikalingas papildomas įgaliojimas',
  general_payment_error: 'Iškilo problema dėl mokėjimo. Galbūt jūsų kortelė nepalaiko prenumeratos, turi pirkimo limitus arba neturi pakankamai lėšų sumokėti už prenumeratą. Susisiekite su banku arba pasirinkite kitą mokėjimo būdą.',
  subscription_set_successfully: 'Prenumerata nustatyta teisingai.',
  booking_created: 'Naujas užsakymas',
  booking_updated: 'Užsakymo pakeitimas',
  booking_deleted: 'Užsakymo ištrynimas',
  booking_reminder: 'Užsakymo priminimas',
  email_notifications: 'Pranešimai el. paštu',
  sms_notifications: 'SMS pranešimai',
  save_user_preferences: 'Išsaugokite nuostatas',
  free_spots: 'Liko dėmės',
  no: 'Nr',
  yes: 'Taip',
  p24_inactive: 'Negalima naudoti mokėjimo operatoriaus Przelewy24. Susisiekite su savo mokėjimo operatoriumi.',
  stripe_customer_invalid: 'Neteisingas Stripe kliento ID. Mokėjimo būdas negali būti prisegtas prie kliento.',
  stripe_resource_invalid: 'Pasirinkto produkto nėra. Prašome patikrinti produkto prieinamumą su mokėjimo operatoriumi.',
  account_blocked_message: 'Jūsų paskyra neaktyvi. Susisiekite su administratoriumi.',
  time_zone: 'Laiko zona',
  no_spots_left: 'Pasiekėte maksimalų laisvų vietų skaičių.',
  employee_auth_required: 'Prisijunkite prie savo paskyros arba naudokite kitą el. pašto adresą. Darbuotojų paskyrose reikia prisijungti.',
  subpage_auth_required: 'Jei norite peržiūrėti skirtuką, prisijunkite prie savo paskyros.',
  gross: 'Bendra suma.',
  subscription_login_info: 'Jau turite paskyrą? Norėdami užpildyti savo duomenis, prisijunkite žemiau.',
  change_date_time: 'Suplanuoti iš naujo',
  promo_code_checkbox: 'Turiu nuolaidos kodą',
  booking_promo_code_label: 'Įveskite nuolaidos kodą',
  select_hour: 'Pasirinkite laiką',
  id: 'Id',
  booking_rescheduled: 'Jūsų užsakymas buvo pakeistas.',
  booking_rescheduled_fail: 'Šio užsakymo pakeisti negalima.',
  max_user_bookings_error: 'Viršijote maksimalų užsakymų skaičių. Pasirinkite kitą paslaugą arba susisiekite su mumis.',
  in_total: 'Iš viso',
  company: 'Bendrovė',
  individual: 'Privatus asmuo',
  bulk_payment: 'Masinis mokėjimas',
  make_bulk_payment: 'Masinis mokėjimas',
  simple_store_product_confirmation_title: 'Užpildykite pirkimo formą',
  select_service_type: 'Pasirinkite paslaugos parinktį',
  add_to_calendar_button_label: 'Pridėti prie kalendoriaus',
  login_to_use_service_with_subscription: 'Prisijunkite prie savo paskyros, kad rezervuotumėte susitikimą šiai paslaugai gauti.',
  booking_dialog_date_is_already_taken_multiple_bookings: 'Pasirinkta data neprieinama. Prašome pasirinkti kitą laisvą datą. Rezervuojant kelias datas, įsitikinkite, kad datos nesikerta.',
  select_location_calendar_warning: 'Pasirinkite vietą, kad įkeltumėte galimus laikus.',
  selectable_location_online_title: 'Internetu',
  change: 'Keisti',
  show_less_hours_button: 'Rodyti mažiau valandų',
  show_more_hours_button: 'Rodyti daugiau valandų',
  add_another_button_title: 'Pridėti dar vieną',
  close: 'Uždaryti',
  selected_time_slots: 'Pasirinkti laiko tarpsniai',
  select_location: 'Pasirinkite vietą',
  captcha_invalid_error_notification: 'Captcha patvirtinimas nepavyko. Bandykite atnaujinti puslapį, jei problema išliks.',
  verify_code_incorrect_error: 'Įvestas kodas neteisingas.',
  verify_code_too_many_requests: 'Šiuo metu negalime sugeneruoti naujo kodo. Pabandykite vėliau arba naudokite jau išsiųstą kodą.',
  send_again_button_title: 'Siųsti dar kartą',
  verify_title: 'Patvirtinti',
  verify_code_title: 'Įveskite patvirtinimo kodą',
  verify_code_description: 'Išsiuntėme 6 skaitmenų patvirtinimo kodą į jūsų el. paštą. Įveskite jį žemiau:',
  verification_invalid_error_notification: 'Neteisinga patikra. Pabandykite vėliau dar kartą.',
  too_many_requests: 'Ups! Veikiate šiek tiek per greitai. Sulėtinkite tempą ir pabandykite dar kartą po akimirkos.',
  services_number_title: 'Pasirenkamų paslaugų skaičius',
  booking_can_not_be_paid: 'Mokėjimas negalimas. Atnaujinkite puslapį arba dar kartą naudokitės nuoroda iš el. laiško, kad peržiūrėtumėte rezervaciją.',
  drag_and_drop_files_here_or_click_to_upload: 'Vilkdami failus įkelkite juos čia arba spustelėkite, kad įkeltumėte',
  files_uploaded: 'Įkelti failai',
  max_file_size_mb: 'Maksimalus failo dydis: 10 MB',
  unknown_type: 'Nežinomas tipas',
  upload_file_error: 'Įkeliant failą įvyko klaida. Priimami formatai: PDF, XLSX, XLS, CSV, DOC, DOCX, JPG, JPEG, PNG, TXT. Failo dydis negali viršyti 10 MB.',
  logged_in_info_notification: 'Sėkmingai prisijungta prie paskyros: @{email}',
  logged_out_info_notification: 'Sėkmingai atsijungta nuo paskyros: @{email}'
}
