export default {
  error_occurred: 'Na vjen keq, ka ndodhur një gabim. Ju lutemi provoni sërish pas një momenti.',
  new_content_available: "Ka një përditësim të ri të faqes, ju lutemi klikoni 'Rifresko' për të ringarkuar faqen.",
  refresh_button_title: 'Rifresko',
  force_reload_message: 'Na vjen keq, ka ndodhur një gabim. Ekipi ynë tashmë është duke punuar për të. Ju lutemi provoni ta rifreskoni faqen e internetit brenda një momenti.',
  sign_up: 'Krijo nje llogari',
  service: 'Shërbimi',
  employee: 'Punonjës',
  select_employee: 'Zgjidhni një person',
  employees: 'Punonjësit',
  services: 'Shërbimet',
  duration: 'Kohëzgjatja',
  from: 'Nga',
  select_date: 'Zgjidhni një datë',
  no_account_question: 'Nuk keni ende një llogari?',
  name: 'Emri',
  surname: 'Mbiemri',
  name_and_surname: 'Emri i plotë',
  phone: 'Telefoni',
  password: 'Fjalëkalimi',
  field_is_required: 'Kjo fushë është e nevojshme.',
  value_is_too_long: 'Vlera është shumë e gjatë.',
  value_is_too_short: 'Vlera është shumë e shkurtër.',
  signup_was_successful: 'Regjistrimi ishte i suksesshëm. Hyrja në panel.',
  signup_has_failed: 'Regjistrimi dështoi. Ju lutemi provoni sërish pas një momenti.',
  email_is_taken: 'Llogaria me adresën e dhënë të emailit ekziston tashmë. Nëse keni harruar fjalëkalimin tuaj, përdorni opsionin përkujtues.',
  email_or_password_incorrect: 'Adresa e postës elektronike ose fjalëkalimi është i pasaktë. Nëse keni harruar fjalëkalimin tuaj, përdorni opsionin përkujtues.',
  account_not_found: 'Llogaria nuk u gjet.',
  page_not_found: 'Oops! Faqja që po kërkoni nuk u gjet :(',
  go_back_to_main_page: 'Kthehuni në faqen kryesore dhe provoni përsëri.',
  bookings: 'Rezervimet',
  account: 'Llogaria ime',
  date: 'Data',
  cancel: 'Anulo',
  date_from: 'Data nga',
  date_to: 'Data në',
  nothing_found_here: 'Asgjë nuk u gjet.',
  old_password: 'Fjalëkalimi i vjetër',
  remove: 'Fshije',
  phone_is_invalid: 'Numri i telefonit është i pasaktë.',
  book: 'Libër',
  sign_up_2: 'Krijo nje llogari',
  log_in: 'Hyni',
  your_email_address: 'Adresa juaj e emailit',
  select: 'Zgjidhni',
  filters: 'Filtrat',
  expand: 'Zgjerojeni',
  collapse: 'Kolapsi',
  bookings_drawer_title: 'Zgjidhni filtra për të shfaqur vetëm rezervimet që kërkoni.',
  clear_filters: 'Rivendos filtrat',
  show_more: 'Trego më shumë',
  dont_you_remember_password: 'Keni harruar fjalëkalimin tuaj?',
  password_information: 'Fjalëkalimi duhet të përbëhet nga të paktën 8 karaktere, të përmbajë një shkronjë të madhe, një numër dhe një karakter të veçantë.',
  forgotten_password_button: 'Dërgoni një lidhje për të ndryshuar fjalëkalimin tuaj',
  do_you_already_have_an_account: 'A keni tashmë një llogari?',
  reset_password_title: 'Vendosni një fjalëkalim të ri',
  reset_password_subtitle: 'Futni fjalëkalimin tuaj të ri më poshtë.',
  reset_password_button: 'Ruani fjalëkalimin e ri',
  profile: 'Profili',
  logout: 'Dilni',
  cancel_booking: 'Anuloni rezervimin tuaj',
  status_approved: 'Miratuar',
  status_payment: 'Duke pritur pagesen',
  status_done: 'E përfunduar',
  status_cancelled: 'Anuluar',
  cancel_booking_dialog_title: 'Dëshiron të anulosh rezervimin?',
  cancel_booking_dialog_information: 'Ky veprim nuk mund të zhbëhet!',
  cancel_booking_dialog_button: 'Po, anuloje rezervimin tim',
  my_profile: 'Profili im',
  my_data: 'Detajet e mia',
  upload_new_photo: 'Shto një foto të re',
  birthday: 'ditëlindjen',
  save_changes: 'Ruaj ndryshimet',
  phone_number: 'Numri i telefonit',
  directional: 'Kodi i zonës',
  save_new_password: 'Ruani fjalëkalimin e ri',
  new_password: 'Fjalëkalim i ri',
  password_change: 'Ndrysho fjalekalimin',
  view: 'Pamje',
  services_2: 'Shërbimet',
  accept_terms_and_conditions_1: 'Duke klikuar opsionin më poshtë, unë pranoj',
  accept_terms_and_conditions_2: 'Termat dhe Kushtet',
  accept_terms_and_conditions_3: 'dhe konfirmoj që e kam lexuar',
  accept_terms_and_conditions_4: 'Deklarata e Privatësisë.',
  to: 'te',
  pln: '@{value} PLN',
  usd: '$@{value}',
  eur: '@{value} EUR',
  gbp: '£@{value}',
  jpy: '@{value} JPY',
  aud: '@{value} AUD',
  cad: '@{value} CAD',
  chf: '@{value} CHF',
  sek: '@{value} SEK',
  nzd: '@{value} NZD',
  brl: '@{value} BRL',
  bgn: '@{value} BGN',
  czk: '@{value} CZK',
  dkk: '@{value} DKK',
  hkd: '@{value} HKD',
  huf: '@{value} HUF',
  ils: '@{value} ILS',
  myr: '@{value} MYR',
  mxn: '@{value} MXN',
  twd: '@{value} TWD',
  nok: '@{value} NOK',
  php: '@{value} PHP',
  ron: '@{value} RON',
  rub: '@{value} RUB',
  sgd: '@{value} SGD',
  thb: '@{value} THB',
  uah: '@{value} UAH',
  inr: '@{value} INR',
  clp: '@{value} CLP',
  login: 'Hyni',
  email_is_incorrect: 'Adresa e emailit është e pasaktë.',
  logged_out: 'Ju keni dalë nga llogaria',
  booking_dialog_date_is_already_taken: 'Koha e zgjedhur është e padisponueshme. Dëshironi të zgjidhni një vend tjetër?',
  data_search: 'Duke kërkuar për të dhëna. Te lutem prit...',
  no_employees: 'Asnjë punonjës',
  no_services: 'Asnjë shërbim',
  no_categories: 'Asnjë kategori',
  no_bookings: 'Rezervimet nuk u gjetën.',
  no_subscriptions: 'Nuk u gjet asnjë abonim.',
  female: 'Femër',
  male: 'Mashkull',
  other: 'Të tjera',
  no_numeric_in_password: 'Nuk ka karakter numerik në fjalëkalim.',
  no_lowercase_in_password: 'Nuk ka shkronjë të vogël në fjalëkalim.',
  no_uppercase_in_password: 'Nuk ka shkronjë të madhe në fjalëkalim.',
  no_special_character: 'Asnjë personazh i veçantë.',
  min_n_characters: 'Numri minimal i karaktereve është @{min}.',
  max_n_characters: 'Numri maksimal i karaktereve është @{max}.',
  email: 'E-mail',
  reset_password_fail_text: 'Fjalëkalimi nuk është ndryshuar. Lidhja ndoshta ka skaduar.',
  reset_password_success_title: 'Fjalekalimi juaj eshte ndryshuar.',
  reset_password_success_text: 'Mund të identifikoheni me fjalëkalimin tuaj të ri.',
  account_activation: 'Aktivizimi i llogarisë',
  account_activation_success: 'Llogaria është aktivizuar.',
  account_activation_fail: 'Pati një problem gjatë aktivizimit të llogarisë. Duket sikur llogaria është tashmë aktive ose lidhja nuk është më e vlefshme.',
  account_activation_processing: 'Duke aktivizuar llogarinë tuaj. Te lutem prit...',
  account_activation_resend_title: 'Llogaria nuk është aktivizuar',
  account_activation_resend_text: 'Aktivizoni llogarinë tuaj duke klikuar lidhjen e aktivizimit në emailin që ju dërguam.',
  account_activation_resend_button: 'Dergoje perseri',
  confirm_send_activate_link_dialog_subtitle: 'Ne kemi dërguar një email me lidhjen e aktivizimit në adresën e emailit të dhënë më parë.',
  file_is_too_large: 'Skedari i zgjedhur është shumë i madh.',
  appointment_title: 'Zgjidhni një person, datë dhe orë të rezervimit',
  others: 'Të tjera',
  countryLabel: 'Vendi',
  requiredMessages: 'Kërkohet një numër telefoni',
  date_of_visit: 'Data',
  hour_of_visit: 'Koha',
  thank_you_for_booking: 'Rezervimi juaj',
  no_free_hours: 'Nuk ka kohë të disponueshme në ditën e zgjedhur.',
  any_person: 'Zgjedhje e rastësishme',
  password_changed: 'Fjalekalimi juaj eshte ndryshuar',
  incorrect_old_password: 'Fjalëkalimi i vjetër është i pasaktë',
  error404: 'Gabim 404',
  minutes: 'min',
  hours: 'h',
  days: 'd',
  ok: 'Ne rregull',
  web_page_temporary_disabled: 'Na vjen keq! Kjo faqe u çaktivizua përkohësisht.',
  profile_update_success: 'Të dhënat tuaja janë ruajtur.',
  form_is_invalid: 'Formulari përmban gabime. Ju lutemi kontrolloni fushat e theksuara dhe provoni përsëri.',
  search: 'Kërko',
  pay_online_button_title: 'Paguani Online',
  account_not_activated_info: 'Adresa e emailit nuk është konfirmuar. Konfirmoni adresën tuaj të postës elektronike duke klikuar lidhjen në mesazhin e dërguar.',
  paid: 'I paguar',
  payments: 'Pagesat',
  payment_service: 'Shërbimi',
  payment_date: 'Data',
  payment_price: 'Çmimi',
  payment_success_title: 'Pagesa ishte e suksesshme, faleminderit!',
  payment_fail_title: 'Pagesa dështoi, ju lutemi provoni përsëri.',
  pay_again: 'Paguaj përsëri',
  newsletter_subscribe_success: 'Faleminderit! Adresa juaj e emailit është konfirmuar.',
  newsletter_subscribe_fail: 'Pati një problem gjatë aktivizimit të adresës suaj të emailit. Duket sikur adresa e emailit është tashmë aktive ose lidhja nuk është më e vlefshme.',
  newsletter_send_again: 'Dergoje perseri',
  newsletter_send_again_success: 'Lidhja e aktivizimit është dërguar sërish.',
  send_again_fail: 'Pati një problem me krijimin e një lidhjeje të re aktivizimi, ndoshta adresa juaj e emailit është tashmë aktive ose sapo keni krijuar lidhjen.',
  newsletter_email_is_used: 'Adresa e emailit është tashmë në listë, nëse adresa nuk është konfirmuar, ne mund ta ridërgojmë lidhjen e aktivizimit.',
  newsletter_unsubscribe_success: 'Faleminderit! Adresa juaj e emailit është hequr.',
  newsletter_unsubscribe_fail: 'Pati një problem me fshirjen e adresës së emailit. Duket sikur adresa e emailit është fshirë tashmë ose lidhja ka skaduar.',
  booking_payments: 'Rezervimet',
  simple_store_product_transactions: 'Produktet',
  payment_type_select_box: 'Zgjidhni llojin e pagesës',
  payment_description: 'Përshkrim',
  add_to_cart: 'Shto në shportë',
  remove_from_cart: 'Hiqeni nga karroca',
  save: 'Ruaj',
  bookings_cart_summary: 'Përmbledhja e rezervimit',
  today: 'Sot',
  fill_booking_details_title: 'Futni detajet',
  create_an_account_question: 'Krijoni një llogari dhe dërgoni fjalëkalimin në adresën e postës elektronike',
  value_is_incorrect: 'Kjo vlerë nuk është e vlefshme.',
  first_available_date: 'Hapësirat kohore të disponueshme janë aktive',
  service_location_1: 'Adresë',
  service_location_2: 'Telefoni',
  service_location_3: 'Google Meet',
  service_location_4: 'Të tjera',
  service_location_5: 'Zoom',
  service_location_6: 'Microsoft Teams',
  service_location_7: 'Skype',
  service_location_8: 'WhatsApp',
  service_location: 'Vendndodhja',
  booking_not_found: 'Rezervimi nuk u gjet.',
  booking_canceled: 'Rezervimi u anulua.',
  booking_canceled_fail: 'Ky rezervim nuk mund të anulohet.',
  day: 'Dita',
  week: 'Javë',
  month: 'Muaj',
  year: 'Viti',
  booking_plural_1: 'Rezervimi',
  booking_plural_2: 'Rezervimet',
  booking_plural_3: 'Rezervimet',
  booking_plural_4: 'Rezervimet',
  booking_plural_5: 'Rezervimet',
  day_plural_1: 'Dita',
  day_plural_2: 'Ditët',
  day_plural_3: 'Ditët',
  day_plural_4: 'Ditët',
  day_plural_5: 'Ditët',
  week_plural_1: 'javë',
  week_plural_2: 'Javë',
  week_plural_3: 'Javë',
  week_plural_4: 'Javë',
  week_plural_5: 'Javë',
  month_plural_1: 'Muaj',
  month_plural_2: 'Muaj',
  month_plural_3: 'Muaj',
  month_plural_4: 'Muaj',
  month_plural_5: 'Muaj',
  year_plural_1: 'Viti',
  year_plural_2: 'Vite',
  year_plural_3: 'Vite',
  year_plural_4: 'Vite',
  year_plural_5: 'Vite',
  tax: 'ID e TVSH-së',
  tax_prefix: 'Parashtesa',
  get_company_data: 'Kërko',
  get_company_data_failed: 'Të dhënat nuk mund të shkarkoheshin.',
  company_name: 'Emri i Kompanise',
  street_and_number: 'Rruga dhe numri',
  postcode: 'Kodi Postar',
  city: 'Qyteti',
  country: 'Vendi',
  subscribe: 'Abonohu',
  recurrent_fee_info: 'Faturohet çdo',
  one_time_fee_info: 'Tarifa një herë.',
  new_account_title: 'Krijo nje llogari te re',
  payment_data_title: 'Detajet e pagesës',
  billing_data_checkbox_question: 'Detajet e faturimit',
  confirmation_modal_header: 'Veprimi kërkon konfirmim',
  confirmation_modal_warning: 'Je i sigurt që dëshiron të vazhdosh?',
  no_way_to_undo_action: 'Ky veprim nuk mund të zhbëhet!',
  delete_payment_method: 'Hiq kartën',
  add_payment_method: 'Shto kartë',
  fill_card_details: 'Plotësoni detajet e pagesës.',
  subscriptions: 'Abonimet',
  subscription: 'Abonimi',
  invoice_number: 'Fatura',
  number: 'Numri',
  status: 'Statusi',
  details: 'Detajet',
  created_at: 'Krijuar',
  subscription_status_initiated: 'E papaguar',
  subscription_status_active: 'Aktiv',
  subscription_status_inactive: 'Joaktiv',
  subscription_status_canceled: 'Anuluar',
  ends_at: 'Përfundon në',
  ends_at_default: 'E vlefshme deri në fund të periudhës së faturimit',
  select_subscription_to_pay: 'Zgjidhni një abonim për të paguar.',
  pay_with_subscription: 'Paguani me abonim',
  bookings_paid_by_subscription_success_message: 'I paguar',
  bookings_not_paid_by_subscription_error_message: 'Rezervimi nuk mund të paguhej me abonimin e zgjedhur.',
  stripe: 'Shirit',
  provider_tag: 'Metoda',
  card: 'Kartelë',
  cash: 'Paratë e gatshme',
  transfer: 'Transferimi',
  promo_code_label: 'Kodi i zbritjes',
  promo_code_invalid_message: 'Kodi i ofruar i zbritjes është i pavlefshëm.',
  payment_method_setup_error: 'Pati një problem me kartën tuaj. Banka juaj ka refuzuar të autorizojë kartën për transaksione të mëvonshme. Ju lutemi kontaktoni bankën tuaj.',
  active_subscription_missing: 'Rezervimi për këtë shërbim kërkon një abonim aktiv. Fatkeqësisht, nuk keni asnjë abonim për të përdorur.',
  area_code: 'Kodi i zonës',
  usage: 'Përdorimi',
  create_account_in_stripe_failed: 'Operatori i pagesave ka refuzuar të dhënat e abonimit tuaj. Ju lutemi kontrolloni korrektësinë e formularit (numri i identifikimit tatimor, numri i telefonit, adresa e e-mail).',
  additional_auth_required: 'Banka juaj kërkon autorizim shtesë',
  general_payment_error: 'Pati një problem me pagesën tuaj. Ndoshta karta juaj nuk mbështet abonimet, ka kufij blerjesh ose nuk ka fonde të mjaftueshme për të paguar për abonimin. Ju lutemi kontaktoni bankën tuaj ose zgjidhni një mënyrë tjetër pagese.',
  subscription_set_successfully: 'Abonimi është konfiguruar saktë.',
  booking_created: 'Rezervim i ri',
  booking_updated: 'Ndryshimi i rezervimit',
  booking_deleted: 'Fshirja e një rezervimi',
  booking_reminder: 'Kujtesa e rezervimit',
  email_notifications: 'Njoftimet me email',
  sms_notifications: 'Njoftimet SMS',
  save_user_preferences: 'Ruaj preferencat',
  free_spots: 'Pikat e mbetura',
  no: 'Nr',
  yes: 'Po',
  p24_inactive: 'Operatori i pagesave Przelewy24 nuk mund të përdoret. Ju lutemi kontaktoni operatorin tuaj të pagesës.',
  stripe_customer_invalid: "Id i pavlefshëm i klientit Stripe. Mënyra e pagesës nuk mund t'i bashkëngjitet klientit.",
  stripe_resource_invalid: 'Produkti i zgjedhur nuk është i disponueshëm. Ju lutemi kontrolloni disponueshmërinë e produktit me operatorin e pagesave.',
  account_blocked_message: 'Llogaria juaj është joaktive. Ju lutemi kontaktoni administratorin.',
  time_zone: 'Zona kohore',
  no_spots_left: 'Keni arritur numrin maksimal të vendeve të disponueshme.',
  employee_auth_required: 'Ju lutemi hyni në llogarinë tuaj ose përdorni një adresë tjetër emaili. Llogaritë e punonjësve kërkojnë hyrje.',
  subpage_auth_required: 'Ju lutemi hyni në llogarinë tuaj për të parë skedën.',
  gross: 'Shuma bruto.',
  subscription_login_info: 'Posedoni një llogari? Ju lutemi, regjistrohuni më poshtë për të plotësuar të dhënat tuaja.',
  change_date_time: 'Riprogramoni',
  promo_code_checkbox: 'Unë kam një kod zbritje',
  booking_promo_code_label: 'Futni kodin e zbritjes',
  select_hour: 'Zgjidhni kohën',
  id: 'Id',
  booking_rescheduled: 'Rezervimi juaj është ndryshuar.',
  booking_rescheduled_fail: 'Ky rezervim nuk mund të ndryshohet.',
  max_user_bookings_error: 'Ju keni tejkaluar numrin maksimal të rezervimeve. Ju lutemi zgjidhni një shërbim tjetër ose na kontaktoni.',
  in_total: 'Total',
  company: 'Kompania',
  individual: 'Njeri privat',
  bulk_payment: 'Pagesa me shumicë',
  make_bulk_payment: 'Pagesa me shumicë',
  simple_store_product_confirmation_title: 'Plotësoni formularin e blerjes',
  select_service_type: 'Zgjidhni një opsion shërbimi',
  add_to_calendar_button_label: 'Shto në Kalendar',
  login_to_use_service_with_subscription: 'Ju lutemi hyni në llogarinë tuaj për të rezervuar një takim për këtë shërbim.',
  booking_dialog_date_is_already_taken_multiple_bookings: 'Data e zgjedhur nuk është e disponueshme. Ju lutemi zgjidhni një datë tjetër të lirë. Në rastin e rezervimit të disa datave, sigurohuni që datat të mos përjashtojnë njëra-tjetrën.',
  select_location_calendar_warning: 'Zgjidhni një vendndodhje për të ngarkuar kohët e disponueshme.',
  selectable_location_online_title: 'Online',
  change: 'Ndrysho',
  show_less_hours_button: 'Shfaq më pak orë',
  show_more_hours_button: 'Shfaq më shumë orë',
  add_another_button_title: 'Shto një tjetër',
  close: 'Mbylle',
  selected_time_slots: 'Orari i zgjedhur',
  select_location: 'Zgjidhni vendndodhjen',
  captcha_invalid_error_notification: 'Verifikimi i Captcha nuk është i saktë. Provoni të rifreskoni faqen nëse problemi vazhdon.',
  verify_code_incorrect_error: 'Kodi i futur është i pasaktë.',
  verify_code_too_many_requests: 'Nuk mund të gjenerojmë një kod të ri për momentin. Ju lutemi provoni përsëri më vonë ose përdorni kodin që kemi dërguar.',
  send_again_button_title: 'Dërgo përsëri',
  verify_title: 'Verifiko',
  verify_code_title: 'Futni kodin e verifikimit',
  verify_code_description: 'Kemi dërguar një kod verifikimi me 6 shifra në email-in tuaj. Ju lutemi futeni atë më poshtë:',
  verification_invalid_error_notification: 'Verifikim i pavlefshëm. Provoni përsëri më vonë.',
  too_many_requests: 'Ups! Po vepron pak shumë shpejt. Ngadalëso dhe provo përsëri pas një çasti.',
  services_number_title: 'Numri i shërbimeve për të zgjedhur',
  booking_can_not_be_paid: 'Pagesa nuk është e disponueshme. Rifreskoni faqen ose përdorni përsëri lidhjen në email për të parë rezervimin.',
  drag_and_drop_files_here_or_click_to_upload: 'Zvarritni dhe lëshoni skedarët këtu ose klikoni për t’i ngarkuar',
  files_uploaded: 'Skedarët e ngarkuar',
  max_file_size_mb: 'Madhësia maksimale e skedarit: 10 MB',
  unknown_type: 'Lloj i panjohur',
  upload_file_error: 'Ndodhi një gabim gjatë ngarkimit të skedarit. Formatet e pranuara: PDF, XLSX, XLS, CSV, DOC, DOCX, JPG, JPEG, PNG, TXT. Madhësia e skedarit nuk duhet të kalojë 10 MB.',
  logged_in_info_notification: 'Hyrja në llogari u krye me sukses: @{email}',
  logged_out_info_notification: 'Dalja nga llogaria u krye me sukses: @{email}'
}
