import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import Booking from '@/calendesk/models/DTO/Response/Booking'
import { BookingStatus } from '@/calendesk/models/DTO/Response/BookingStatus'
import { errorNotification } from '@/calendesk/prototypes/notifications'
import SimpleStoreProductCreatePaymentRequestData
  from '@/calendesk/models/DTO/Request/SimpleStoreProductCreatePaymentRequestData'
import CreatePaymentForBookingsRequestData from '@/calendesk/models/DTO/Request/CreatePaymentForBookingsRequestData'

export default Vue.extend({
  data () {
    return {
      isMakingPayment: false
    }
  },
  computed: {
    ...mapGetters({
      stripe: 'stripe/getStripe',
      appConfiguration: 'setup/getAppConfiguration'
    }),
    minimumPayment (): number {
      switch (this.appConfiguration.currency.toLowerCase()) {
        case 'pln':
          return 200
        default:
          return 100
      }
    },
    paymentsActive (): boolean {
      return !!this.stripe
    }
  },
  mounted () {
    window.addEventListener('pageshow', this.handlePageShow)
  },
  beforeDestroy () {
    window.removeEventListener('pageshow', this.handlePageShow)
  },
  methods: {
    ...mapActions({
      createPaymentForBooking: 'stripe/createPaymentForBooking',
      createPaymentForBookings: 'stripe/createPaymentForBookings',
      createPaymentForSimpleStoreProduct: 'stripe/createPaymentForSimpleStoreProduct'
    }),
    handlePageShow () {
      // If the page was restored from bfcache, reset the loader
      this.isMakingPayment = false
    },
    canPayForBookings (bookings: Array<Booking>): boolean {
      if (this.stripe) {
        const result = this.getBookingsForPayment(bookings)

        return (result.length > 0)
      }

      return false
    },
    getBookingsForPayment (bookings: Booking[]): Booking[] {
      return bookings.filter((booking) => {
        if (booking.service && booking.service.allowOnlinePayment && ((booking.service && booking.service.price >= this.minimumPayment) || (booking.serviceType && booking.serviceType.price >= this.minimumPayment))) {
          if (!booking.paid && (booking.status === BookingStatus.PAYMENT || booking.status === BookingStatus.APPROVED)) {
            return true
          }
        } else if (booking.simpleStoreProducts && booking.simpleStoreProducts.length > 0) {
          return true
        }

        return false
      })
    },
    async payBookings (bookings: Array<Booking>, promoCode: string | null) {
      try {
        if (!this.stripe) {
          errorNotification('payment_fail_title', 'Stripe has not been loaded.')
          return
        }

        this.isMakingPayment = true
        const bookingIds = bookings.map(b => b.id)
        const sessionId = await this.createPaymentForBookings(new CreatePaymentForBookingsRequestData(bookingIds, promoCode))

        if (!sessionId) {
          this.isMakingPayment = false
          errorNotification(null, 'Creating a session ID for payBookings failed.')
          return
        }

        const { error } = await this.stripe.redirectToCheckout({ sessionId })

        if (error) {
          this.isMakingPayment = false
          errorNotification(null, error)
        }
      } catch (error) {
        this.isMakingPayment = false

        if (promoCode && error.response && error.response.status === 400) {
          errorNotification('promo_code_invalid_message', error, false)
        } else if (error.response?.data?.code === 'P24_INACTIVE') {
          errorNotification('p24_inactive', error, false)
        } else if (error.response && error.response.status === 404) {
          errorNotification('booking_can_not_be_paid', error, false)
        } else {
          errorNotification(null, error)
        }
      }
    },
    async paySimpleStoreProduct (request: SimpleStoreProductCreatePaymentRequestData): Promise<void> {
      if (!this.stripe) {
        errorNotification('payment_fail_title', 'Stripe has not been loaded.')
        return
      }

      this.isMakingPayment = true

      try {
        const sessionId = await this.createPaymentForSimpleStoreProduct(request)

        if (!sessionId) {
          this.isMakingPayment = false
          errorNotification(null, 'Creating a session ID for paySimpleStoreProduct failed.')
          return
        }

        const { error } = await this.stripe.redirectToCheckout({ sessionId })

        if (error) {
          this.isMakingPayment = false
          errorNotification(null, error)
        }
      } catch (error) {
        this.isMakingPayment = false

        if (error.response?.data?.errors?.email) {
          errorNotification('email_is_taken', error, false)
        } else if (error.response?.data?.code === 'DISCOUNT_CODE_INVALID') {
          errorNotification('promo_code_invalid_message', error, false)
        } else if (error.response?.data?.code) {
          switch (error.response.data.code) {
            case 'USER_BLOCKED':
              errorNotification('account_blocked_message', error, false)
              break
            case 'AUTHENTICATION_REQUIRED':
              errorNotification('employee_auth_required', error, false)
              break
            default:
              errorNotification(null, error)
          }
        } else {
          errorNotification(null, error)
        }
      }
    }
  }
})
