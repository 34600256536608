export default {
  error_occurred: 'Elnézést, hiba történt. Kérjük, próbálja újra egy pillanat múlva.',
  new_content_available: "Új oldalfrissítés érkezett, kattintson a 'Frissítés' gombra az oldal újratöltéséhez.",
  refresh_button_title: 'Frissítés',
  force_reload_message: 'Elnézést, hiba történt. Csapatunk már dolgozik ezen. Kérjük, próbálja meg egy pillanat múlva újratölteni a webhelyet.',
  sign_up: 'Hozzon létre egy fiókot',
  service: 'Szolgáltatás',
  employee: 'Munkavállaló',
  select_employee: 'Válasszon ki egy személyt',
  employees: 'Alkalmazottak',
  services: 'Szolgáltatások',
  duration: 'Időtartam',
  from: 'Tól től',
  select_date: 'Válasszon egy dátumot',
  no_account_question: 'Még nincs fiókja?',
  name: 'Keresztnév',
  surname: 'Vezetéknév',
  name_and_surname: 'Teljes név',
  phone: 'Telefon',
  password: 'Jelszó',
  field_is_required: 'Ez a mező kötelező.',
  value_is_too_long: 'Az érték túl hosszú.',
  value_is_too_short: 'Az érték túl rövid.',
  signup_was_successful: 'A regisztráció sikeres volt. Bejelentkezés a panelbe.',
  signup_has_failed: 'A regisztráció meghiúsult. Kérjük, próbálja újra egy pillanat múlva.',
  email_is_taken: 'A megadott e-mail címmel már létezik fiók. Ha elfelejtette jelszavát, használja az emlékeztető opciót.',
  email_or_password_incorrect: 'Az e-mail cím vagy jelszó helytelen. Ha elfelejtette jelszavát, használja az emlékeztető opciót.',
  account_not_found: 'Fiók nem található.',
  page_not_found: 'Hoppá! A keresett oldal nem található :(',
  go_back_to_main_page: 'Menjen vissza a kezdőlapra, és próbálja újra.',
  bookings: 'Foglalások',
  account: 'A fiókom',
  date: 'Dátum',
  cancel: 'Megszünteti',
  date_from: 'Dátum innen:',
  date_to: 'Dátum ig',
  nothing_found_here: 'Nem találtunk semmit.',
  old_password: 'Régi jelszó',
  remove: 'Töröl',
  phone_is_invalid: 'A telefonszám hibás.',
  book: 'Könyv',
  sign_up_2: 'Hozzon létre egy fiókot',
  log_in: 'Bejelentkezés',
  your_email_address: 'Az email címed',
  select: 'Válassza ki',
  filters: 'Szűrők',
  expand: 'Kiterjed',
  collapse: 'Összeomlás',
  bookings_drawer_title: 'Válassza ki a szűrőket, hogy csak a keresett foglalások jelenjenek meg.',
  clear_filters: 'Szűrők visszaállítása',
  show_more: 'Mutass többet',
  dont_you_remember_password: 'Elfelejtette a jelszavát?',
  password_information: 'A jelszónak legalább 8 karakterből kell állnia, tartalmaznia kell egy nagybetűt, egy számot és egy speciális karaktert.',
  forgotten_password_button: 'Küldj egy linket a jelszó megváltoztatásához',
  do_you_already_have_an_account: 'Van már felhasználói fiókod?',
  reset_password_title: 'Állítson be új jelszót',
  reset_password_subtitle: 'Írja be új jelszavát alább.',
  reset_password_button: 'Mentse el az új jelszót',
  profile: 'Profil',
  logout: 'Kijelentkezés',
  cancel_booking: 'Törölje a foglalást',
  status_approved: 'Jóváhagyott',
  status_payment: 'Fizetésre vár',
  status_done: 'Befejezve',
  status_cancelled: 'Törölve',
  cancel_booking_dialog_title: 'Szeretné törölni a foglalást?',
  cancel_booking_dialog_information: 'Ez a művelet nem visszavonható!',
  cancel_booking_dialog_button: 'Igen, törölje a foglalásomat',
  my_profile: 'A profilom',
  my_data: 'Az én adataim',
  upload_new_photo: 'Új fénykép hozzáadása',
  birthday: 'Születésnap',
  save_changes: 'Változtatások mentése',
  phone_number: 'Telefonszám',
  directional: 'Körzetszám',
  save_new_password: 'Mentse el az új jelszót',
  new_password: 'Új jelszó',
  password_change: 'Jelszó módosítása',
  view: 'Kilátás',
  services_2: 'Szolgáltatások',
  accept_terms_and_conditions_1: 'Az alábbi lehetőségre kattintva elfogadom a',
  accept_terms_and_conditions_2: 'Felhasználási feltételek',
  accept_terms_and_conditions_3: 'és megerősítem, hogy elolvastam a',
  accept_terms_and_conditions_4: 'Adatvédelmi nyilatkozat.',
  to: 'Nak nek',
  pln: '@{value} PLN',
  usd: '$@{value}',
  eur: '@{value} EUR',
  gbp: '£@{value}',
  jpy: '@{value} JPY',
  aud: '@{value} AUD',
  cad: '@{value} CAD',
  chf: '@{value} CHF',
  sek: '@{value} SEK',
  nzd: '@{value} NZD',
  brl: '@{value} BRL',
  bgn: '@{value} BGN',
  czk: '@{value} CZK',
  dkk: '@{value} DKK',
  hkd: '@{value} HKD',
  huf: '@{érték} Ft',
  ils: '@{value} ILS',
  myr: '@{value} MYR',
  mxn: '@{value} MXN',
  twd: '@{value} TWD',
  nok: '@{value} NOK',
  php: '@{value} PHP',
  ron: '@{érték} RON',
  rub: '@{value} RUB',
  sgd: '@{value} SGD',
  thb: '@{value} THB',
  uah: '@{value} UAH',
  inr: '@{value} INR',
  clp: '@{value} CLP',
  login: 'Bejelentkezés',
  email_is_incorrect: 'Az e-mail cím hibás.',
  logged_out: 'Kijelentkezett',
  booking_dialog_date_is_already_taken: 'A kiválasztott idő nem elérhető. Szívesen választana egy másik helyet?',
  data_search: 'Adatok keresése. Kérlek várj...',
  no_employees: 'Nincs alkalmazott',
  no_services: 'Nincsenek szolgáltatások',
  no_categories: 'Nincsenek kategóriák',
  no_bookings: 'Foglalások nem találhatók.',
  no_subscriptions: 'Nem található előfizetés.',
  female: 'Női',
  male: 'Férfi',
  other: 'Egyéb',
  no_numeric_in_password: 'Nincs numerikus karakter a jelszóban.',
  no_lowercase_in_password: 'Nincs kisbetű a jelszóban.',
  no_uppercase_in_password: 'Nincs nagybetű a jelszóban.',
  no_special_character: 'Nincs különleges karakter.',
  min_n_characters: 'A karakterek minimális száma @{min}.',
  max_n_characters: 'A karakterek maximális száma @{max}.',
  email: 'Email',
  reset_password_fail_text: 'A jelszó nem változott. A link valószínűleg lejárt.',
  reset_password_success_title: 'A jelszavad módosítva lett.',
  reset_password_success_text: 'Új jelszavaddal tudsz bejelentkezni.',
  account_activation: 'Fiók Aktiválás',
  account_activation_success: 'A fiók aktiválva lett.',
  account_activation_fail: 'Hiba történt a fiók aktiválásakor. Úgy tűnik, hogy a fiók már aktív, vagy a link már nem érvényes.',
  account_activation_processing: 'Fiókja aktiválása. Kérlek várj...',
  account_activation_resend_title: 'A fiók nincs aktiválva',
  account_activation_resend_text: 'Aktiválja fiókját az általunk küldött e-mailben található aktiváló linkre kattintva.',
  account_activation_resend_button: 'Küldd újra',
  confirm_send_activate_link_dialog_subtitle: 'Az aktiváló linket tartalmazó e-mailt küldtük a korábban megadott e-mail címre.',
  file_is_too_large: 'A kiválasztott fájl túl nagy.',
  appointment_title: 'Válasszon személyt, dátumot és időpontot a foglaláshoz',
  others: 'Egyéb',
  countryLabel: 'Ország',
  requiredMessages: 'Telefonszám megadása kötelező',
  date_of_visit: 'Dátum',
  hour_of_visit: 'Idő',
  thank_you_for_booking: 'Az Ön foglalása',
  no_free_hours: 'A kiválasztott napon nincs szabad időpont.',
  any_person: 'Véletlenszerű választás',
  password_changed: 'A jelszavad módosítva lett',
  incorrect_old_password: 'A régi jelszó helytelen',
  error404: '404-es hibakód',
  minutes: 'min',
  hours: 'h',
  days: 'd',
  ok: 'rendben',
  web_page_temporary_disabled: 'Sajnálom! Ez az oldal átmenetileg le van tiltva.',
  profile_update_success: 'Adatait elmentettük.',
  form_is_invalid: 'Az űrlap hibákat tartalmaz. Kérjük, ellenőrizze a kiemelt mezőket, és próbálja újra.',
  search: 'Keresés',
  pay_online_button_title: 'Online fizetés',
  account_not_activated_info: 'Az e-mail címet nem erősítették meg. Erősítse meg e-mail címét az elküldött üzenetben található hivatkozásra kattintva.',
  paid: 'Fizetett',
  payments: 'Kifizetések',
  payment_service: 'Szolgáltatás',
  payment_date: 'Dátum',
  payment_price: 'Ár',
  payment_success_title: 'A fizetés sikeres volt, köszönöm!',
  payment_fail_title: 'A fizetés sikertelen, próbálja újra.',
  pay_again: 'Fizessen újra',
  newsletter_subscribe_success: 'Köszönöm! E-mail címét megerősítettük.',
  newsletter_subscribe_fail: 'Hiba történt az e-mail-cím aktiválásakor. Úgy tűnik, hogy az e-mail cím már aktív, vagy a link már nem érvényes.',
  newsletter_send_again: 'Küldd újra',
  newsletter_send_again_success: 'Az aktiváló linket ismét elküldtük.',
  send_again_fail: 'Hiba történt az új aktiváló link generálásakor, lehet, hogy az e-mail címe már aktív, vagy éppen most hozta létre a linket.',
  newsletter_email_is_used: 'Az e-mail cím már szerepel a listán, ha a címet nem erősítették meg, újra el tudjuk küldeni az aktiváló linket.',
  newsletter_unsubscribe_success: 'Köszönöm! Az Ön e-mail címét eltávolítottuk.',
  newsletter_unsubscribe_fail: 'Hiba történt az e-mail cím törlésekor. Úgy tűnik, hogy az e-mail címet már törölték, vagy a link lejárt.',
  booking_payments: 'Foglalások',
  simple_store_product_transactions: 'Termékek',
  payment_type_select_box: 'Válassza ki a fizetés típusát',
  payment_description: 'Leírás',
  add_to_cart: 'Kosárba',
  remove_from_cart: 'Vegye ki a kosárból',
  save: 'Megment',
  bookings_cart_summary: 'Foglalási összefoglaló',
  today: 'Ma',
  fill_booking_details_title: 'Adja meg a részleteket',
  create_an_account_question: 'Hozzon létre egy fiókot, és küldje el a jelszót az e-mail címre',
  value_is_incorrect: 'Ez az érték nem érvényes.',
  first_available_date: 'Rendelkezésre álló idősávok bekapcsolva',
  service_location_1: 'Cím',
  service_location_2: 'Telefon',
  service_location_3: 'Google Meet',
  service_location_4: 'Egyéb',
  service_location_5: 'Zoomolás',
  service_location_6: 'Microsoft Teams',
  service_location_7: 'Skype',
  service_location_8: 'WhatsApp',
  service_location: 'Elhelyezkedés',
  booking_not_found: 'Foglalás nem található.',
  booking_canceled: 'A foglalást törölték.',
  booking_canceled_fail: 'Ez a foglalás nem törölhető.',
  day: 'Nap',
  week: 'Hét',
  month: 'Hónap',
  year: 'Év',
  booking_plural_1: 'Foglalás',
  booking_plural_2: 'Foglalások',
  booking_plural_3: 'Foglalások',
  booking_plural_4: 'Foglalások',
  booking_plural_5: 'Foglalások',
  day_plural_1: 'Nap',
  day_plural_2: 'Napok',
  day_plural_3: 'Napok',
  day_plural_4: 'Napok',
  day_plural_5: 'Napok',
  week_plural_1: 'Hét',
  week_plural_2: 'Hetek',
  week_plural_3: 'Hetek',
  week_plural_4: 'Hetek',
  week_plural_5: 'Hetek',
  month_plural_1: 'Hónap',
  month_plural_2: 'Hónapok',
  month_plural_3: 'Hónapok',
  month_plural_4: 'Hónapok',
  month_plural_5: 'Hónapok',
  year_plural_1: 'Év',
  year_plural_2: 'Évek',
  year_plural_3: 'Évek',
  year_plural_4: 'Évek',
  year_plural_5: 'Évek',
  tax: 'ÁFA azonosító',
  tax_prefix: 'Előtag',
  get_company_data: 'Keresés',
  get_company_data_failed: 'Az adatokat nem lehetett letölteni.',
  company_name: 'Cégnév',
  street_and_number: 'utca és házszám',
  postcode: 'irányítószám',
  city: 'Város',
  country: 'Ország',
  subscribe: 'Iratkozz fel',
  recurrent_fee_info: 'Kiszámlázva minden',
  one_time_fee_info: 'Egyszeri díj.',
  new_account_title: 'Új felhasználó létrehozása',
  payment_data_title: 'fizetési részletek',
  billing_data_checkbox_question: 'Számlázási adatok',
  confirmation_modal_header: 'A művelet megerősítést igényel',
  confirmation_modal_warning: 'Biztos vagy benne, hogy folytatni akarod?',
  no_way_to_undo_action: 'Ez a művelet nem visszavonható!',
  delete_payment_method: 'Távolítsa el a kártyát',
  add_payment_method: 'Kártya hozzáadása',
  fill_card_details: 'Töltse ki a fizetési részleteket.',
  subscriptions: 'Előfizetések',
  subscription: 'Feliratkozás',
  invoice_number: 'Számla',
  number: 'Szám',
  status: 'Állapot',
  details: 'Részletek',
  created_at: 'Létrehozva',
  subscription_status_initiated: 'Kifizetetlen',
  subscription_status_active: 'Aktív',
  subscription_status_inactive: 'Inaktív',
  subscription_status_canceled: 'Törölve',
  ends_at: 'órakor ér véget',
  ends_at_default: 'Érvényes a számlázási időszak végéig',
  select_subscription_to_pay: 'Válasszon előfizetést a fizetéshez.',
  pay_with_subscription: 'Fizessen előfizetéssel',
  bookings_paid_by_subscription_success_message: 'Fizetett',
  bookings_not_paid_by_subscription_error_message: 'A foglalást nem lehetett kifizetni a kiválasztott előfizetéssel.',
  stripe: 'Csík',
  provider_tag: 'Módszer',
  card: 'Kártya',
  cash: 'Készpénz',
  transfer: 'Átruházás',
  promo_code_label: 'Akciós kód',
  promo_code_invalid_message: 'A megadott kedvezménykód érvénytelen.',
  payment_method_setup_error: 'Probléma lépett fel a kártyájával. Bankja megtagadta a kártya engedélyezését későbbi tranzakciókhoz. Kérjük, forduljon bankjához.',
  active_subscription_missing: 'A szolgáltatás igénybevételéhez aktív előfizetés szükséges. Sajnos nincs előfizetésed.',
  area_code: 'Körzetszám',
  usage: 'Használat',
  create_account_in_stripe_failed: 'A fizetési szolgáltató elutasította az előfizetési adatokat. Kérjük, ellenőrizze a nyomtatvány helyességét (adóazonosító jel, telefonszám, e-mail cím).',
  additional_auth_required: 'Bankjának további engedélyre van szüksége',
  general_payment_error: 'Hiba történt a fizetéssel. Előfordulhat, hogy kártyája nem támogatja az előfizetéseket, vásárlási korlátai vannak, vagy nincs elég pénzeszköze az előfizetés kifizetéséhez. Kérjük, lépjen kapcsolatba bankjával, vagy válasszon másik fizetési módot.',
  subscription_set_successfully: 'Az előfizetés megfelelően beállítva.',
  booking_created: 'Új foglalás',
  booking_updated: 'Foglalás változása',
  booking_deleted: 'Foglalás törlése',
  booking_reminder: 'Foglalási emlékeztető',
  email_notifications: 'Email Értesítések',
  sms_notifications: 'SMS értesítések',
  save_user_preferences: 'Mentse el a beállításokat',
  free_spots: 'Maradtak a helyek',
  no: 'Nem',
  yes: 'Igen',
  p24_inactive: 'A Przelewy24 fizetési szolgáltató nem használható. Kérjük, lépjen kapcsolatba fizetési szolgáltatójával.',
  stripe_customer_invalid: 'Érvénytelen Stripe ügyfél-azonosító. A fizetési mód nem köthető a vásárlóhoz.',
  stripe_resource_invalid: 'A kiválasztott termék nem elérhető. Kérjük, ellenőrizze a termék elérhetőségét a fizetési szolgáltatónál.',
  account_blocked_message: 'Fiókja inaktív. Kérjük, forduljon a rendszergazdához.',
  time_zone: 'Időzóna',
  no_spots_left: 'Elérted az elérhető helyek maximális számát.',
  employee_auth_required: 'Kérjük, jelentkezzen be fiókjába, vagy használjon másik e-mail címet. Az alkalmazotti fiókokhoz be kell jelentkezni.',
  subpage_auth_required: 'A lap megtekintéséhez kérjük, jelentkezzen be fiókjába.',
  gross: 'Bruttó összeg.',
  subscription_login_info: 'Már van fiókja? Az adatok kitöltéséhez kérjük, jelentkezzen be alább.',
  change_date_time: 'Átütemezés',
  promo_code_checkbox: 'Van egy kedvezményes kódom',
  booking_promo_code_label: 'Adja meg a kedvezmény kódját',
  select_hour: 'Válassza ki az időt',
  id: 'Id',
  booking_rescheduled: 'Foglalása megváltozott.',
  booking_rescheduled_fail: 'Ez a foglalás nem módosítható.',
  max_user_bookings_error: 'Túllépte a foglalások maximális számát. Kérjük, válasszon másik szolgáltatást, vagy lépjen kapcsolatba velünk.',
  in_total: 'Teljes',
  company: 'Vállalat',
  individual: 'Magánszemély',
  bulk_payment: 'Tömeges fizetés',
  make_bulk_payment: 'Tömeges fizetés',
  simple_store_product_confirmation_title: 'Töltse ki a vásárlási űrlapot',
  select_service_type: 'Válasszon egy szolgáltatási lehetőséget',
  add_to_calendar_button_label: 'Hozzáadás a naptárhoz',
  login_to_use_service_with_subscription: 'Kérjük, jelentkezzen be fiókjába, hogy időpontot foglaljon ehhez a szolgáltatáshoz.',
  booking_dialog_date_is_already_taken_multiple_bookings: 'A kiválasztott időpont nem érhető el. Kérem, válasszon másik szabad időpontot. Több időpont foglalása esetén győződjön meg arról, hogy az időpontok nem zárják ki egymást.',
  select_location_calendar_warning: 'Válasszon helyszínt az elérhető időpontok betöltéséhez.',
  selectable_location_online_title: 'Online',
  change: 'Változtatás',
  show_less_hours_button: 'Kevesebb óra megjelenítése',
  show_more_hours_button: 'Több óra megjelenítése',
  add_another_button_title: 'Adj hozzá még egyet',
  close: 'Bezárás',
  selected_time_slots: 'Kiválasztott időpontok',
  select_location: 'Válasszon helyszínt',
  captcha_invalid_error_notification: 'A captcha ellenőrzése sikertelen. Próbálja újratölteni az oldalt, ha a probléma továbbra is fennáll.',
  verify_code_incorrect_error: 'A megadott kód helytelen.',
  verify_code_too_many_requests: 'Jelenleg nem tudunk új kódot generálni. Próbálja újra később, vagy használja a már elküldött kódot.',
  send_again_button_title: 'Küldje újra',
  verify_title: 'Hitelesít',
  verify_code_title: 'Adja meg a hitelesítési kódot',
  verify_code_description: 'Elküldtünk egy 6 számjegyű hitelesítési kódot az e-mail címére. Kérjük, adja meg alább:',
  verification_invalid_error_notification: 'Érvénytelen ellenőrzés. Próbálja újra később.',
  too_many_requests: 'Hoppá! Egy kicsit túl gyorsan cselekszel. Lassíts és próbáld meg újra egy kis idő múlva.',
  services_number_title: 'Választható szolgáltatások száma',
  booking_can_not_be_paid: 'A fizetés nem elérhető. Frissítse az oldalt, vagy használja újra az e-mailben található linket a foglalás megtekintéséhez.',
  drag_and_drop_files_here_or_click_to_upload: 'Húzza ide a fájlokat, vagy kattintson a feltöltéshez',
  files_uploaded: 'Feltöltött fájlok',
  max_file_size_mb: 'Maximális fájlméret: 10 MB',
  unknown_type: 'Ismeretlen típus',
  upload_file_error: 'Hiba történt a fájl feltöltése során. Elfogadott formátumok: PDF, XLSX, XLS, CSV, DOC, DOCX, JPG, JPEG, PNG, TXT. A fájl mérete nem haladhatja meg a 10 MB-ot.',
  logged_in_info_notification: 'Sikeresen bejelentkezett a fiókba: @{email}',
  logged_out_info_notification: 'Sikeresen kijelentkezett a fiókból: @{email}'
}
