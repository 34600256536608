import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import { RootState } from './types'
import { user } from './modules/user/index'
import { setup } from './modules/setup/index'
import { builder } from './modules/builder/index'
import { dialog } from './modules/dialog/index'
import { drawer } from './modules/drawer/index'
import { preview } from './modules/preview/index'
import { employee } from './modules/employee/index'
import { service } from './modules/service/index'
import { subscription } from './modules/subscription/index'
import { booking } from './modules/booking/index'
import { stripe } from './modules/stripe/index'
import { snackbar } from './modules/snackbar/index'
import { payments } from './modules/payments/index'
import { files } from './modules/files/index'

Vue.use(Vuex)

const store: StoreOptions<RootState> = {
  modules: {
    user,
    setup,
    builder,
    dialog,
    drawer,
    preview,
    employee,
    service,
    booking,
    stripe,
    snackbar,
    payments,
    subscription,
    files
  }
}

export default new Vuex.Store<RootState>(store)
