export default {
  error_occurred: 'Λυπούμαστε, παρουσιάστηκε σφάλμα. Δοκιμάστε ξανά σε λίγο.',
  new_content_available: "Υπάρχει μια νέα ενημέρωση σελίδας, κάντε κλικ στο 'Ανανέωση' για να φορτώσετε ξανά τη σελίδα.",
  refresh_button_title: 'Φρεσκάρω',
  force_reload_message: 'Λυπούμαστε, παρουσιάστηκε σφάλμα. Η ομάδα μας εργάζεται ήδη πάνω σε αυτό. Δοκιμάστε να φορτώσετε ξανά τον ιστότοπο σε λίγο.',
  sign_up: 'Δημιουργία λογαριασμού',
  service: 'Υπηρεσία',
  employee: 'Υπάλληλος',
  select_employee: 'Επιλέξτε ένα άτομο',
  employees: 'Υπαλλήλους',
  services: 'Υπηρεσίες',
  duration: 'Διάρκεια',
  from: 'Από',
  select_date: 'Επιλέξτε μια ημερομηνία',
  no_account_question: 'Δεν έχετε λογαριασμό ακόμα;',
  name: 'Ονομα',
  surname: 'Επίθετο',
  name_and_surname: 'Πλήρες όνομα',
  phone: 'Τηλέφωνο',
  password: 'Κωδικός πρόσβασης',
  field_is_required: 'Αυτό το πεδίο είναι υποχρεωτικό.',
  value_is_too_long: 'Η τιμή είναι πολύ μεγάλη.',
  value_is_too_short: 'Η τιμή είναι πολύ μικρή.',
  signup_was_successful: 'Η εγγραφή ήταν επιτυχής. Είσοδος στον πίνακα.',
  signup_has_failed: 'Η εγγραφή απέτυχε. Δοκιμάστε ξανά σε λίγο.',
  email_is_taken: 'Ο λογαριασμός με τη δεδομένη διεύθυνση email υπάρχει ήδη. Εάν ξεχάσατε τον κωδικό πρόσβασής σας, χρησιμοποιήστε την επιλογή υπενθύμισης.',
  email_or_password_incorrect: 'Η διεύθυνση e-mail ή ο κωδικός πρόσβασης δεν είναι σωστά. Εάν ξεχάσατε τον κωδικό πρόσβασής σας, χρησιμοποιήστε την επιλογή υπενθύμισης.',
  account_not_found: 'Ο λογαριασμός δεν βρέθηκε.',
  page_not_found: 'Ωχ! Η σελίδα που ψάχνατε δεν βρέθηκε :(',
  go_back_to_main_page: 'Επιστρέψτε στην αρχική σελίδα και δοκιμάστε ξανά.',
  bookings: 'Κρατήσεις',
  account: 'Ο λογαριασμός μου',
  date: 'Ημερομηνία',
  cancel: 'Ματαίωση',
  date_from: 'Ημερομηνία από',
  date_to: 'Ημερομηνία έως',
  nothing_found_here: 'Δεν βρέθηκε τίποτα.',
  old_password: 'ΠΑΛΙΟΣ ΚΩΔΙΚΟΣ',
  remove: 'Διαγράφω',
  phone_is_invalid: 'Ο αριθμός τηλεφώνου είναι λάθος.',
  book: 'Βιβλίο',
  sign_up_2: 'Δημιουργία λογαριασμού',
  log_in: 'Συνδεθείτε',
  your_email_address: 'Η ηλεκτρονική σου διεύθυνση',
  select: 'Επιλέγω',
  filters: 'Φίλτρα',
  expand: 'Επεκτείνουν',
  collapse: 'Κατάρρευση',
  bookings_drawer_title: 'Επιλέξτε φίλτρα για να εμφανίζονται μόνο οι κρατήσεις που αναζητάτε.',
  clear_filters: 'Επαναφορά φίλτρων',
  show_more: 'Δείτε περισσότερα',
  dont_you_remember_password: 'Ξεχάσατε τον κωδικό σας;',
  password_information: 'Ο κωδικός πρόσβασης πρέπει να αποτελείται από τουλάχιστον 8 χαρακτήρες, να περιέχει ένα κεφαλαίο γράμμα, έναν αριθμό και έναν ειδικό χαρακτήρα.',
  forgotten_password_button: 'Στείλτε έναν σύνδεσμο για να αλλάξετε τον κωδικό πρόσβασής σας',
  do_you_already_have_an_account: 'Έχετε ήδη λογαριασμό?',
  reset_password_title: 'Ορίστε νέο κωδικό πρόσβασης',
  reset_password_subtitle: 'Εισαγάγετε τον νέο σας κωδικό πρόσβασης παρακάτω.',
  reset_password_button: 'Αποθηκεύστε τον νέο κωδικό πρόσβασης',
  profile: 'Προφίλ',
  logout: 'Αποσύνδεση',
  cancel_booking: 'Ακυρώστε την κράτησή σας',
  status_approved: 'Εγκρίθηκε',
  status_payment: 'Εν αναμονή πληρωμής',
  status_done: 'Ολοκληρώθηκε το',
  status_cancelled: 'Ακυρώθηκε',
  cancel_booking_dialog_title: 'Θέλετε να ακυρώσετε την κράτησή σας;',
  cancel_booking_dialog_information: 'Αυτή η πράξη δε μπορεί να αναιρεθεί!',
  cancel_booking_dialog_button: 'Ναι, ακυρώστε την κράτησή μου',
  my_profile: 'Το ΠΡΟΦΙΛ μου',
  my_data: 'Οι λεπτομέρειες μου',
  upload_new_photo: 'Προσθέστε μια νέα φωτογραφία',
  birthday: 'Γενέθλια',
  save_changes: 'Αποθήκευσε τις αλλαγές',
  phone_number: 'Τηλεφωνικό νούμερο',
  directional: 'Ταχυδρομικός κώδικας',
  save_new_password: 'Αποθηκεύστε τον νέο κωδικό πρόσβασης',
  new_password: 'Νέος Κωδικός',
  password_change: 'Αλλαξε κωδικό',
  view: 'Θέα',
  services_2: 'Υπηρεσίες',
  accept_terms_and_conditions_1: 'Κάνοντας κλικ στην παρακάτω επιλογή, συμφωνώ με το',
  accept_terms_and_conditions_2: 'Οροι και Προϋποθέσεις',
  accept_terms_and_conditions_3: 'και επιβεβαιώνω ότι έχω διαβάσει το',
  accept_terms_and_conditions_4: 'Δήλωση προστασίας προσωπικών δεδομένων.',
  to: 'Προς την',
  pln: '@{value} PLN',
  usd: '$@{value}',
  eur: '@{value} EUR',
  gbp: '£@{value}',
  jpy: '@{value} JPY',
  aud: '@{value} AUD',
  cad: '@{value} CAD',
  chf: '@{value} CHF',
  sek: '@{value} SEK',
  nzd: '@{value} NZD',
  brl: '@{value} BRL',
  bgn: '@{value} BGN',
  czk: '@{value} CZK',
  dkk: '@{value} DKK',
  hkd: '@{value} HKD',
  huf: '@{value} HUF',
  ils: '@{value} ILS',
  myr: '@{value} MYR',
  mxn: '@{value} MXN',
  twd: '@{value} TWD',
  nok: '@{value} NOK',
  php: '@{value} PHP',
  ron: '@{value} RON',
  rub: '@{value} RUB',
  sgd: '@{value} SGD',
  thb: '@{value} THB',
  uah: '@{value} UAH',
  inr: '@{value} INR',
  clp: '@{value} CLP',
  login: 'Συνδεθείτε',
  email_is_incorrect: 'Η διεύθυνση email είναι λανθασμένη.',
  logged_out: 'Έχετε αποσυνδεθεί',
  booking_dialog_date_is_already_taken: 'Η επιλεγμένη ώρα δεν είναι διαθέσιμη. Θα σας πείραζε να επιλέξετε ένα διαφορετικό μέρος;',
  data_search: 'Αναζήτηση δεδομένων. Παρακαλώ περιμένετε...',
  no_employees: 'Χωρίς εργαζόμενους',
  no_services: 'Δεν υπάρχουν υπηρεσίες',
  no_categories: 'Δεν υπάρχουν κατηγορίες',
  no_bookings: 'Οι κρατήσεις δεν βρέθηκαν.',
  no_subscriptions: 'Δεν βρέθηκαν συνδρομές.',
  female: 'Θηλυκός',
  male: 'Αρσενικός',
  other: 'Αλλα',
  no_numeric_in_password: 'Δεν υπάρχει αριθμητικός χαρακτήρας στον κωδικό πρόσβασης.',
  no_lowercase_in_password: 'Χωρίς πεζά γράμματα στον κωδικό πρόσβασης.',
  no_uppercase_in_password: 'Χωρίς κεφαλαία γράμματα στον κωδικό πρόσβασης.',
  no_special_character: 'Χωρίς ιδιαίτερο χαρακτήρα.',
  min_n_characters: 'Ο ελάχιστος αριθμός χαρακτήρων είναι @{min}.',
  max_n_characters: 'Ο μέγιστος αριθμός χαρακτήρων είναι @{max}.',
  email: 'E-mail',
  reset_password_fail_text: 'Ο κωδικός πρόσβασης δεν έχει αλλάξει. Ο σύνδεσμος μάλλον έχει λήξει.',
  reset_password_success_title: 'Ο κωδικός σας έχει αλλάξει.',
  reset_password_success_text: 'Μπορείτε να συνδεθείτε με τον νέο σας κωδικό πρόσβασης.',
  account_activation: 'Ενεργοποίηση Λογαριασμού',
  account_activation_success: 'Ο λογαριασμός έχει ενεργοποιηθεί.',
  account_activation_fail: 'Παρουσιάστηκε πρόβλημα κατά την ενεργοποίηση του λογαριασμού σας. Φαίνεται ότι ο λογαριασμός είναι ήδη ενεργός ή ο σύνδεσμος δεν είναι πλέον έγκυρος.',
  account_activation_processing: 'Ενεργοποίηση του λογαριασμού σας. Παρακαλώ περιμένετε...',
  account_activation_resend_title: 'Ο λογαριασμός δεν έχει ενεργοποιηθεί',
  account_activation_resend_text: 'Ενεργοποιήστε τον λογαριασμό σας κάνοντας κλικ στον σύνδεσμο ενεργοποίησης στο email που σας στείλαμε.',
  account_activation_resend_button: 'Στείλε ξανά',
  confirm_send_activate_link_dialog_subtitle: 'Έχουμε στείλει ένα email με τον σύνδεσμο ενεργοποίησης στη διεύθυνση email που δόθηκε προηγουμένως.',
  file_is_too_large: 'Το επιλεγμένο αρχείο είναι πολύ μεγάλο.',
  appointment_title: 'Επιλέξτε άτομο, ημερομηνία και ώρα κράτησης',
  others: 'Αλλα',
  countryLabel: 'Χώρα',
  requiredMessages: 'Απαιτείται αριθμός τηλεφώνου',
  date_of_visit: 'Ημερομηνία',
  hour_of_visit: 'χρόνος',
  thank_you_for_booking: 'Η κράτησή σας',
  no_free_hours: 'Δεν υπάρχουν διαθέσιμα χρονικά διαστήματα την επιλεγμένη ημέρα.',
  any_person: 'Τυχαία επιλογή',
  password_changed: 'ο κωδικός σας έχει αλλάξει',
  incorrect_old_password: 'Ο παλιός κωδικός πρόσβασης είναι λανθασμένος',
  error404: 'Σφάλμα 404',
  minutes: 'ελάχ',
  hours: 'η',
  days: 'ρε',
  ok: 'Εντάξει',
  web_page_temporary_disabled: 'Συγνώμη! Αυτή η σελίδα απενεργοποιήθηκε προσωρινά.',
  profile_update_success: 'Τα στοιχεία σας έχουν αποθηκευτεί.',
  form_is_invalid: 'Η φόρμα περιέχει σφάλματα. Ελέγξτε τα επισημασμένα πεδία και δοκιμάστε ξανά.',
  search: 'Αναζήτηση',
  pay_online_button_title: 'Πληρωμή Online',
  account_not_activated_info: 'Η διεύθυνση e-mail δεν έχει επιβεβαιωθεί. Επιβεβαιώστε τη διεύθυνση e-mail σας κάνοντας κλικ στον σύνδεσμο στο απεσταλμένο μήνυμα.',
  paid: 'Εμμισθος',
  payments: 'Πληρωμές',
  payment_service: 'Υπηρεσία',
  payment_date: 'Ημερομηνία',
  payment_price: 'Τιμή',
  payment_success_title: 'Η πληρωμή ήταν επιτυχής, ευχαριστώ!',
  payment_fail_title: 'Η πληρωμή απέτυχε, δοκιμάστε ξανά.',
  pay_again: 'Πληρώστε ξανά',
  newsletter_subscribe_success: 'Ευχαριστώ! Η διεύθυνση email σας έχει επιβεβαιωθεί.',
  newsletter_subscribe_fail: 'Παρουσιάστηκε πρόβλημα κατά την ενεργοποίηση της διεύθυνσης email σας. Φαίνεται ότι η διεύθυνση email είναι ήδη ενεργή ή ο σύνδεσμος δεν είναι πλέον έγκυρος.',
  newsletter_send_again: 'Στείλε ξανά',
  newsletter_send_again_success: 'Ο σύνδεσμος ενεργοποίησης εστάλη ξανά.',
  send_again_fail: 'Παρουσιάστηκε πρόβλημα με τη δημιουργία ενός νέου συνδέσμου ενεργοποίησης, ίσως η διεύθυνση email σας να είναι ήδη ενεργή ή να δημιουργήσατε μόλις τον σύνδεσμο.',
  newsletter_email_is_used: 'Η διεύθυνση email είναι ήδη στη λίστα, εάν η διεύθυνση δεν έχει επιβεβαιωθεί, μπορούμε να στείλουμε ξανά τον σύνδεσμο ενεργοποίησης.',
  newsletter_unsubscribe_success: 'Ευχαριστώ! Η διεύθυνση email σας έχει αφαιρεθεί.',
  newsletter_unsubscribe_fail: 'Παρουσιάστηκε πρόβλημα κατά τη διαγραφή της διεύθυνσης email. Φαίνεται ότι η διεύθυνση email έχει ήδη διαγραφεί ή ο σύνδεσμος έχει λήξει.',
  booking_payments: 'Κρατήσεις',
  simple_store_product_transactions: 'Προϊόντα',
  payment_type_select_box: 'Επιλέξτε τον τύπο πληρωμής',
  payment_description: 'Περιγραφή',
  add_to_cart: 'Προσθήκη στο καλάθι',
  remove_from_cart: 'Αφαιρέστε από το καλάθι',
  save: 'Αποθηκεύσετε',
  bookings_cart_summary: 'Περίληψη κράτησης',
  today: 'Σήμερα',
  fill_booking_details_title: 'Εισαγάγετε λεπτομέρειες',
  create_an_account_question: 'Δημιουργήστε έναν λογαριασμό και στείλτε τον κωδικό πρόσβασης στη διεύθυνση e-mail',
  value_is_incorrect: 'Αυτή η τιμή δεν είναι έγκυρη.',
  first_available_date: 'Οι διαθέσιμες χρονοθυρίδες είναι ενεργοποιημένες',
  service_location_1: 'Διεύθυνση',
  service_location_2: 'Τηλέφωνο',
  service_location_3: 'Google Meet',
  service_location_4: 'Αλλα',
  service_location_5: 'Zoom',
  service_location_6: 'Microsoft Teams',
  service_location_7: 'Skype',
  service_location_8: 'WhatsApp',
  service_location: 'Τοποθεσία',
  booking_not_found: 'Η κράτηση δεν βρέθηκε.',
  booking_canceled: 'Η κράτηση ακυρώθηκε.',
  booking_canceled_fail: 'Αυτή η κράτηση δεν μπορεί να ακυρωθεί.',
  day: 'Ημέρα',
  week: 'Εβδομάδα',
  month: 'Μήνας',
  year: 'Ετος',
  booking_plural_1: 'Κράτηση',
  booking_plural_2: 'Κρατήσεις',
  booking_plural_3: 'Κρατήσεις',
  booking_plural_4: 'Κρατήσεις',
  booking_plural_5: 'Κρατήσεις',
  day_plural_1: 'Ημέρα',
  day_plural_2: 'Ημέρες',
  day_plural_3: 'Ημέρες',
  day_plural_4: 'Ημέρες',
  day_plural_5: 'Ημέρες',
  week_plural_1: 'Εβδομάδα',
  week_plural_2: 'Εβδομάδες',
  week_plural_3: 'Εβδομάδες',
  week_plural_4: 'Εβδομάδες',
  week_plural_5: 'Εβδομάδες',
  month_plural_1: 'Μήνας',
  month_plural_2: 'Μήνες',
  month_plural_3: 'Μήνες',
  month_plural_4: 'Μήνες',
  month_plural_5: 'Μήνες',
  year_plural_1: 'Ετος',
  year_plural_2: 'Χρόνια',
  year_plural_3: 'Χρόνια',
  year_plural_4: 'Χρόνια',
  year_plural_5: 'Χρόνια',
  tax: 'ΑΦΜ',
  tax_prefix: 'Πρόθεμα',
  get_company_data: 'Αναζήτηση',
  get_company_data_failed: 'Δεν ήταν δυνατή η λήψη των δεδομένων.',
  company_name: 'Ονομα εταιρείας',
  street_and_number: 'οδός και αριθμός',
  postcode: 'Ταχυδρομικός Κώδικας',
  city: 'Πόλη',
  country: 'Χώρα',
  subscribe: 'Εγγραφείτε',
  recurrent_fee_info: 'Τιμολογείται κάθε',
  one_time_fee_info: 'Εφάπαξ τέλος.',
  new_account_title: 'Δημιούργησε νέο λογαριασμό',
  payment_data_title: 'Οι λεπτομέρειες πληρωμής',
  billing_data_checkbox_question: 'Λεπτομέρειες χρέωσης',
  confirmation_modal_header: 'Η ενέργεια απαιτεί επιβεβαίωση',
  confirmation_modal_warning: 'Εισαι σιγουρος οτι θελεις να συνεχισεις?',
  no_way_to_undo_action: 'Αυτή η πράξη δε μπορεί να αναιρεθεί!',
  delete_payment_method: 'Αφαιρέστε την κάρτα',
  add_payment_method: 'Προσθήκη κάρτας',
  fill_card_details: 'Συμπληρώστε τα στοιχεία πληρωμής.',
  subscriptions: 'Συνδρομές',
  subscription: 'Συνδρομή',
  invoice_number: 'Τιμολόγιο',
  number: 'Αριθμός',
  status: 'Κατάσταση',
  details: 'Λεπτομέριες',
  created_at: 'Δημιουργήθηκε',
  subscription_status_initiated: 'Απλήρωτος',
  subscription_status_active: 'Ενεργός',
  subscription_status_inactive: 'Αδρανής',
  subscription_status_canceled: 'Ακυρώθηκε',
  ends_at: 'Τελειώνει στις',
  ends_at_default: 'Ισχύει μέχρι το τέλος της περιόδου χρέωσης',
  select_subscription_to_pay: 'Επιλέξτε μια συνδρομή για πληρωμή.',
  pay_with_subscription: 'Πληρώστε με συνδρομή',
  bookings_paid_by_subscription_success_message: 'επί πληρωμή',
  bookings_not_paid_by_subscription_error_message: 'Δεν ήταν δυνατή η πληρωμή της κράτησης με την επιλεγμένη συνδρομή.',
  stripe: 'Ταινία',
  provider_tag: 'Μέθοδος',
  card: 'Κάρτα',
  cash: 'Μετρητά',
  transfer: 'ΜΕΤΑΦΟΡΑ',
  promo_code_label: 'Κωδικός έκπτωσης',
  promo_code_invalid_message: 'Ο παρεχόμενος εκπτωτικός κωδικός δεν είναι έγκυρος.',
  payment_method_setup_error: 'Παρουσιάστηκε πρόβλημα με την κάρτα σας. Η τράπεζά σας αρνήθηκε να εξουσιοδοτήσει την κάρτα για μεταγενέστερες συναλλαγές. Επικοινωνήστε με την τράπεζά σας.',
  active_subscription_missing: 'Η κράτηση για αυτήν την υπηρεσία απαιτεί ενεργή συνδρομή. Δυστυχώς, δεν έχετε συνδρομή για χρήση.',
  area_code: 'Ταχυδρομικός κώδικας',
  usage: 'Χρήση',
  create_account_in_stripe_failed: 'Ο πάροχος πληρωμών απέρριψε τα δεδομένα της συνδρομής σας. Ελέγξτε την ορθότητα του εντύπου (ΑΦΜ, τηλέφωνο, διεύθυνση e-mail).',
  additional_auth_required: 'Η τράπεζά σας απαιτεί πρόσθετη εξουσιοδότηση',
  general_payment_error: 'Παρουσιάστηκε πρόβλημα με την πληρωμή σας. Ίσως η κάρτα σας να μην υποστηρίζει συνδρομές, να έχει όρια αγορών ή να μην έχει αρκετά χρήματα για να πληρώσει τη συνδρομή. Επικοινωνήστε με την τράπεζά σας ή επιλέξτε άλλη μέθοδο πληρωμής.',
  subscription_set_successfully: 'Η συνδρομή έχει ρυθμιστεί σωστά.',
  booking_created: 'Νέα κράτηση',
  booking_updated: 'Αλλαγή κράτησης',
  booking_deleted: 'Διαγραφή κράτησης',
  booking_reminder: 'Υπενθύμιση κράτησης',
  email_notifications: 'Ειδοποιήσεις ηλεκτρονικού ταχυδρομείου',
  sms_notifications: 'Ειδοποιήσεις SMS',
  save_user_preferences: 'Αποθήκευση προτιμήσεων',
  free_spots: 'Σημεία αριστερά',
  no: 'Οχι',
  yes: 'Ναί',
  p24_inactive: 'Ο χειριστής πληρωμών Przelewy24 δεν μπορεί να χρησιμοποιηθεί. Επικοινωνήστε με τον πάροχο πληρωμών σας.',
  stripe_customer_invalid: 'Μη έγκυρο αναγνωριστικό πελάτη Stripe. Ο τρόπος πληρωμής δεν μπορεί να επισυναφθεί στον πελάτη.',
  stripe_resource_invalid: 'Το επιλεγμένο προϊόν δεν είναι διαθέσιμο. Ελέγξτε τη διαθεσιμότητα του προϊόντος με τον πάροχο πληρωμών.',
  account_blocked_message: 'Ο λογαριασμός σας είναι ανενεργός. Επικοινωνήστε με τον διαχειριστή.',
  time_zone: 'Ζώνη ώρας',
  no_spots_left: 'Έχετε φτάσει τον μέγιστο αριθμό διαθέσιμων θέσεων.',
  employee_auth_required: 'Συνδεθείτε στον λογαριασμό σας ή χρησιμοποιήστε διαφορετική διεύθυνση email. Οι λογαριασμοί εργαζομένων απαιτούν σύνδεση.',
  subpage_auth_required: 'Συνδεθείτε στον λογαριασμό σας για να δείτε την καρτέλα.',
  gross: 'Μικτό ποσό.',
  subscription_login_info: 'Έχετε ήδη λογαριασμό? Παρακαλούμε συνδεθείτε παρακάτω για να συμπληρώσετε τα δεδομένα σας.',
  change_date_time: 'Επαναπρογραμματίζω',
  promo_code_checkbox: 'Έχω εκπτωτικό κωδικό',
  booking_promo_code_label: 'Εισαγάγετε τον εκπτωτικό κωδικό',
  select_hour: 'Επιλέξτε χρόνο',
  id: 'Ταυτότητα',
  booking_rescheduled: 'Η κράτησή σας άλλαξε.',
  booking_rescheduled_fail: 'Αυτή η κράτηση δεν μπορεί να αλλάξει.',
  max_user_bookings_error: 'Έχετε υπερβεί τον μέγιστο αριθμό κρατήσεων. Επιλέξτε άλλη υπηρεσία ή επικοινωνήστε μαζί μας.',
  in_total: 'Σύνολο',
  company: 'Εταιρία',
  individual: 'Ιδιώτης',
  bulk_payment: 'Μαζική πληρωμή',
  make_bulk_payment: 'Μαζική πληρωμή',
  simple_store_product_confirmation_title: 'Συμπληρώστε τη φόρμα αγοράς',
  select_service_type: 'Επιλέξτε μια επιλογή υπηρεσίας',
  add_to_calendar_button_label: 'Προσθήκη στο Ημερολόγιο',
  login_to_use_service_with_subscription: 'Συνδεθείτε στον λογαριασμό σας για να κλείσετε ραντεβού για αυτήν την υπηρεσία.',
  booking_dialog_date_is_already_taken_multiple_bookings: 'Η επιλεγμένη ημερομηνία δεν είναι διαθέσιμη. Παρακαλώ επιλέξτε μια άλλη ελεύθερη ημερομηνία. Σε περίπτωση κράτησης πολλών ημερομηνιών, βεβαιωθείτε ότι οι ημερομηνίες δεν αλληλοαποκλείονται.',
  select_location_calendar_warning: 'Επιλέξτε τοποθεσία για να φορτώσετε τις διαθέσιμες ώρες.',
  selectable_location_online_title: 'Διαδικτυακά',
  change: 'Αλλαγή',
  show_less_hours_button: 'Δείξε λιγότερες ώρες',
  show_more_hours_button: 'Δείξε περισσότερες ώρες',
  add_another_button_title: 'Πρόσθεσε άλλο ένα',
  close: 'Κλείσε',
  selected_time_slots: 'Επιλεγμένες χρονικές υποδοχές',
  select_location: 'Επιλέξτε τοποθεσία',
  captcha_invalid_error_notification: 'Η επαλήθευση captcha είναι λανθασμένη. Δοκιμάστε να ανανεώσετε τη σελίδα αν το πρόβλημα συνεχιστεί.',
  verify_code_incorrect_error: 'Ο εισαχθείς κωδικός είναι λανθασμένος.',
  verify_code_too_many_requests: 'Δεν μπορούμε να δημιουργήσουμε νέο κωδικό αυτή τη στιγμή. Δοκιμάστε ξανά αργότερα ή χρησιμοποιήστε τον κωδικό που σας στείλαμε.',
  send_again_button_title: 'Στείλτε ξανά',
  verify_title: 'Επαληθεύστε',
  verify_code_title: 'Εισάγετε τον κωδικό επαλήθευσης',
  verify_code_description: 'Στείλαμε έναν 6-ψήφιο κωδικό επαλήθευσης στο email σας. Παρακαλώ εισάγετε τον παρακάτω:',
  verification_invalid_error_notification: 'Μη έγκυρη επαλήθευση. Προσπαθήστε ξανά αργότερα.',
  too_many_requests: 'Ουπς! Κινείσαι λίγο πολύ γρήγορα. Χαλάρωσε και δοκίμασε ξανά σε λίγο.',
  services_number_title: 'Αριθμός υπηρεσιών προς επιλογή',
  booking_can_not_be_paid: 'Η πληρωμή δεν είναι διαθέσιμη. Ανανεώστε τη σελίδα ή χρησιμοποιήστε ξανά τον σύνδεσμο στο email για να δείτε την κράτηση.',
  drag_and_drop_files_here_or_click_to_upload: 'Σύρετε και αποθέστε αρχεία εδώ ή κάντε κλικ για μεταφόρτωση',
  files_uploaded: 'Ανεβασμένα αρχεία',
  max_file_size_mb: 'Μέγιστο μέγεθος αρχείου: 10 MB',
  unknown_type: 'Άγνωστος τύπος',
  upload_file_error: 'Παρουσιάστηκε σφάλμα κατά τη μεταφόρτωση του αρχείου. Αποδεκτές μορφές: PDF, XLSX, XLS, CSV, DOC, DOCX, JPG, JPEG, PNG, TXT. Το μέγεθος του αρχείου δεν μπορεί να υπερβαίνει τα 10 MB.',
  logged_in_info_notification: 'Συνδεθήκατε επιτυχώς στον λογαριασμό: @{email}',
  logged_out_info_notification: 'Αποσυνδεθήκατε επιτυχώς από τον λογαριασμό: @{email}'
}
