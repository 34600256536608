import { ActionTree } from 'vuex'
import { RootState } from '../../types'
import { FilesState } from '@/store/modules/files/types'
import api from '@/calendesk/api/APIClient'

export const actions: ActionTree<FilesState, RootState> = {
  uploadFileToSandbox (context, { file, onProgress }): Promise<string> {
    return new Promise((resolve, reject) => {
      api
        .uploadFileToSandbox(file, onProgress)
        .then(({ data }) => {
          resolve(data.uuid)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  addFileToProcessWithLastBooking ({ commit }, file: { uuid: string; file: File }) {
    commit('ADD_FILE_TO_PROCESS_WITH_LAST_BOOKING', file)
  },
  removeFileFromProcessWithLastBooking ({ commit }, fileUuid: string) {
    commit('REMOVE_FILE_FROM_PROCESS_WITH_LAST_BOOKING', fileUuid)
  }
}
