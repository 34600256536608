



















































































































































































































import CPhoneInput from '@/views/components/CPhoneInput.vue'
import parseCheckboxDescription from '@/calendesk/tools/parseCheckboxDescription'
import { FormElement } from '@/calendesk/models/FormElement'
import { BookingDynamicFormTypes } from '@/calendesk/models/BookingDynamicFormTypes'
import {
  email,
  equalsValue,
  lowercase,
  maxChars,
  minChars,
  number,
  required,
  specialChar,
  uppercase
} from '@/calendesk/forms/validators'
import mixins from 'vue-typed-mixins'
import DraftElement from '@/calendesk/sections/section/mixins/DraftElement'
import CCountryAutocomplete from '@/components/CCountryAutocomplete.vue'
import { mapActions, mapGetters } from 'vuex'
import { errorNotification } from '@/calendesk/prototypes/notifications'

export default mixins(DraftElement).extend({
  name: 'FormElements',
  props: {
    elements: {
      type: Array as () => FormElement[],
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  components: {
    CCountryAutocomplete,
    CPhoneInput
  },
  data () {
    return {
      draggingState: {} as Record<number, boolean>,
      fileInputValue: {} as { [key: number]: Array<{ uuid: string; file: File }> },
      uploadProgress: {} as Record<string, number>,
      fileError: {} as Record<number, boolean>,
      pendingUploads: [] as string[],
      formData: [] as Array<Record<string, any>>,
      addressData: [] as Array<any>,
      rules: {
        required,
        email,
        minChars,
        maxChars,
        lowercase,
        uppercase,
        number,
        specialChar,
        equalsValue
      }
    }
  },
  watch: {
    pendingUploads (newValue) {
      const isBusy = newValue.length > 0
      this.$emit('isBusy', isBusy)
    }
  },
  computed: {
    ...mapGetters({
      getFilesToProcessWithLastBooking: 'files/getFilesToProcessWithLastBooking'
    }),
    defaultCountryIsoCode (): string {
      return (this.appConfiguration.companyCountryIsoCode || this.appConfiguration.language).toLowerCase()
    }
  },
  methods: {
    ...mapActions({
      addFileToProcessWithLastBooking: 'files/addFileToProcessWithLastBooking',
      removeFileFromProcessWithLastBooking: 'files/removeFileFromProcessWithLastBooking',
      uploadFileToSandbox: 'files/uploadFileToSandbox'
    }),
    validate (): boolean {
      let isValid = true

      this.elements.forEach((element: FormElement, index: number) => {
        if (element.required) {
          if (element.type === this.dynamicFormTypes.FILES) {
            const hasFiles: boolean = this.fileInputValue[index]?.length > 0 || false
            this.$set(this.fileError, index, !hasFiles)

            if (!hasFiles) {
              isValid = false
            }
          } else {
            const value: any = this.formData[index]?.value
            if (!value) {
              isValid = false
            }
          }
        }
      })

      return isValid
    },
    onDragLeave (event: any, index: number) {
      // Check if the related target is outside the drop area
      const dropArea = event.currentTarget
      if (!dropArea.contains(event.relatedTarget)) {
        this.$set(this.draggingState, index, false)
      }
    },
    setDraggingState (index: number, state: boolean) {
      this.$set(this.draggingState, index, state)
    },
    async processFiles (files: File[], index: number, element: any) {
      if (!files.length) return

      // Define accepted formats and max file size (in bytes)
      const acceptedFormats = [
        'application/pdf',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // XLSX
        'application/vnd.ms-excel', // XLS
        'text/csv', // CSV
        'application/msword', // DOC
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // DOCX
        'image/jpeg',
        'image/png',
        'text/plain' // TXT
      ]
      const maxFileSize = 10 * 1024 * 1024 // 10 MB

      // Separate valid and invalid files
      const validFiles: File[] = []
      const invalidFiles: File[] = []

      for (const file of files) {
        if (!acceptedFormats.includes(file.type) || file.size > maxFileSize) {
          invalidFiles.push(file)
        } else {
          validFiles.push(file)
        }
      }

      // Notify user of invalid files, if any
      if (invalidFiles.length > 0) {
        errorNotification(
          'upload_file_error',
          null,
          false
        )
      }

      if (!validFiles.length) return // Exit if no valid files

      if (!this.fileInputValue[index]) {
        this.$set(this.fileInputValue, index, [])
      }

      // Prepare files with UUIDs
      const filesWithUUID = validFiles.map((file) => ({
        uuid: this.generateUUID(),
        file
      }))

      const updatedFiles = [...this.fileInputValue[index], ...filesWithUUID]
      this.$set(this.fileInputValue, index, updatedFiles)

      filesWithUUID.forEach((fileObj) => {
        this.$set(this.pendingUploads, this.pendingUploads.length, fileObj.uuid)
      })

      // Upload files concurrently
      await Promise.all(
        filesWithUUID.map(async (fileObj) => {
          this.$set(this.uploadProgress, fileObj.uuid, 0) // Initialize progress
          let uuid = fileObj.uuid

          try {
            uuid = await this.uploadFileToSandbox({
              file: fileObj.file,
              onProgress: (progressEvent: ProgressEvent) => {
                const progress = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                )
                this.$set(this.uploadProgress, fileObj.uuid, progress)
              }
            })

            // Safely update the shared state
            const updatedFileObj = { ...fileObj, uuid }

            // Use a temporary updatedFiles array to avoid race conditions
            const currentFiles = [...this.fileInputValue[index]]
            const fileIndex = currentFiles.findIndex((f) => f.uuid === fileObj.uuid)

            if (fileIndex !== -1) {
              currentFiles[fileIndex] = updatedFileObj
            } else {
              console.error('Could not find the index of', fileObj.uuid)
            }

            // Update the reactive array safely
            this.$set(this.fileInputValue, index, currentFiles)

            // Add to booking and update the dynamic form element
            await this.addFileToProcessWithLastBooking(updatedFileObj)
            this.updateDynamicFormElement(index, element, currentFiles)
          } catch (error) {
            console.error(error)

            // Remove the file if an error occurs
            this.deleteFile(index, fileObj.uuid, element)

            // Show an error notification
            errorNotification('upload_file_error', error, false)
          } finally {
            this.deleteFromPendingUploads(fileObj.uuid)
          }
        })
      )
    },
    resetFileInput (index: number) {
      const fileInputs = this.$refs['fileInput' + index] as HTMLInputElement[]
      if (fileInputs && fileInputs.length > 0) {
        fileInputs[0].value = ''
      }
    },
    resetFileErrors (index: number) {
      this.$set(this.fileError, index, false)
    },
    async handleFileSelect (event: Event, index: number, element: any) {
      this.resetFileErrors(index)
      const files = Array.from((event.target as HTMLInputElement).files || [])
      await this.processFiles(files, index, element)
      this.resetFileInput(index)
    },
    async handleFileDrop (event: DragEvent, index: number, element: any) {
      this.resetFileErrors(index)
      const files = Array.from(event.dataTransfer?.files || [])
      await this.processFiles(files, index, element)
      this.setDraggingState(index, false)
      this.resetFileInput(index)
    },
    deleteFile (index: number, uuid: string, element: any) {
      if (this.fileInputValue[index]) {
        // Find the file to be removed
        const fileToRemove = this.fileInputValue[index].find(
          (fileObj) => fileObj.uuid === uuid
        )

        if (fileToRemove) {
          // Remove the file with the matching UUID from the Vuex store first
          this.removeFileFromProcessWithLastBooking(uuid)

          // Update the local state to reflect the removal
          const updatedFiles = this.fileInputValue[index].filter(
            (fileObj) => fileObj.uuid !== uuid
          )
          this.$set(this.fileInputValue, index, updatedFiles)
          this.deleteFromPendingUploads(fileToRemove.uuid)

          // Update the dynamic form after all state changes
          this.updateDynamicFormElement(index, element, updatedFiles)
        }
      }
    },
    deleteFromPendingUploads (uuid: string) {
      const pendingIndex = this.pendingUploads.indexOf(uuid)
      if (pendingIndex !== -1) {
        this.$delete(this.pendingUploads, pendingIndex)
      }
    },
    triggerFileInput (index: number) {
      const fileInput = this.$refs['fileInput' + index] as any
      if (fileInput && fileInput.length > 0) {
        fileInput[0].click()
      }
    },
    previousElementWasCheckbox (index: number): boolean {
      const previousElement: any = this.elements[index - 1]

      return previousElement && previousElement.type === this.dynamicFormTypes.CHECKBOX
    },
    nextElementIsCheckbox (index: number): boolean {
      const previousElement: any = this.elements[index + 1]

      return previousElement && previousElement.type === this.dynamicFormTypes.CHECKBOX
    },
    getAddressDetailsForIndex (index: number): string {
      const street = this.addressData[index].street_and_number
      const postcode = this.addressData[index].postcode
      const city = this.addressData[index].city
      const country = this.addressData[index].country || this.defaultCountryIsoCode
      const addressValues = [street, postcode, city, country.toUpperCase()]
      const filteredValues = addressValues.filter(val => val)
      return filteredValues
        .map((val, i) => i === filteredValues.length - 1 ? val : val + ', ') // add comma after each value except the last value
        .join('')
    },
    updateAddressFormData (index: number, element: any, key: string, value: any) {
      if (!this.addressData[index]) {
        this.addressData[index] = []
      }

      this.addressData[index][key] = value

      this.updateDynamicFormElement(index, element, null)
    },
    updateDynamicFormElement (index: number, element: any, value: any) {
      const type = element.type
      let parsedValue = value
      if (element.type === this.dynamicFormTypes.ADDRESS) {
        parsedValue = this.getAddressDetailsForIndex(index)
      }

      if (element.type === this.dynamicFormTypes.FILES) {
        parsedValue = value.map((fileObj: { uuid: string; file: File }) => ({
          [fileObj.uuid]: fileObj.file.name
        }))

        const currentFiles = this.getFilesToProcessWithLastBooking || []
        value.forEach((fileObj: { uuid: string; file: File }) => {
          // Check if the file is already in the store
          const isDuplicate = currentFiles.some(
            (currentFile: any) => currentFile.uuid === fileObj.uuid
          )

          if (!isDuplicate) {
            // Add the file to the store if it's not already present
            this.addFileToProcessWithLastBooking(fileObj)
          }
        })
      }

      this.formData[index] = {
        type: type,
        label: element.label,
        description: element.description,
        required: element.required,
        equal: element.equal,
        radioOptions: element.radioOptions,
        value: parsedValue
      }

      this.$emit('updated', this.formData.filter(value => value))
    },
    getRulesForDynamicElement (element: any, merge: Array<any> = []): Array<any> {
      const rules = []
      if (element.required) {
        rules.push(this.rules.required)
      }

      if (element.type === BookingDynamicFormTypes.TEXT_FIELD ||
        element.type === BookingDynamicFormTypes.EMAIL) {
        rules.push(this.rules.maxChars(255))
      } else if (element.type === BookingDynamicFormTypes.TEXT_AREA) {
        rules.push(this.rules.maxChars(5000))
      }

      if (element.type === BookingDynamicFormTypes.EMAIL) {
        rules.push(this.rules.email)
      }

      if (element.equal) {
        rules.push(this.rules.equalsValue(element.equal))
      }

      return rules.concat(merge)
    },
    parseCheckboxDescription
  }
})
