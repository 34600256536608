import { sendRequest } from '@/calendesk/api/SendRequest'
import BookingCreateRequestData from '@/calendesk/models/DTO/Request/BookingCreateRequestData'
import BookingFiltersRequestData from '@/calendesk/models/DTO/Request/BookingFiltersRequestData'
import BookingCreatePaymentRequestData from '@/calendesk/models/DTO/Request/BookingCreatePaymentRequestData'
import BookingPaymentTransactionsRequestData from '@/calendesk/models/DTO/Request/BookingPaymentTransactionsRequestData'
import UpdateUserRequestData from '@/calendesk/models/DTO/Request/UpdateUserRequestData'
import ResetPasswordRequestData from '@/calendesk/models/DTO/Request/ResetPasswordRequestData'
import NewsletterSignupRequestData from '@/calendesk/models/DTO/Request/NewsletterSignupRequestData'
import SimpleStoreProductCreatePaymentRequestData
  from '@/calendesk/models/DTO/Request/SimpleStoreProductCreatePaymentRequestData'
import SimpleStoreProductTransactionRequestData
  from '@/calendesk/models/DTO/Request/SimpleStoreProductTransactionRequestData'
import CreatePaymentForBookingsRequestData from '@/calendesk/models/DTO/Request/CreatePaymentForBookingsRequestData'
import PaymentMethodRequestData from '@/calendesk/models/DTO/Request/PaymentMethodRequestData'
import AvailableSubscriptionsRequestData from '@/calendesk/models/DTO/Request/AvailableSubscriptionsRequestData'
import StripeConnectSubscriptionData from '@/calendesk/models/DTO/Request/StripeConnectSubscriptionData'
import UserSubscriptionsRequestData from '@/calendesk/models/DTO/Request/UserSubscriptionsRequestData'
import UserSubscriptionPaymentTransactionsRequestData
  from '@/calendesk/models/DTO/Request/UserSubscriptionPaymentTransactionsRequestData'
import PayForBookingsWithUserSubscriptionRequestData
  from '@/calendesk/models/DTO/Request/PayForBookingsWithUserSubscriptionRequestData'
import StripeConnectSetupIntentRequestData from '@/calendesk/models/DTO/Request/StripeConnectSetupIntentRequestData'
import UserPreferences from '@/calendesk/models/DTO/UserPreferences'
import GeneralEmailMessageRequestData from '@/calendesk/models/DTO/Request/GeneralEmailMessageRequestData'
import BookingRescheduleRequestData from '@/calendesk/models/DTO/Request/BookingRescheduleRequestData'
import VerifyCodeRequestData from '@/calendesk/models/DTO/Request/VerifyCodeRequestData'
import VerifyEmailRequestData from '@/calendesk/models/DTO/Request/VerifyEmailRequestData'

const APIClient = {
  healthy () {
    return sendRequest('GET', 'healthy/tenant')
  },

  healthyCentral () {
    return sendRequest('GET', 'healthy/central')
  },

  configuration () {
    return sendRequest('GET', 'settings/web')
  },

  getRegulations () {
    return sendRequest('GET', 'settings/regulations')
  },

  getPrivacyPolicy () {
    return sendRequest('GET', 'settings/privacy_policy')
  },

  getLocale () {
    return sendRequest('GET', 'locale')
  },

  login (data: Record<string, string>) {
    return sendRequest(
      'POST',
      'tenant/auth/login',
      data,
      false,
      {},
      { skipAuthRefresh: true },
      null,
      true)
  },

  signUpUserAndStoreSession (data: Record<string, string>) {
    return sendRequest(
      'POST',
      'tenant/auth/signup',
      data,
      false,
      {},
      { skipAuthRefresh: true },
      null,
      true)
  },

  forgottenPassword (email: string) {
    return sendRequest('GET', `auth/login/${email}/remind-password`, null, false, {}, { skipAuthRefresh: true })
  },

  logout () {
    return sendRequest('POST', 'auth/logout', null, true, {}, { skipAuthRefresh: true })
  },

  getUserData () {
    return sendRequest('GET', 'v2/user', null, true)
  },

  getBookingByControl (control: string) {
    return sendRequest('GET', `user/bookings/v2/control/${control}`)
  },

  updateUsersData (data: UpdateUserRequestData) {
    return sendRequest('PATCH', 'user', data, true)
  },

  updateUserPreferencesData (data: UserPreferences) {
    return sendRequest('PUT', 'user/preferences', data, true)
  },

  getPublishedWebsite (draftUuid: string) {
    return sendRequest('GET', `website/v2/published/${draftUuid}`)
  },

  getTenantByDomain (domain: string) {
    return sendRequest('GET', 'v2/tenants/domain', { url: domain })
  },

  getDraftPage (draftUuid: string, pageId: number) {
    return sendRequest('GET', `website/v2/published/${draftUuid}/pages/${pageId}`)
  },

  getEmployees (data: Record<string, any >) {
    return sendRequest('GET', 'employees', data)
  },

  getCategoriesAndServices (data: Record<string, any >) {
    return sendRequest('GET', 'v2/categories/services', data)
  },

  getServices (data: Record<string, any >) {
    return sendRequest('GET', 'services', data)
  },

  getBookings (data: BookingFiltersRequestData) {
    return sendRequest('GET', 'user/bookings', data, true)
  },

  getStripeAccount () {
    return sendRequest('GET', 'stripe/connect/account')
  },

  getStripeConnectSetupIntent (data: StripeConnectSetupIntentRequestData) {
    return sendRequest('GET', 'stripe/connect/customers/payment-methods/setup/intent', data, true)
  },

  attachPaymentMethodToStripeConnectCustomerAccount (data: PaymentMethodRequestData) {
    return sendRequest('POST', 'stripe/connect/customers/payment-methods/setup/attach', data, true)
  },

  detachPaymentMethodFromStripeConnectCustomerAccount () {
    return sendRequest('DELETE', 'stripe/connect/customers/payment-methods/setup/detach', null, true)
  },

  createUserSubscription (data: StripeConnectSubscriptionData) {
    return sendRequest('POST', `/subscriptions/${data.subscription_id}`, data, true)
  },

  createPaymentForBooking (data: BookingCreatePaymentRequestData) {
    return sendRequest('POST', 'stripe/connect/payment/checkout/booking', data, true)
  },

  createPaymentForBookings (data: CreatePaymentForBookingsRequestData) {
    return sendRequest('POST', 'stripe/connect/payment/checkout/bookings', data)
  },

  createPaymentForSimpleStoreProduct (data: SimpleStoreProductCreatePaymentRequestData, withAuth = false) {
    if (withAuth) {
      return sendRequest('POST', 'stripe/connect/payment/checkout/simple-store-product/v2/auth', data, true)
    } else {
      return sendRequest('POST', 'stripe/connect/payment/checkout/simple-store-product/v2', data, false)
    }
  },

  createStripeCustomerForConnectedAccount () {
    return sendRequest('POST', 'stripe/connect/customers', null, true)
  },

  cancelBooking (control: string) {
    return sendRequest('PUT', `user/bookings/v2/control/${control}/cancel`)
  },

  rescheduleBooking (data: BookingRescheduleRequestData) {
    return sendRequest('PATCH', `user/bookings/v2/control/${data.control}/reschedule`, data)
  },

  getBookingFilters () {
    return sendRequest('GET', 'user/bookings/filters', null, true)
  },

  getAvailableBookingSlots (data: Record<string, any >) {
    return sendRequest('GET', 'available-slots', data)
  },

  createBooking (data: BookingCreateRequestData, withAuth = false) {
    if (withAuth) {
      return sendRequest(
        'POST',
        'user/bookings/create/auth',
        data,
        true,
        {},
        {},
        null,
        true)
    } else {
      return sendRequest(
        'POST',
        'user/bookings/create/no-auth',
        data,
        false,
        {},
        {},
        null,
        true)
    }
  },

  getBookingPaymentTransactions (data: BookingPaymentTransactionsRequestData) {
    return sendRequest('GET', 'payments/bookings', data, true)
  },

  getSimpleStoreProductPaymentTransactions (data: SimpleStoreProductTransactionRequestData) {
    return sendRequest('GET', 'simple-store/product/transaction', data, true)
  },

  getSimpleStoreProduct (productId: string|number) {
    return sendRequest('GET', 'simple-store/product/' + productId, null, false)
  },

  uploadPicture (data: FormData) {
    return sendRequest('POST', 'user/upload/picture', data, true, {
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/x-www-form-urlencoded'
    })
  },

  resendActivationLink (email: string) {
    return sendRequest('GET', `auth/signup/${email}/resend-activation-link`)
  },

  activateUserAccount (token: string) {
    return sendRequest('GET', `auth/signup/activate/${token}`)
  },

  newsletterSignup (data: NewsletterSignupRequestData) {
    return sendRequest('POST', 'newsletter', data)
  },

  newsletterActivateEmail (token: string) {
    return sendRequest('GET', `newsletter/subscribe/${token}`)
  },

  newsletterUnsubscribeEmail (token: string) {
    return sendRequest('GET', `newsletter/unsubscribe/${token}`)
  },

  resendNewsletterActivationLink (email: string) {
    return sendRequest('GET', `newsletter/resend/${email}`)
  },

  resetPassword (data: ResetPasswordRequestData, token: string) {
    return sendRequest('PUT', `auth/login/reset-password/${token}`, data)
  },

  getAvailableSubscriptions (data: AvailableSubscriptionsRequestData) {
    return sendRequest('GET', 'subscriptions', data)
  },

  getSubscription (subscriptionId: number) {
    return sendRequest('GET', `subscriptions/${subscriptionId}`)
  },

  cancelUserSubscription (subscriptionId: number) {
    return sendRequest('POST', `user/subscriptions/${subscriptionId}/cancel`, null, true)
  },

  payForBookingsWithUserSubscription (data: PayForBookingsWithUserSubscriptionRequestData) {
    return sendRequest('POST', 'bookings/pay/subscriptions', data, true)
  },

  getUserSubscriptions (data: UserSubscriptionsRequestData) {
    return sendRequest('GET', 'v2/user/subscriptions', data, true)
  },

  getUserSubscriptionPaymentTransactions (data: UserSubscriptionPaymentTransactionsRequestData) {
    return sendRequest('GET', 'user/subscriptions/transactions', data, true)
  },

  getCompanyData (countryISOCode: string, taxNumber: string) {
    return sendRequest('GET', `company/${countryISOCode}/${taxNumber}`)
  },

  sendGeneralEmailMessage (data: GeneralEmailMessageRequestData) {
    return sendRequest('POST', 'mail/tenant/send', data)
  },

  checkIfBookingCanBePaidWithSubscription (bookingId: number) {
    return sendRequest('GET', 'user/bookings/' + bookingId + '/subscriptions/can-pay')
  },

  verifyCode (data: VerifyCodeRequestData) {
    return sendRequest('POST', 'verify/code', data)
  },

  verifyEmail (data: VerifyEmailRequestData) {
    return sendRequest('POST', 'bookings/verify/email', data)
  },

  uploadFileToSandbox (file: File, progressCallback: (progressEvent: ProgressEvent) => void) {
    const formData = new FormData()
    formData.append('file', file)

    return sendRequest(
      'POST',
      'files/sandbox/upload',
      formData,
      false,
      {
        'Content-Type': 'multipart/form-data'
      },
      {},
      null,
      false,
      null,
      progressCallback
    )
  }
}

export default APIClient
