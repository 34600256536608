export default {
  error_occurred: 'Kahjuks ilmnes viga. Proovige hetke pärast uuesti.',
  new_content_available: "Leheküljel on uus värskendus, lehe uuesti laadimiseks klõpsake nuppu 'Värskenda'.",
  refresh_button_title: 'Värskenda',
  force_reload_message: 'Kahjuks ilmnes viga. Meie meeskond juba töötab selle kallal. Proovige veebisaiti hetke pärast uuesti laadida.',
  sign_up: 'Kontot looma',
  service: 'Teenindus',
  employee: 'Töötaja',
  select_employee: 'Valige inimene',
  employees: 'Töötajad',
  services: 'Teenused',
  duration: 'Kestus',
  from: 'Alates',
  select_date: 'Valige kuupäev',
  no_account_question: 'Kas teil pole veel kontot?',
  name: 'Eesnimi',
  surname: 'Perekonnanimi',
  name_and_surname: 'Täisnimi',
  phone: 'Telefon',
  password: 'Parool',
  field_is_required: 'Selle välja täitmine on kohustuslik.',
  value_is_too_long: 'Väärtus on liiga pikk.',
  value_is_too_short: 'Väärtus on liiga lühike.',
  signup_was_successful: 'Registreerimine õnnestus. Paneelile sisselogimine.',
  signup_has_failed: 'Registreerimine ebaõnnestus. Proovige mõne aja pärast uuesti.',
  email_is_taken: 'Antud meiliaadressiga konto on juba olemas. Kui unustasite parooli, kasutage meeldetuletuse valikut.',
  email_or_password_incorrect: 'E-posti aadress või parool on vale. Kui unustasite parooli, kasutage meeldetuletuse valikut.',
  account_not_found: 'Kontot ei leitud.',
  page_not_found: 'Oih! Otsitud lehte ei leitud :(',
  go_back_to_main_page: 'Minge tagasi avalehele ja proovige uuesti.',
  bookings: 'Broneeringud',
  account: 'Minu konto',
  date: 'Kuupäev',
  cancel: 'Tühista',
  date_from: 'Kuupäev alates',
  date_to: 'Kuupäev kuni',
  nothing_found_here: 'Midagi ei leitud.',
  old_password: 'Vana parool',
  remove: 'Kustuta',
  phone_is_invalid: 'Telefoninumber on vale.',
  book: 'Raamat',
  sign_up_2: 'Kontot looma',
  log_in: 'Logi sisse',
  your_email_address: 'Sinu emaili aadress',
  select: 'Valige',
  filters: 'Filtrid',
  expand: 'Laienda',
  collapse: 'Ahenda',
  bookings_drawer_title: 'Valige filtrid, et kuvada ainult need broneeringud, mida otsite.',
  clear_filters: 'Lähtestage filtrid',
  show_more: 'Näita rohkem',
  dont_you_remember_password: 'Unustasid salasõna?',
  password_information: 'Parool peaks koosnema vähemalt 8 tähemärgist, sisaldama suurtähti, numbrit ja erimärki.',
  forgotten_password_button: 'Parooli muutmiseks saatke link',
  do_you_already_have_an_account: 'Kas teil on juba konto?',
  reset_password_title: 'Määra uus parool',
  reset_password_subtitle: 'Sisestage allpool oma uus parool.',
  reset_password_button: 'Salvestage uus parool',
  profile: 'Profiil',
  logout: 'Logi välja',
  cancel_booking: 'Tühista broneering',
  status_approved: 'Kinnitatud',
  status_payment: 'Makse ootamine',
  status_done: 'Lõpetatud',
  status_cancelled: 'Tühistatud',
  cancel_booking_dialog_title: 'Kas soovite broneeringu tühistada?',
  cancel_booking_dialog_information: 'Seda toimingut ei saa tagasi võtta!',
  cancel_booking_dialog_button: 'Jah, tühista minu broneering',
  my_profile: 'Minu profiil',
  my_data: 'Minu andmed',
  upload_new_photo: 'Lisa uus foto',
  birthday: 'Sünnipäev',
  save_changes: 'Salvesta muudatused',
  phone_number: 'Telefoninumber',
  directional: 'Piirkonna kood',
  save_new_password: 'Salvestage uus parool',
  new_password: 'Uus salasõna',
  password_change: 'Muuda salasõna',
  view: 'Vaade',
  services_2: 'Teenused',
  accept_terms_and_conditions_1: 'Alloleval valikul klõpsates nõustun',
  accept_terms_and_conditions_2: 'Tingimused',
  accept_terms_and_conditions_3: 'ja kinnitan, et olen lugenud',
  accept_terms_and_conditions_4: 'Privaatsusavaldus.',
  to: 'To',
  pln: '@{value} PLN',
  usd: '$@{value}',
  eur: '@{value} EUR',
  gbp: '£@{value}',
  jpy: '@{value} JPY',
  aud: '@{value} AUD',
  cad: '@{value} CAD',
  chf: '@{value} CHF',
  sek: '@{value} SEK',
  nzd: '@{value} NZD',
  brl: '@{value} BRL',
  bgn: '@{value} BGN',
  czk: '@{value} CZK',
  dkk: '@{value} Taani krooni',
  hkd: '@{value} HKD',
  huf: '@{value} HUF',
  ils: '@{value} ILS',
  myr: '@{value} MYR',
  mxn: '@{value} MXN',
  twd: '@{value} TWD',
  nok: '@{value} NOK',
  php: '@{value} PHP',
  ron: '@{value} RON',
  rub: '@{value} RUB',
  sgd: '@{value} SGD',
  thb: '@{value} THB',
  uah: '@{value} UAH',
  inr: '@{value} INR',
  clp: '@{value} CLP',
  login: 'Logi sisse',
  email_is_incorrect: 'E-posti aadress on vale.',
  logged_out: 'Teid on välja logitud',
  booking_dialog_date_is_already_taken: 'Valitud aeg pole saadaval. Kas valiksite mõne muu koha?',
  data_search: 'Andmete otsimine. Palun oota...',
  no_employees: 'Töötajaid pole',
  no_services: 'Teenused puuduvad',
  no_categories: 'Kategooriaid pole',
  no_bookings: 'Broneeringuid ei leitud.',
  no_subscriptions: 'Tellimusi ei leitud.',
  female: 'Naine',
  male: 'Mees',
  other: 'Muud',
  no_numeric_in_password: 'Paroolis ei ole numbrimärki.',
  no_lowercase_in_password: 'Paroolis pole väiketähti.',
  no_uppercase_in_password: 'Paroolis pole suurtähti.',
  no_special_character: 'Pole erilist tegelast.',
  min_n_characters: 'Minimaalne märkide arv on @{min}.',
  max_n_characters: 'Maksimaalne märkide arv on @{max}.',
  email: 'E-post',
  reset_password_fail_text: 'Parooli pole muudetud. Link on ilmselt aegunud.',
  reset_password_success_title: 'Teie parool on muudetud.',
  reset_password_success_text: 'Saate sisse logida oma uue parooliga.',
  account_activation: 'Konto aktiveerimine',
  account_activation_success: 'Konto on aktiveeritud.',
  account_activation_fail: 'Teie konto aktiveerimisel ilmnes probleem. Näib, et konto on juba aktiivne või link ei kehti enam.',
  account_activation_processing: 'Teie konto aktiveerimine. Palun oota...',
  account_activation_resend_title: 'Konto pole aktiveeritud',
  account_activation_resend_text: 'Aktiveerige oma konto, klõpsates teile saadetud meilis oleval aktiveerimislingil.',
  account_activation_resend_button: 'Saada uuesti',
  confirm_send_activate_link_dialog_subtitle: 'Oleme saatnud aktiveerimislingiga meili varem antud meiliaadressile.',
  file_is_too_large: 'Valitud fail on liiga suur.',
  appointment_title: 'Valige broneerimise isik, kuupäev ja kellaaeg',
  others: 'muud',
  countryLabel: 'Riik',
  requiredMessages: 'Vaja on telefoninumbrit',
  date_of_visit: 'Kuupäev',
  hour_of_visit: 'Aeg',
  thank_you_for_booking: 'Teie broneering',
  no_free_hours: 'Valitud päeval pole vabu aegu.',
  any_person: 'Juhuslik valik',
  password_changed: 'Teie parool on muudetud',
  incorrect_old_password: 'Vana parool on vale',
  error404: 'Viga 404',
  minutes: 'min',
  hours: 'h',
  days: 'd',
  ok: 'Okei',
  web_page_temporary_disabled: 'Vabandust! See leht oli ajutiselt keelatud.',
  profile_update_success: 'Teie andmed on salvestatud.',
  form_is_invalid: 'Vorm sisaldab vigu. Kontrollige esiletõstetud välju ja proovige uuesti.',
  search: 'Otsing',
  pay_online_button_title: 'Maksa Internetis',
  account_not_activated_info: 'E-posti aadressi pole kinnitatud. Kinnitage oma e-posti aadress, klõpsates saadetud sõnumis olevat linki.',
  paid: 'Tasuline',
  payments: 'Maksed',
  payment_service: 'Teenindus',
  payment_date: 'Kuupäev',
  payment_price: 'Hind',
  payment_success_title: 'Makse sooritamine õnnestus, aitäh!',
  payment_fail_title: 'Makse ebaõnnestus, proovige uuesti.',
  pay_again: 'Maksa uuesti',
  newsletter_subscribe_success: 'Aitäh! Teie e-posti aadress on kinnitatud.',
  newsletter_subscribe_fail: 'Teie e-posti aadressi aktiveerimisel ilmnes probleem. Näib, et meiliaadress on juba aktiivne või link ei kehti enam.',
  newsletter_send_again: 'Saada uuesti',
  newsletter_send_again_success: 'Aktiveerimislink on uuesti saadetud.',
  send_again_fail: 'Uue aktiveerimislingi loomisel ilmnes probleem, võib-olla on teie e-posti aadress juba aktiivne või olete just lingi loonud.',
  newsletter_email_is_used: 'Meiliaadress on juba nimekirjas, kui aadress pole kinnitatud, saame aktiveerimislingi uuesti saata.',
  newsletter_unsubscribe_success: 'Aitäh! Teie e-posti aadress on eemaldatud.',
  newsletter_unsubscribe_fail: 'E-posti aadressi kustutamisel ilmnes probleem. Näib, et meiliaadress on juba kustutatud või link on aegunud.',
  booking_payments: 'Broneeringud',
  simple_store_product_transactions: 'Tooted',
  payment_type_select_box: 'Valige makse tüüp',
  payment_description: 'Kirjeldus',
  add_to_cart: 'Lisa ostukorvi',
  remove_from_cart: 'Eemalda ostukorvist',
  save: 'Salvesta',
  bookings_cart_summary: 'Broneeringu kokkuvõte',
  today: 'Täna',
  fill_booking_details_title: 'Sisestage üksikasjad',
  create_an_account_question: 'Loo konto ja saada parool e-posti aadressile',
  value_is_incorrect: 'See väärtus ei kehti.',
  first_available_date: 'Saadaolevad ajapilud sisse lülitatud',
  service_location_1: 'Aadress',
  service_location_2: 'Telefon',
  service_location_3: 'Google Meet',
  service_location_4: 'Muud',
  service_location_5: 'Zoom',
  service_location_6: 'Microsoft Teams',
  service_location_7: 'Skype',
  service_location_8: 'WhatsApp',
  service_location: 'Asukoht',
  booking_not_found: 'Broneeringut ei leitud.',
  booking_canceled: 'Broneering tühistati.',
  booking_canceled_fail: 'Seda broneeringut ei saa tühistada.',
  day: 'Päev',
  week: 'Nädal',
  month: 'Kuu',
  year: 'Aasta',
  booking_plural_1: 'Broneerimine',
  booking_plural_2: 'Broneeringud',
  booking_plural_3: 'Broneeringud',
  booking_plural_4: 'Broneeringud',
  booking_plural_5: 'Broneeringud',
  day_plural_1: 'Päev',
  day_plural_2: 'Päevad',
  day_plural_3: 'Päevad',
  day_plural_4: 'Päevad',
  day_plural_5: 'Päevad',
  week_plural_1: 'Nädal',
  week_plural_2: 'Nädalad',
  week_plural_3: 'Nädalad',
  week_plural_4: 'Nädalad',
  week_plural_5: 'Nädalad',
  month_plural_1: 'Kuu',
  month_plural_2: 'Kuud',
  month_plural_3: 'Kuud',
  month_plural_4: 'Kuud',
  month_plural_5: 'Kuud',
  year_plural_1: 'Aasta',
  year_plural_2: 'Aastaid',
  year_plural_3: 'Aastaid',
  year_plural_4: 'Aastaid',
  year_plural_5: 'Aastaid',
  tax: 'KMKR ID',
  tax_prefix: 'Eesliide',
  get_company_data: 'Otsing',
  get_company_data_failed: 'Andmeid ei saanud alla laadida.',
  company_name: 'Ettevõtte nimi',
  street_and_number: 'Tänav ja number',
  postcode: 'Postiindeks',
  city: 'Linn',
  country: 'Riik',
  subscribe: 'Telli',
  recurrent_fee_info: 'Arveldatakse iga',
  one_time_fee_info: 'Ühekordne tasu.',
  new_account_title: 'Looge uus konto',
  payment_data_title: 'Makse andmed',
  billing_data_checkbox_question: 'Arvelduse üksikasjad',
  confirmation_modal_header: 'Toiming nõuab kinnitust',
  confirmation_modal_warning: 'Kas soovite kindlasti jätkata?',
  no_way_to_undo_action: 'Seda toimingut ei saa tagasi võtta!',
  delete_payment_method: 'Eemalda kaart',
  add_payment_method: 'Lisa kaart',
  fill_card_details: 'Täitke makse üksikasjad.',
  subscriptions: 'Tellimused',
  subscription: 'Tellimus',
  invoice_number: 'Arve',
  number: 'Number',
  status: 'Olek',
  details: 'Üksikasjad',
  created_at: 'Loodud',
  subscription_status_initiated: 'Tasumata',
  subscription_status_active: 'Aktiivne',
  subscription_status_inactive: 'Mitteaktiivne',
  subscription_status_canceled: 'Tühistatud',
  ends_at: 'Lõpeb kell',
  ends_at_default: 'Kehtib kuni arveldusperioodi lõpuni',
  select_subscription_to_pay: 'Valige tasumiseks tellimus.',
  pay_with_subscription: 'Makske tellimusega',
  bookings_paid_by_subscription_success_message: 'Tasuline',
  bookings_not_paid_by_subscription_error_message: 'Broneeringut ei saanud valitud tellimusega tasuda.',
  stripe: 'Triip',
  provider_tag: 'meetod',
  card: 'Kaart',
  cash: 'Sularaha',
  transfer: 'Ülekanne',
  promo_code_label: 'Sooduskood',
  promo_code_invalid_message: 'Esitatud sooduskood on kehtetu.',
  payment_method_setup_error: 'Teie kaardiga tekkis probleem. Teie pank on keeldunud kaarti hilisemateks tehinguteks autoriseerimast. Võtke ühendust oma pangaga.',
  active_subscription_missing: 'Selle teenuse broneerimiseks on vaja aktiivset tellimust. Kahjuks pole teil tellimust kasutada.',
  area_code: 'Piirkonna kood',
  usage: 'Kasutamine',
  create_account_in_stripe_failed: 'Makseoperaator lükkas teie tellimusandmed tagasi. Palun kontrollige ankeedi õigsust (maksukohustuslasena registreerimise number, telefoninumber, e-posti aadress).',
  additional_auth_required: 'Teie pank nõuab täiendavat autoriseerimist',
  general_payment_error: 'Teie maksega tekkis probleem. Võib-olla ei toeta teie kaart tellimusi, sellel on ostulimiidid või pole piisavalt vahendeid tellimuse eest tasumiseks. Võtke ühendust oma pangaga või valige mõni muu makseviis.',
  subscription_set_successfully: 'Tellimus on õigesti seadistatud.',
  booking_created: 'Uus broneering',
  booking_updated: 'Broneeringu muutmine',
  booking_deleted: 'Broneeringu kustutamine',
  booking_reminder: 'Broneeringu meeldetuletus',
  email_notifications: 'Meiliteatised',
  sms_notifications: 'SMS-teavitused',
  save_user_preferences: 'Salvestage eelistused',
  free_spots: 'Täpid jäänud',
  no: 'Ei',
  yes: 'Jah',
  p24_inactive: 'Makseoperaatorit Przelewy24 ei saa kasutada. Võtke ühendust oma makseoperaatoriga.',
  stripe_customer_invalid: "Vigane Stripe'i kliendi ID. Makseviisi ei saa kliendile lisada.",
  stripe_resource_invalid: 'Valitud toode pole saadaval. Palun kontrollige toote saadavust makseoperaatorilt.',
  account_blocked_message: 'Teie konto on passiivne. Võtke ühendust administraatoriga.',
  time_zone: 'Ajavöönd',
  no_spots_left: 'Olete saavutanud maksimaalse saadaolevate kohtade arvu.',
  employee_auth_required: 'Logige oma kontole sisse või kasutage teist e-posti aadressi. Töötajate kontod nõuavad sisselogimist.',
  subpage_auth_required: 'Vahekaardi vaatamiseks logige oma kontole sisse.',
  gross: 'Brutosumma.',
  subscription_login_info: 'On juba konto? Andmete täitmiseks logige allpool sisse.',
  change_date_time: 'Ajastada ümber',
  promo_code_checkbox: 'Mul on sooduskood',
  booking_promo_code_label: 'Sisesta sooduskood',
  select_hour: 'Vali aeg',
  id: 'Id',
  booking_rescheduled: 'Teie broneering on muudetud.',
  booking_rescheduled_fail: 'Seda broneeringut ei saa muuta.',
  max_user_bookings_error: 'Olete ületanud maksimaalse broneeringute arvu. Valige mõni muu teenus või võtke meiega ühendust.',
  in_total: 'Kokku',
  company: 'Ettevõte',
  individual: 'Eraisik',
  bulk_payment: 'Hulgimakse',
  make_bulk_payment: 'Hulgimakse',
  simple_store_product_confirmation_title: 'Täitke ostuvorm',
  select_service_type: 'Valige teenuse valik',
  add_to_calendar_button_label: 'Lisa kalendrisse',
  login_to_use_service_with_subscription: 'Selle teenuse jaoks aja broneerimiseks logige oma kontole sisse.',
  booking_dialog_date_is_already_taken_multiple_bookings: 'Valitud kuupäev pole saadaval. Palun valige teine vaba kuupäev. Mitme kuupäeva broneerimisel veenduge, et kuupäevad ei välista teineteist.',
  select_location_calendar_warning: 'Valige asukoht, et laadida saadaval olevad ajad.',
  selectable_location_online_title: 'Veebis',
  change: 'Muuda',
  show_less_hours_button: 'Näita vähem tunde',
  show_more_hours_button: 'Näita rohkem tunde',
  add_another_button_title: 'Lisa veel üks',
  close: 'Sulge',
  selected_time_slots: 'Valitud ajavahemikud',
  select_location: 'Vali asukoht',
  captcha_invalid_error_notification: 'Captcha kinnitamine ebaõnnestus. Proovige lehte värskendada, kui probleem püsib.',
  verify_code_incorrect_error: 'Sisestatud kood on vale.',
  verify_code_too_many_requests: 'Hetkel ei saa me uut koodi genereerida. Proovige hiljem uuesti või kasutage juba saadetud koodi.',
  send_again_button_title: 'Saada uuesti',
  verify_title: 'Kinnita',
  verify_code_title: 'Sisesta kinnituskood',
  verify_code_description: 'Saatsime teie e-posti 6-kohalise kinnituskoodi. Palun sisestage see allpool:',
  verification_invalid_error_notification: 'Kehtetu kinnitamine. Proovi hiljem uuesti.',
  too_many_requests: 'Ups! Toimite natuke liiga kiiresti. Palun aeglustage ja proovige varsti uuesti.',
  services_number_title: 'Valikus olevate teenuste arv',
  booking_can_not_be_paid: 'Makse ei ole saadaval. Värskendage lehte või kasutage uuesti e-kirjas olevat linki, et broneeringut vaadata.',
  drag_and_drop_files_here_or_click_to_upload: 'Lohistage failid siia või klõpsake nende üleslaadimiseks',
  files_uploaded: 'Üleslaaditud failid',
  max_file_size_mb: 'Maksimaalne faili suurus: 10 MB',
  unknown_type: 'Tundmatu tüüp',
  upload_file_error: 'Faili üleslaadimisel ilmnes tõrge. Lubatud vormingud: PDF, XLSX, XLS, CSV, DOC, DOCX, JPG, JPEG, PNG, TXT. Faili suurus ei tohi ületada 10 MB.',
  logged_in_info_notification: 'Edukas sisselogimine kontole: @{email}',
  logged_out_info_notification: 'Edukas väljalogimine kontolt: @{email}'
}
