export default {
  error_occurred: 'Przepraszamy, wystąpił błąd. Prosimy spróbować za moment.',
  new_content_available: 'Pojawiła się nowa aktualizacja strony, kliknij „Odśwież”, aby przeładować stronę.',
  refresh_button_title: 'Odśwież',
  force_reload_message: 'Przepraszamy, wystąpił błąd. Nasz zespół pracuje już nad jego usunięciem. Spróbuj przeładować stronę.',

  sign_up: 'Załóż konto',
  service: 'Usługa',
  employee: 'Specjalista',
  select_employee: 'Wybierz osobę',
  employees: 'Specjaliści',
  services: 'Usługi',
  duration: 'Czas trwania',
  from: 'Od',
  select_date: 'Wybierz datę',
  no_account_question: 'Nie masz jeszcze konta?',
  name: 'Imię',
  surname: 'Nazwisko',
  name_and_surname: 'Imię i nazwisko',
  phone: 'Telefon',
  password: 'Hasło',
  field_is_required: 'Pole jest wymagane.',
  value_is_too_long: 'Wprowadzona wartość jest za długa.',
  value_is_too_short: 'Wprowadzona wartość jest za krótka.',
  signup_was_successful: 'Rejestracja przebiegła pomyślnie. Trwa logowanie do panelu.',
  signup_has_failed: 'Rejestracja się nie udała. Spróbuj za chwilę.',
  email_is_taken: 'Konto z podanym adresem e-mail już istnieje. Jeśli zapomniałeś hasła, skorzystaj z opcji przypomnienia.',
  email_or_password_incorrect: 'Podany adres e-mail lub hasło są niepoprawne. Jeśli nie pamiętasz hasła, skorzystaj opcji przypomnienia.',
  account_not_found: 'Nie znaleziono konta.',
  page_not_found: 'Ups! Nie znaleziono szukanej strony  :(',
  go_back_to_main_page: 'Wróć do strony głównej i spróbuj ponownie.',
  bookings: 'Rezerwacje',
  account: 'Moje konto',
  date: 'Data',
  cancel: 'Anuluj',
  date_from: 'Data od',
  date_to: 'Data do',
  nothing_found_here: 'Nic nie znaleziono.',
  old_password: 'Stare hasło',
  remove: 'Usuń',
  phone_is_invalid: 'Numer telefonu jest niepoprawny.',
  book: 'Rezerwuj',
  sign_up_2: 'Załóż konto',
  log_in: 'Logowanie',
  your_email_address: 'Twój adres e-mail',
  select: 'Wybierz',
  filters: 'Filtry',
  expand: 'Rozwiń',
  collapse: 'Zwiń',
  bookings_drawer_title: 'Wybierz filtry, aby pokazać tylko rezerwacje, których szukasz.',
  clear_filters: 'Wyczyść filtry',
  show_more: 'Pokaż więcej',
  dont_you_remember_password: 'Nie pamiętasz hasła?',
  password_information: 'Hasło powinno składać się z minimum 8 znaków, zawierać wielką literę, cyfrę oraz znak specjalny.',
  forgotten_password_button: 'Wyślij link do zmiany hasła',
  do_you_already_have_an_account: 'Masz już konto?',
  reset_password_title: 'Ustaw nowe hasło',
  reset_password_subtitle: 'Wpisz poniżej Twoje nowe hasło.',
  reset_password_button: 'Zapisz nowe hasło',
  profile: 'Profil',
  logout: 'Wyloguj',
  cancel_booking: 'Odwołaj rezerwację',
  status_approved: 'Zaakceptowana',
  status_payment: 'Oczekuje na płatność',
  status_done: 'Odbyła się',
  status_cancelled: 'Odwołana',
  cancel_booking_dialog_title: 'Chcesz odwołać rezerwację?',
  cancel_booking_dialog_information: 'Tej akcji nie da się cofnąć!',
  cancel_booking_dialog_button: 'Tak, odwołaj rezerwację',
  my_profile: 'Mój profil',
  my_data: 'Moje dane',
  upload_new_photo: 'Dodaj nowe zdjęcie',
  birthday: 'Urodziny',
  save_changes: 'Zapisz zmiany',
  phone_number: 'Numer telefonu',
  directional: 'Kierunkowy',
  save_new_password: 'Zapisz nowe hasło',
  new_password: 'Nowe hasło',
  password_change: 'Zmiana hasła',
  view: 'Zobacz',
  services_2: 'Usług',
  accept_terms_and_conditions_1: 'Klikając opcję poniżej, zgadzam się na',
  accept_terms_and_conditions_2: 'Warunki użytkowania',
  accept_terms_and_conditions_3: 'i potwierdzam zapoznanie się z',
  accept_terms_and_conditions_4: 'Oświadczeniem dotyczącym prywatności.',
  to: 'Do',
  pln: '@{value} zł',
  usd: '$@{value}',
  eur: '@{value} EUR',
  gbp: '£@{value}',
  jpy: '@{value} JPY',
  aud: '@{value} AUD',
  cad: '@{value} CAD',
  chf: '@{value} CHF',
  sek: '@{value} SEK',
  nzd: '@{value} NZD',
  brl: '@{value} BRL',
  bgn: '@{value} BGN',
  czk: '@{value} CZK',
  dkk: '@{value} DKK',
  hkd: '@{value} HKD',
  huf: '@{value} HUF',
  ils: '@{value} ILS',
  myr: '@{value} MYR',
  mxn: '@{value} MXN',
  twd: '@{value} TWD',
  nok: '@{value} NOK',
  php: '@{value} PHP',
  ron: '@{value} RON',
  rub: '@{value} RUB',
  sgd: '@{value} SGD',
  thb: '@{value} THB',
  uah: '@{value} UAH',
  inr: '@{value} INR',
  clp: '@{value} CLP',
  login: 'Zaloguj się',
  email_is_incorrect: 'Adres e-mail jest niepoprawny.',
  logged_out: 'Zostałeś wylogowany',
  booking_dialog_date_is_already_taken: 'Wybrany termin jest niedostępny. Proszę wybrać inny wolny termin.',
  data_search: 'Wyszukiwanie danych. Proszę czekać...',
  no_employees: 'Brak specjalistów',
  no_services: 'Brak usług',
  no_categories: 'Brak kategorii',
  no_bookings: 'Nie znaleziono rezerwacji.',
  no_subscriptions: 'Nie znaleziono subskrypcji.',
  female: 'Kobieta',
  male: 'Mężczyzna',
  other: 'Inna',
  no_numeric_in_password: 'Brak znaku numerycznego w haśle.',
  no_lowercase_in_password: 'Brak małej litery w haśle.',
  no_uppercase_in_password: 'Brak dużej litery w haśle.',
  no_special_character: 'Brak znaku specjalnego.',
  min_n_characters: 'Minimalna ilość znaków to @{min}.',
  max_n_characters: 'Maksymalna ilość znaków to @{max}.',
  email: 'E-mail',
  reset_password_fail_text: 'Hasło nie zostało zmienione. Link prawdopodobnie wygasł.',
  reset_password_success_title: 'Hasło zostało zmienione.',
  reset_password_success_text: 'Możesz zalogować się swoim nowym hasłem.',
  account_activation: 'Aktywacja konta',
  account_activation_success: 'Konto zostało aktywowane.',
  account_activation_fail: 'Podczas aktywacji konta wystąpił problem. Wygląda na to, że konto jest już aktywne lub link stracił ważność.',
  account_activation_processing: 'Trwa aktywowanie konta. Proszę czekać...',
  account_activation_resend_title: 'Konto nie zostało aktywowane',
  account_activation_resend_text: 'Aktywuj konto klikając w link aktywacyjny w przesłanej wiadomości e-mail.',
  account_activation_resend_button: 'Wyślij ponownie',
  confirm_send_activate_link_dialog_subtitle: 'Na podany wcześniej adres e-mail wysłaliśmy wiadomość z linkiem aktywującym konto.',
  file_is_too_large: 'Wybrany plik jest za duży.',
  appointment_title: 'Wybierz osobę, datę oraz godzinę rezerwacji',
  others: 'Inne',
  countryLabel: 'Kraj',
  requiredMessages: 'Numer telefonu jest wymagany',
  date_of_visit: 'Data',
  hour_of_visit: 'Godzina',
  thank_you_for_booking: 'Twoja rezerwacja',
  no_free_hours: 'Brak wolnych terminów.',
  any_person: 'Dowolna osoba',
  password_changed: 'Hasło zostało zmienione',
  incorrect_old_password: 'Niepoprawne stare hasło',
  error404: 'Błąd 404',
  minutes: 'min',
  hours: 'h',
  days: 'd',
  ok: 'OK',
  web_page_temporary_disabled: 'Przepraszamy! Strona tymczasowo wyłączona.',
  profile_update_success: 'Twoje dane zostały zapisane.',
  form_is_invalid:
    'Formularz zawiera błędy. Sprawdź podświetlone pola i spróbuj ponownie.',
  search: 'Szukaj',
  pay_online_button_title: 'Opłać online',
  account_not_activated_info: 'Adres e-mail nie został potwierdzony. Potwierdź adres e-mail klikając w link w przesłanej wiadomości.',
  paid: 'Opłacono',
  payments: 'Płatności',
  payment_service: 'Usługa',
  payment_date: 'Data',
  payment_price: 'Cena',
  payment_success_title: 'Płatność powiodła się, dziękujemy!',
  payment_fail_title: 'Płatność nie powiodła się, spróbuj ponownie.',
  pay_again: 'Opłać ponownie',
  newsletter_subscribe_success: 'Dziękujemy, adres e-mail został potwierdzony.',
  newsletter_subscribe_fail: 'Podczas aktywacji adresu e-mail wystąpił problem. Wygląda na to, że adres e-mail został już potwierdzony lub link stracił ważność.',
  newsletter_send_again: 'Wyślij ponownie',
  newsletter_send_again_success: 'Link aktywacyjny został wysłany ponownie.',
  send_again_fail: 'Wystąpił problem podczas generowania nowego linku aktywacyjnego, być może adres e-mail jest już aktywny lub przed chwilą wygenerowałeś już link.',
  newsletter_email_is_used: 'Adres e-mail znajduje się już na liście, jeśli adres nie został potwierdzony, możemy wysłać ponownie link aktywacyjny.',
  newsletter_unsubscribe_success: 'Dziękujemy, adres e-mail został usunięty.',
  newsletter_unsubscribe_fail: 'Podczas usuwania adresu e-mail wystąpił problem. Wygląda na to, że adres e-mail został już usunięty lub link stracił ważność.',
  booking_payments: 'Rezerwacje',
  simple_store_product_transactions: 'Produkty',
  payment_type_select_box: 'Wybierz rodzaj płatności',
  payment_description: 'Opis',
  add_to_cart: 'Dodaj do koszyka',
  remove_from_cart: 'Usuń z koszyka',
  save: 'Zapisz',
  bookings_cart_summary: 'Podsumowanie rezerwacji',
  today: 'Dziś',
  fill_booking_details_title: 'Uzupełnij formularz rezerwacji',
  create_an_account_question: 'Utwórz konto oraz wyślij hasło na adres e-mail',
  value_is_incorrect: 'Wpisana wartość jest nieprawidłowa.',
  first_available_date: 'Wolne terminy w',
  service_location_1: 'Adres',
  service_location_2: 'Telefon',
  service_location_3: 'Google Meet',
  service_location_4: 'Inna',
  service_location_5: 'Zoom',
  service_location_6: 'Microsoft Teams',
  service_location_7: 'Skype',
  service_location_8: 'WhatsApp',
  service_location: 'Lokalizacja',
  booking_not_found: 'Nie znaleziono rezerwacji.',
  booking_canceled: 'Rezerwacja została anulowana.',
  booking_canceled_fail: 'Rezerwacja nie może już zostać anulowana.',
  day: 'Dzień',
  week: 'Tydzień',
  month: 'Miesiąc',
  year: 'Rok',
  booking_plural_1: 'Rezerwacja',
  booking_plural_2: 'Rezerwacje',
  booking_plural_3: 'Rezerwacje',
  booking_plural_4: 'Rezerwacje',
  booking_plural_5: 'Rezerwacji',
  day_plural_1: 'Dzień',
  day_plural_2: 'Dni',
  day_plural_3: 'Dni',
  day_plural_4: 'Dni',
  day_plural_5: 'Dni',
  week_plural_1: 'Tydzień',
  week_plural_2: 'Tygodnie',
  week_plural_3: 'Tygodnie',
  week_plural_4: 'Tygodnie',
  week_plural_5: 'Tygodni',
  month_plural_1: 'Miesiąc',
  month_plural_2: 'Miesiące',
  month_plural_3: 'Miesiące',
  month_plural_4: 'Miesiące',
  month_plural_5: 'Miesiący',
  year_plural_1: 'Rok',
  year_plural_2: 'Lata',
  year_plural_3: 'Lata',
  year_plural_4: 'Lata',
  year_plural_5: 'Lat',
  tax: 'NIP',
  tax_prefix: 'Prefix',
  get_company_data: 'Szukaj',
  get_company_data_failed: 'Nie udało się pobrać danych.',
  company_name: 'Nazwa firmy',
  street_and_number: 'Ulica i numer',
  postcode: 'Kod pocztowy',
  city: 'Miejscowość',
  country: 'Kraj',
  subscribe: 'Subskrybuj',
  recurrent_fee_info: 'Płatne co',
  one_time_fee_info: 'Płatne jednorazowo.',
  new_account_title: 'Utwórz nowe konto',
  payment_data_title: 'Dane do płatności',
  billing_data_checkbox_question: 'Dane do faktury',
  confirmation_modal_header: 'Akcja wymaga potwierdzenia',
  confirmation_modal_warning: 'Jesteś pewien, że chcesz kontynuować?',
  no_way_to_undo_action: 'Tej akcji nie da się cofnąć!',
  delete_payment_method: 'Usuń kartę',
  add_payment_method: 'Dodaj kartę',
  fill_card_details: 'Uzupełnij dane do płatności.',
  subscriptions: 'Subskrypcje',
  subscription: 'Subskrypcja',
  invoice_number: 'Faktura',
  number: 'Numer',
  status: 'Status',
  details: 'Szczegóły',
  created_at: 'Utworzono',
  subscription_status_initiated: 'Nieopłacona',
  subscription_status_active: 'Aktywna',
  subscription_status_inactive: 'Nieaktywna',
  subscription_status_canceled: 'Anulowana',
  ends_at: 'Ważność',
  ends_at_default: 'Ważność do końca okresu',
  select_subscription_to_pay: 'Wybierz subskrypcję, aby opłacić.',
  pay_with_subscription: 'Opłać subskrypcją',
  bookings_paid_by_subscription_success_message: 'Opłacono',
  bookings_not_paid_by_subscription_error_message: 'Nie można było opłacić rezerwacji wybraną subskrypcją.',
  stripe: 'Stripe',
  provider_tag: 'Metoda',
  card: 'Karta',
  cash: 'Gotówka',
  transfer: 'Przelew',
  promo_code_label: 'Kod rabatowy',
  promo_code_invalid_message: 'Kod rabatowy jest nieprawidłowy.',
  payment_method_setup_error: 'Wystąpił problem z Twoją kartą. Twój bank odrzucił możliwość autoryzacji karty do późniejszych transakcji. Skontaktuj się z bankiem.',
  active_subscription_missing: 'Rezerwacja na tę usługę wymaga aktywnej subskrypcji. Nie posiadasz żadnej subskrypcji, którą można by wykorzystać.',
  area_code: 'Kierunkowy',
  usage: 'Użycie',
  create_account_in_stripe_failed: 'Dane odrzucone przez operatora płatności. Sprawdź, proszę poprawność danych (numer NIP, telefon, adres e-mail).',
  additional_auth_required: 'Twój bank wymaga dodatkowej autoryzacji',
  general_payment_error: 'Wystąpił problem podczas płatności. Być może Twoja karta nie obsługuje subskrypcji, ma limity zakupowe, lub brakuje na niej środków do opłacenia subskrypcji. Skontaktuj się z bankiem lub wybierz inną metodę płatności.',
  subscription_set_successfully: 'Subskrypcja ustawiona poprawnie.',
  booking_created: 'Nowa rezerwacja',
  booking_updated: 'Zmiana rezerwacji',
  booking_deleted: 'Usunięcie rezerwacji',
  booking_reminder: 'Przypomnienie o rezerwacji',
  email_notifications: 'Powiadomienia e-mail',
  sms_notifications: 'Powiadomienia SMS',
  save_user_preferences: 'Zapisz ustawienia',
  free_spots: 'Pozostało',
  no: 'Nie',
  yes: 'Tak',
  p24_inactive: 'Operator Przelewy24 nie może być wykorzystywany. Skontaktuj się z operatorem płatności.',
  stripe_customer_invalid: 'Nieprawidłowy Stripe Id klienta. Nie można podłączyć metody płatności.',
  stripe_resource_invalid: 'Wybrany produkt jest niedostępny u operatora płatności.',
  account_blocked_message: 'Konto jest nieaktywne. Skontaktuj się z administratorem.',
  time_zone: 'Strefa czasowa',
  no_spots_left: 'Osiągnąłeś maksymalny limit wolnych miejsc.',
  employee_auth_required: 'Proszę, zaloguj się na swoje konto lub wybierz inny adres e-mail. Konta pracowników wymagają logowania.',
  subpage_auth_required: 'Musisz być zalogowany, aby wyświetlić zakładkę.',
  gross: 'Kwota brutto.',
  subscription_login_info: 'Masz już swoje konto? Zaloguj się poniżej, aby wczytać swoje dane.',
  change_date_time: 'Zmień termin',
  promo_code_checkbox: 'Mam kod rabatowy',
  booking_promo_code_label: 'Wprowadź kod rabatowy',
  select_hour: 'Wybierz godzinę',
  id: 'Id',
  booking_rescheduled: 'Rezerwacja została zmieniona.',
  booking_rescheduled_fail: 'Rezerwacja nie może już zostać zmieniona.',
  max_user_bookings_error: 'Przekroczono maksymalną liczbę rezerwacji. Wybierz inną usługę lub skontaktuj się z nami.',
  in_total: 'Razem',
  company: 'Firma',
  individual: 'Osoba prywatna',
  bulk_payment: 'Płatność zbiorcza',
  make_bulk_payment: 'Opłać zbiorczo',
  simple_store_product_confirmation_title: 'Uzupełnij formularz zakupu',
  select_service_type: 'Wybierz wariant usługi',
  add_to_calendar_button_label: 'Dodaj do kalendarza',
  login_to_use_service_with_subscription: 'Musisz zalogować się na swoje konto, aby zarezerwować wizytę na tę usługę.',
  booking_dialog_date_is_already_taken_multiple_bookings: 'Wybrany termin jest niedostępny. Proszę wybrać inny wolny termin. W przypadku rezerwacji wielu terminów upewnij się, że terminy się wzajemnie nie wykluczają.',
  select_location_calendar_warning: 'Wybierz lokalizację, aby wczytać dostępne terminy.',
  selectable_location_online_title: 'Online',
  change: 'Zmień',
  show_less_hours_button: 'Pokaż mniej godzin',
  show_more_hours_button: 'Pokaż więcej godzin',
  add_another_button_title: 'Dodaj kolejne',
  close: 'Zamknij',
  selected_time_slots: 'Wybrane terminy',
  select_location: 'Wybierz lokalizację',
  captcha_invalid_error_notification:
    'Weryfikacja captcha niepoprawna. Spróbuj przeładować stronę, jeśli problem będzie się powtarzać.',
  verify_code_incorrect_error: 'Wprowadzony kod jest niepoprawny.',
  verify_code_too_many_requests:
    'Obecnie nie możemy wygenerować nowego kodu. Spróbuj ponownie za jakiś czas lub wykorzystaj kod, który wysłaliśmy.',
  send_again_button_title: 'Wyślij ponownie',
  verify_title: 'Weryfikuj',
  verify_code_title: 'Wprowadź kod weryfikacyjny',
  verify_code_description:
    'Wysłaliśmy 6-cyfrowy kod weryfikacyjny na Twój e-mail. Proszę wprowadź go poniżej:',
  verification_invalid_error_notification:
    'Nieprawidłowa weryfikacja. Spróbuj ponownie później.',
  too_many_requests: 'Ups! Działasz trochę za szybko. Zwolnij i spróbuj ponownie za chwilę.',
  services_number_title: 'Liczba usług do wyboru',
  booking_can_not_be_paid: 'Płatność jest niedostępna. Odśwież stronę lub skorzystaj ponownie z linku w e-mailu, aby wyświetlić rezerwację.',
  drag_and_drop_files_here_or_click_to_upload: 'Przeciągnij i upuść pliki tutaj lub kliknij, aby je przesłać',
  files_uploaded: 'Przesłane pliki',
  max_file_size_mb: 'Maksymalny rozmiar pliku: 10 MB',
  unknown_type: 'Nieznany typ',
  upload_file_error: 'Wystąpił błąd podczas wgrywania pliku. Akceptowane formaty: PDF, XLSX, XLS, CSV, DOC, DOCX, JPG, JPEG, PNG, TXT. Rozmiar pliku nie może przekraczać 10 MB.',
  logged_in_info_notification: 'Poprawnie zalogowano do konta: @{email}',
  logged_out_info_notification: 'Poprawnie wylogowano z konta: @{email}'
}
