import { MutationTree } from 'vuex'
import { FilesState } from './types'

export const mutations: MutationTree<FilesState> = {
  ADD_FILE_TO_PROCESS_WITH_LAST_BOOKING (state, fileToProcessWithLastBooking: { uuid: string; file: File }) {
    state.filesToProcessWithLastBooking.push(fileToProcessWithLastBooking)
  },
  REMOVE_FILE_FROM_PROCESS_WITH_LAST_BOOKING (state, fileUuid: string) {
    state.filesToProcessWithLastBooking = state.filesToProcessWithLastBooking.filter(
      (file) => file.uuid !== fileUuid
    )
  }
}
